import {
  SET_DASHBOARD_VIEW,
} from '../action/Dashboard';
import { defineds } from '../Utils/date.util';

const initialState = {
  showChart: true,
  categories: [],
  activeSelection: 'Today',
  startDate: new Date(defineds.startOfToday).getTime(),
  endDate: new Date(defineds.endOfToday).getTime(),
  isCustomDate: false,
};
export const dashboardReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case SET_DASHBOARD_VIEW: {
      newState = {
        ...curState,
        ...action.payload,
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

