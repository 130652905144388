export const FETCH_WORKFLOW_V2 = 'FETCH_WORKFLOW_V2';
export const FETCH_WORKFLOW_SUCCESS_V2 = 'FETCH_WORKFLOW_SUCCESS_V2';
export const FETCH_WORKFLOW_FAILURE_V2 = 'FETCH_WORKFLOW_FAILURE_V2';

export const SET_WORKFLOW_PAGE_SIZE = 'SET_WORKFLOW_PAGE_SIZE';
export const SET_WORKFLOW_PAGE_INDEX = 'SET_WORKFLOW_PAGE_INDEX';
export const RESET_WORKFLOW_ITEMS = 'RESET_WORKFLOW_ITEMS';
export const RELOAD_WORKFLOW = 'RELOAD_WORKFLOW';
export const UPDATE_WORKFLOW_LIST_ITEM = 'UPDATE_WORKFLOW_LIST_ITEM';

export const FETCH_WORKFLOW_DETAIL = 'FETCH_WORKFLOW_DETAIL';
export const FETCH_WORKFLOW_DETAIL_SUCCESS = 'FETCH_WORKFLOW_DETAIL_SUCCESS';
export const FETCH_WORKFLOW_DETAIL_FAILURE = 'FETCH_WORKFLOW_DETAIL_FAILURE';
export const RESET_WORKFLOW_DETAIL_DATA = 'RESET_WORKFLOW_DETAIL_DATA';
export const UPDATE_WORKFLOW_DETAIL = 'UPDATE_WORKFLOW_DETAIL';
export const REMOVE_WORKFLOW = 'REMOVE_WORKFLOW';

export const SET_WORKFLOW_SEARCH = 'SET_WORKFLOW_SEARCH';

export const SET_PROSPECT_SEQUENCE_DATA = 'SET_PROSPECT_SEQUENCE_DATA';
export const SET_PROSPECT_SEQUENCE_DATA_SUCCESS = 'SET_PROSPECT_SEQUENCE_DATA_SUCCESS';
export const SET_PROSPECT_SEQUENCE_DATA_FAILURE = 'SET_PROSPECT_SEQUENCE_DATA_FAILURE';

