import {
  salesforcePlugin, zapier, hubspotName, pipedriveName, googleSheets,
  zoho, slackLogo, pabbly,
} from '../../assets/images';

export const PLUGIN_TYPES = ['CRM', 'Tools', 'Extensions'];

export const pluginConfig = {
  salesforce: {
    name: 'salesforce',
    imgSrc: salesforcePlugin,
    title: 'Salesforce',
    type: PLUGIN_TYPES[0],
    description: 'Automate tedious tasks, eliminate manual data entry errors, save time and streamline your workflow.',
  },
  hubspot: {
    name: 'hubspot',
    imgSrc: hubspotName,
    title: 'HubSpot',
    type: PLUGIN_TYPES[0],
    description: 'Sync your contacts across HubSpot and Upscale',
  },
  pipedrive: {
    name: 'pipedrive',
    imgSrc: pipedriveName,
    title: 'Pipedrive',
    type: PLUGIN_TYPES[0],
    description: 'Sync your contacts across Pipedrive and Upscale',
  },
  zoho: {
    name: 'zoho',
    imgSrc: zoho,
    title: 'Zoho',
    type: PLUGIN_TYPES[0],
    description: 'Sync your contacts across Pipedrive and Upscale',
  },
};
export const PLUGIN_ENUM = ['hubspot', 'pipedrive', 'salesforce', 'zoho'];

const TOOL_CATEGORY_TYPES = {
  external: 'external',
  internal: 'interanl',
};

export const toolsConfig = {
  pabbly: {
    name: 'pabbly',
    imgSrc: pabbly,
    title: 'Pabbly',
    type: PLUGIN_TYPES[1],
    description: 'Explore App automation with Pabbly',
    toolCategory: TOOL_CATEGORY_TYPES.external,
  },
  zapier: {
    name: 'zapier',
    imgSrc: zapier,
    title: 'Zapier',
    type: PLUGIN_TYPES[1],
    description: 'Explore App automation with Zapier',
    toolCategory: TOOL_CATEGORY_TYPES.external,
  },
  googleSheets: {
    name: 'googleSheets',
    imgSrc: googleSheets,
    title: 'Google Sheets',
    type: PLUGIN_TYPES[1],
    description: '',
    toolCategory: TOOL_CATEGORY_TYPES.internal,
  },
  slack: {
    name: 'slack',
    imgSrc: slackLogo,
    title: 'Slack',
    type: PLUGIN_TYPES[1],
    description: '',
    toolCategory: TOOL_CATEGORY_TYPES.external,
  },
};

export const extensionsConfig = {
  chromeExtension: {
    name: 'upscaleEngage',
    imgSrc: '/logoFull.png',
    title: 'Upscale Engage',
    type: PLUGIN_TYPES[2],
    description: 'Explore App automation with zapier',
  },
};

