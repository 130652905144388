export const checkAndDeleteKey = ({ dataMap, ids }) => {
  let isModified = false;
  ids.forEach((id) => {
    if (dataMap[id]) {
      // eslint-disable-next-line no-param-reassign
      delete dataMap[id];
      isModified = true;
    }
  });
  return isModified ? dataMap : null;
};

export const getMatchingKeys = ({ keys = [], modifiedKeys = [] }) => keys.filter((obj) => modifiedKeys.indexOf(obj) !== -1);
