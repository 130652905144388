import { combineReducers } from 'redux';
import { USER_LOGGED_OUT } from '../action/type';
import { userSettingsReducer } from './userSettingsReducer';
import { accountSettingsReducer } from './accountSettingsReducer';
import { allUsersReducer } from './allUsersReducer';
import { taskReducerV2 } from './taskReducerV2';
import { activityReducer } from './activityReducer';
import { customFieldsReducer } from './customFieldsReducer';
import { upscaleSettingReducer } from './upscaleSettingReducer';
import { integrationsReducer } from './integrationsReducer';
import { ownedNumbersReducer } from './ownedNumbers';
import { prospectFilterReducer } from './prospectFilterReducer';
import { taskFilterReducer } from './taskFilterReducer';
import { accountFilterReducer } from './accountFilterReducer';
import { callFilterReducer } from './callFilterReducer';
import { offlineReducer } from './offlineReducer';
import { socketEventReducer } from './socketEventReducer';
import { spinnerCounterReducer } from './spinnerReducer';
import { ThirdPartyDataReducer } from './thirdPartyDataReducer';
import { opportunityFilterReducer } from './opportunityFilterReducer';
import { workflowProspectFilterReducer } from './workflowProspectFilterReducer';
import { componentChangeReducer } from './componentChangeReducer';
import { insightsReducer } from './insightsReducer';
import { prospectProfileActivityReducer } from './individualProfileActivity/prospectActivityReducer';
import { clientAccountProfileActivityReducer } from './individualProfileActivity/clientAccountActivityReducer';
import { opportunityProfileActivityReducer } from './individualProfileActivity/opportunityActivityReducer';
import { outboxFilterReducer } from './outboxFilterReducer';
import { notificationReducer } from './notificationReducer';
import { importHistoryReducer } from './ImportHistoryReducer';
import { importHistoryProspectFilterReducer } from './importHistoryProspectFilterReducer';
import { universalSearchReducer } from './universalSearchReducer';
import { prospectTilesReducer } from './prospectTilesReducer';
import { prospectsReducerV2 } from './prospectReducerV2';
import { prospectDetails } from './prospectDetailsReducer';
import { clientAccountReducerV2 } from './clientAccountReducerV2';
import { clientAccountDetailsReducer } from './clientAccountDetailsReducer';
import { opportunityReducerV2 } from './opportunityReducerV2';
import { opportunityDetailsReducer } from './opportunityDetailsReducer';
import { opportunityTilesReducer } from './opportunityTilesReducer';
import { workflowProspectTilesReducer } from './workflowProspectTileReducer';
import { workflowProspectsListReducer } from './workflowProspectListReducer';
import { taskTilesReducer } from './taskTileReducer';
import { taskViewReducerV2 } from './taskViewReducer';
import { taskDetails } from './taskDetailsReducer';
import { callReducerV2 } from './callReducer';
import { callTilesReducer } from './callTileReducer';
import { outboxReducerV2 } from './outboxReducer';
import { outboxTilesReducer } from './outboxTileReducer';
import { dashboardReducer } from './dashboardReducer';
import { workflowsReportInsightReducer } from './workflowsReportInsightReducer';
import workflowDetailsReducer from './Workflow/detailMap';
import workflowFilter from './Workflow/filter';
import workflowList from './Workflow/list';
import templateReducerV2 from './Template/list';
import templateFilterReducer from './Template/filter';
import templateDetailsReducer from './Template/detailMap';
import { planDetailReducer } from './planDetailReducer';

const appReducer = combineReducers({
  UserSettings: userSettingsReducer,
  AccountSettings: accountSettingsReducer,
  AllUsers: allUsersReducer,
  Workflows: workflowList,
  WorkflowDetails: workflowDetailsReducer,
  Tasks: taskReducerV2,
  TaskTiles: taskTilesReducer,
  Templates: templateReducerV2,
  TemplateFilter: templateFilterReducer,
  TemplateDetails: templateDetailsReducer,
  Activity: activityReducer,
  CustomFields: customFieldsReducer,
  UpscaleSetting: upscaleSettingReducer,
  Integrations: integrationsReducer,
  IntegrationLogs: ownedNumbersReducer,
  ProspectFilter: prospectFilterReducer,
  TaskFilter: taskFilterReducer,
  WorkflowFilter: workflowFilter,
  AccountFilter: accountFilterReducer,
  Offline: offlineReducer,
  SocketEvent: socketEventReducer,
  SpinnerCounter: spinnerCounterReducer,
  CallFilter: callFilterReducer,
  ThirdPartyData: ThirdPartyDataReducer,
  OpportunityFilter: opportunityFilterReducer,
  WorkflowProspectFilter: workflowProspectFilterReducer,
  Component: componentChangeReducer,
  insights: insightsReducer,
  ProspectProfileActivity: prospectProfileActivityReducer,
  ClientAccountProfileActivity: clientAccountProfileActivityReducer,
  OpportunityProfileActivity: opportunityProfileActivityReducer,
  OutboxFilter: outboxFilterReducer,
  Notification: notificationReducer,
  ImportHistory: importHistoryReducer,
  ImportHistoryFilter: importHistoryProspectFilterReducer,
  UniversalSearch: universalSearchReducer,
  ProspectTiles: prospectTilesReducer,
  ProspectsV2: prospectsReducerV2,
  ProspectDetails: prospectDetails,
  ClientAccountsV2: clientAccountReducerV2,
  ClientAccountDetails: clientAccountDetailsReducer,
  OpportunityTiles: opportunityTilesReducer,
  OpportunityV2: opportunityReducerV2,
  OpportunityDetails: opportunityDetailsReducer,
  WorkflowProspectTile: workflowProspectTilesReducer,
  WorkflowProspectList: workflowProspectsListReducer,
  TaskView: taskViewReducerV2,
  TaskDetails: taskDetails,
  Calls: callReducerV2,
  CallTiles: callTilesReducer,
  Outboxs: outboxReducerV2,
  OutboxTiles: outboxTilesReducer,
  Dashboard: dashboardReducer,
  WorkflowsReportInsight: workflowsReportInsightReducer,
  PlanDetails: planDetailReducer,
});

// when a logout action is dispatched it will reset redux state
const rootReducer = (state, action) => appReducer(
  action.type === USER_LOGGED_OUT ? undefined : state, action,
);

export default rootReducer;
