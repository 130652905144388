import API from '../libs/amplify.lib';
import { API_NAME } from '../constants';
import { generateQueryStringFromObject } from '../Utils/query.v2.util';
import { responseCallback } from '../libs/response.lib';

export const addCustomField = async (payload) => API.post(API_NAME, '/customFields', { body: payload })
  .then((resp) => JSON.parse(responseCallback(resp).body));

export const getCustomFields = async (query) => API.get(API_NAME, `/customFields?${generateQueryStringFromObject(query)}`)
  .then((resp) => JSON.parse(responseCallback(resp).body));

export const deleteCustomField = async (query, payload = {}) => API.del(API_NAME, `/customFields?${generateQueryStringFromObject(query)}`, { body: payload })
  .then((resp) => JSON.parse(responseCallback(resp).body));

export const updateCustomField = async (payload) => API.put(API_NAME, '/customFields', { body: payload })
  .then((resp) => JSON.parse(responseCallback(resp).body));

export const bulkDeleteCustomFields = async (payload) => API
  .post(API_NAME, '/customFields?type=multiple&method=delete', { body: payload })
  .then(responseCallback);

export const setAccountDefaultCustomFields = async ({
  operation, ...rest
}) => API
  .put(API_NAME, `/customFields?operation=${operation}`, { body: rest })
  .then((resp) => JSON.parse(responseCallback(resp).body));

export const getTriggerConstants = async (query = {}) => API
  .get(API_NAME, `/upscaleSetting?${generateQueryStringFromObject(query)}`)
  .then((resp) => JSON.parse(responseCallback(resp).body).data);
