import {
  FETCH_INTEGRATION_SETTINGS, FETCH_INTEGRATION_SETTINGS_SUCCESS,
  FETCH_INTEGRATION_SETTINGS_FAILURE,
} from '../action/type';

const initialState = {
  accountId: '',
  integrations: [],
  integrationMap: {},
  isLoading: NaN,
  error: '',
  syncedCRM: '',
  apiKeys: {},
};
export const integrationsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_INTEGRATION_SETTINGS:
      newState = {
        ...curState,
        error: null,
        isLoading: FETCH_INTEGRATION_SETTINGS,
        accountId: action.payload.accountId,
      };
      break;
    case FETCH_INTEGRATION_SETTINGS_SUCCESS:
      newState = {
        ...curState,
        isLoading: FETCH_INTEGRATION_SETTINGS_SUCCESS,
        integrations: action.payload.integrations,
        integrationMap: action.payload.integrationMap,
        syncedCRM: action.payload.syncedCRM,
        apiKeys: action.payload.apiKeys,
      };
      break;
    case FETCH_INTEGRATION_SETTINGS_FAILURE:
      newState = {
        ...curState,
        isLoading: FETCH_INTEGRATION_SETTINGS_FAILURE,
        error: action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};
