import {
  FETCH_CLIENT_ACCOUNT_DETAIL,
  FETCH_CLIENT_ACCOUNT_DETAIL_FAILURE,
  FETCH_CLIENT_ACCOUNT_DETAIL_SUCCESS,
  RESET_CLIENT_ACCOUNT_ITEMS,
  UPDATE_CLIENT_ACCOUNT_DETAIL,
} from '../action/ClientAccount';
import {
  // ADD_CLIENT_ACCOUNT_NOTE,
  BULK_UPDATE_CLIENT_ACCOUNT, BULK_UPDATE_CLIENT_ACCOUNT_CUSTOM_FIELD, BULK_UPDATE_CLIENT_ACCOUNT_MULTIPLE_FIELD, BULK_UPDATE_CLIENT_ACCOUNT_TAGS, REMOVE_CLIENT_ACCOUNT,
} from '../action/type';
import { combineAndGenerateValidTags } from '../Utils/crossMapper.util';
import { getMatchingKeys } from '../Utils/V2/creator.util';

const initialState = {
  detailMap: { },
};
export const clientAccountDetailsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_CLIENT_ACCOUNT_DETAIL:
      newState = {
        ...curState,
        detailMap: {
          ...curState.detailMap,
          [action.payload.id]: {
            isLoading: true,
            data: {},
            error: '',
          },
        },
      };
      break;
    case FETCH_CLIENT_ACCOUNT_DETAIL_SUCCESS: {
      const [, id] = action.payload._id.split(':');
      if (curState.detailMap[id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              isLoading: false,
              data: action.payload,
              error: '',
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_CLIENT_ACCOUNT_DETAIL_FAILURE: {
      if (curState.detailMap[action.payload.id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              ...curState.detailMap[action.payload.id],
              isLoading: false,
              error: action.payload.error,
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }

      break;
    }
    case UPDATE_CLIENT_ACCOUNT_DETAIL: {
      const { id, data } = action.payload;
      const [, uId] = id.split(':');
      if (curState.detailMap[uId]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [uId]: {
              ...curState.detailMap[uId],
              data: {
                ...curState.detailMap[uId].data,
                ...data,
              },
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    //
    case BULK_UPDATE_CLIENT_ACCOUNT: {
      const { ids, data } = action.payload;
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: ids });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, ...data },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_CLIENT_ACCOUNT_TAGS: {
      const { ids, data } = action.payload;
      const { newTags } = data;
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: ids });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, tags: combineAndGenerateValidTags(detailMap[key].data?.tags || [], newTags) },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_CLIENT_ACCOUNT_CUSTOM_FIELD: {
      const { ids, data } = action.payload;
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: ids });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, customFields: { ...(detailMap[key].data?.customFields || {}), ...data } },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    // case ADD_CLIENT_ACCOUNT_NOTE: {
    //   const { id, data } = action.payload;
    //   const { detailMap } = curState;
    //   if (detailMap[id]?.data?.notes) {
    //     const notes = detailMap[id].data?.notes;
    //     if (notes && data.note) notes.unshift(data.note);
    //     detailMap[id] = {
    //       ...detailMap[id],
    //       data: { ...detailMap[id].data, notes: notes || [] },
    //     };
    //     newState = {
    //       detailMap: { ...detailMap },
    //     };
    //   } else {
    //     newState = curState;
    //   }
    //   break;
    // }
    case REMOVE_CLIENT_ACCOUNT: {
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: action.payload.ids });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          delete detailMap[key];
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_CLIENT_ACCOUNT_MULTIPLE_FIELD: {
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: Object.keys(action.payload) });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, ...action.payload[key] },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    //
    case RESET_CLIENT_ACCOUNT_ITEMS: {
      newState = { ...initialState };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

