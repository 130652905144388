import {
  USER_SETTINGS, USER_SETTINGS_SUCCESS, USER_SETTINGS_FAILURE,
  UPDATE_USER_SETTINGS, UPDATE_USER_SETTINGS_SUCCESS, UPDATE_USER_SETTINGS_FAILURE, HIDE_DEMO, UPDATE_USER_SETTING_BASIC_SETTING, UPDATE_USER_SETTING_TABLE_CONFIG, USER_AUTHENTICATED, USER_ACCESS_DETAILS,
} from '../action/type';

const initialState = {
  user: {}, userSettings: {}, isLoading: NaN, error: '', hideDemo: false, userLoginStatus: NaN, isAuthenticated: false,
};
export const userSettingsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case USER_AUTHENTICATED: {
      newState = {
        ...curState, userLoginStatus: USER_AUTHENTICATED, isAuthenticated: true,
      };
      break;
    }
    case USER_ACCESS_DETAILS: {
      newState = {
        ...curState, userLoginStatus: USER_ACCESS_DETAILS, user: action.payload, userSettings: action.payload,
      };
      break;
    }
    case USER_SETTINGS:
      newState = {
        ...curState, error: null, userLoginStatus: USER_SETTINGS,
      };
      break;
    case USER_SETTINGS_SUCCESS:
      newState = {
        ...curState,
        userLoginStatus: USER_SETTINGS_SUCCESS,
        userSettings: action.payload.userSettings,
        user: action.payload.user,
      };
      break;
    case USER_SETTINGS_FAILURE:
      newState = {
        ...curState, userLoginStatus: USER_SETTINGS_FAILURE, user: {}, userSettings: null, error: action.payload,
      };
      break;
    case UPDATE_USER_SETTINGS:
      newState = {
        ...curState, isLoading: UPDATE_USER_SETTINGS,
      };
      break;
    case UPDATE_USER_SETTINGS_SUCCESS:
      newState = {
        ...curState, isLoading: UPDATE_USER_SETTINGS_SUCCESS, userSettings: action.payload,
      };
      break;
    case UPDATE_USER_SETTINGS_FAILURE:
      newState = {
        ...curState, isLoading: UPDATE_USER_SETTINGS_FAILURE, error: action.payload,
      };
      break;
    case HIDE_DEMO:
      newState = {
        ...curState, isLoading: HIDE_DEMO, hideDemo: true,
      };
      break;
    case UPDATE_USER_SETTING_BASIC_SETTING:
    case UPDATE_USER_SETTING_TABLE_CONFIG: {
      const { userSettings = {} } = curState;
      newState = {
        ...curState,
        userSettings: { ...userSettings, ...action.payload },
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
