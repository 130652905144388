import {
  FETCH_IMPORT_PROSPECT_HISTORY, FETCH_IMPORT_PROSPECT_HISTORY_SUCCESS, FETCH_IMPORT_PROSPECT_HISTORY_FAILURE,
  RESET_IMPORT_PROSPECT_HISTORY,
} from '../action/type';

const initialState = {
  items: [],
  isLoading: NaN,
  error: '',
  LastEvaluatedKey: null,
};
export const importHistoryReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case RESET_IMPORT_PROSPECT_HISTORY:
      newState = {
        ...initialState,
      };
      break;
    case FETCH_IMPORT_PROSPECT_HISTORY:
      newState = {
        ...curState,
        error: '',
        isLoading: FETCH_IMPORT_PROSPECT_HISTORY,
      };
      break;
    case FETCH_IMPORT_PROSPECT_HISTORY_SUCCESS:
      newState = {
        ...curState,
        error: '',
        isLoading: FETCH_IMPORT_PROSPECT_HISTORY_SUCCESS,
        items: payload.items,
        LastEvaluatedKey: payload.LastEvaluatedKey,
      };
      break;
    case FETCH_IMPORT_PROSPECT_HISTORY_FAILURE:
      newState = {
        ...curState,
        isLoading: FETCH_IMPORT_PROSPECT_HISTORY_FAILURE,
        error: payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};
