import {
  TASK_TILE_DATA, TASK_TILE_DATA_FAILURE, TASK_TILE_DATA_SUCCESS, RESET_TASK_TILE_DATA,
  TASK_RANGE_TILE_DATA,
  TASK_RANGE_TILE_DATA_SUCCESS,
  TASK_RANGE_TILE_DATA_FAILURE,
} from '../action/Task';

const initialState = {
  isLoading: NaN,
  data: { All: 0 },
  rangeTileData: { Today: 0, Due: 0, Upcoming: 0, Completed: 0, Skipped: 0, Paused: 0 },
  isLoadingRangeTile: NaN,
  rangeTileError: NaN,
  error: '',
};
export const taskTilesReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TASK_TILE_DATA: {
      newState = {
        ...curState,
        isLoading: true,
      };
      break;
    }
    case TASK_TILE_DATA_SUCCESS: {
      const newDataObj = { All: 0 };
      let totalCount = 0;
      action.payload.resultArr.forEach((data) => {
        newDataObj[data._id] = data.count;
        totalCount += Number(data.count);
      });
      newDataObj.All = totalCount;
      newState = {
        ...curState,
        data: newDataObj,
        isLoading: false,
      };
      break;
    }
    case TASK_TILE_DATA_FAILURE: {
      newState = {
        ...curState,
        error: action.payload,
        isLoading: false,
      };
      break;
    }

    case TASK_RANGE_TILE_DATA: {
      newState = {
        ...curState,
        isLoadingRangeTile: true,
      };
      break;
    }
    case TASK_RANGE_TILE_DATA_SUCCESS: {
      newState = {
        ...curState,
        rangeTileData: { ...initialState.rangeTileData, ...(action.payload.resultArr) },
        isLoadingRangeTile: false,
        rangeTileError: '',
      };
      break;
    }
    case TASK_RANGE_TILE_DATA_FAILURE: {
      newState = {
        ...curState,
        rangeTileError: action.payload,
        isLoadingRangeTile: false,
      };
      break;
    }

    case RESET_TASK_TILE_DATA: {
      newState = { ...curState, isLoading: NaN, error: '', rangeTileError: '', isLoadingRangeTile: false };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

