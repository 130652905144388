import { TOGGLE_COMPONENT_CHANGE } from '../action/type';

const initialState = {
  isUpdated: false,
};
export const componentChangeReducer = (state = initialState, action = { type: '', payload: '' }) => {
  if (action.type === TOGGLE_COMPONENT_CHANGE) {
    return {
      ...state,
      isUpdated: action.payload,
    };
  }
  return state;
};
