import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import './normalize.css';
import './commonStyles/index.scss';
import './assets/scss/app.scss';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { configureAWSAmplify } from './service/Authentication';
import { initSentry } from './libs/errorLib';
import { setToggleComponentChangeThunk } from './action/componentChange';
import { IS_NOT_PRODUCTION } from './constants';

// log the vitals

// eslint-disable-next-line no-console
// getCLS(console.log);
// eslint-disable-next-line no-console
// getFID(console.log);
// eslint-disable-next-line no-console
// getLCP(console.log);
// import { configureNylas } from './service/Nylas';
// Initializes sentry for login
initSentry();

// configure Amplify
configureAWSAmplify();

// configure Nylas
// configureNylas();

/**
 * Uncomment the below condition if you want to see the logs of Amplify Auth or API
 */
if (IS_NOT_PRODUCTION) {
  Amplify.Logger.LOG_LEVEL = '';
}

const mapDispatchToProps = {
  setToggleComponentChangeThunk,
};

const Element = ({ setToggleComponentChangeThunk: togglePromptState }) => {
  const onUserConfirm = (message, callback) => {
    const allowTransition = window.confirm(message);
    callback(allowTransition);
    if (allowTransition) {
      togglePromptState(false);
    }
  };

  return (
    <Router getUserConfirmation={onUserConfirm}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
  );
};
const AppWrapper = connect(null, mapDispatchToProps)(memo(Element));

const UnsecuredPage = () => (
  <div>
    <div style={{ color: 'red' }}>If you see this page, Web App link you have clicked on is under Clickjacking security attack.</div>
    <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    <h2>
      Click
      <a
        href={window.self.location.href}
        title="Web Application"
        target="blank"
        style={{
          textDecoration: 'none',
          color: 'red',
        }}
      >
        {' here '}
      </a>
      to access Web App safely.
    </h2>
  </div>
);

// Won't render the application if WebApp is under Clickjacking attack
if (window.self === window.top) {
  ReactDOM.render(
    <Provider store={store}>
      <AppWrapper />
    </Provider>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
