export const PROSPECT_TILE_DATA = 'PROSPECT_TILE_DATA';
export const PROSPECT_TILE_DATA_SUCCESS = 'PROSPECT_TILE_DATA_SUCCESS';
export const PROSPECT_TILE_DATA_FAILURE = 'PROSPECT_TILE_DATA_FAILURE';
export const RESET_PROSPECT_TILE_DATA = 'RESET_PROSPECT_TILE_DATA';

export const FETCH_PROSPECT_V2 = 'FETCH_PROSPECT_V2';
export const FETCH_PROSPECT_SUCCESS_V2 = 'FETCH_PROSPECT_SUCCESS_V2';
export const FETCH_PROSPECT_FAILURE_V2 = 'FETCH_PROSPECT_FAILURE_V2';

export const SET_PROSPECT_PAGE_SIZE = 'SET_PROSPECT_PAGE_SIZE';
export const SET_PROSPECT_PAGE_INDEX = 'SET_PROSPECT_PAGE_INDEX';
export const RESET_PROSPECT_ITEMS = 'RESET_PROSPECT_ITEMS';
export const UPDATE_PROSPECT_DETAIL = 'UPDATE_PROSPECT_DETAIL';
export const RELOAD_PROSPECT_ITEMS = 'RELOAD_PROSPECT_ITEMS';
export const UPDATE_PROSPECT_LIST_ITEM = 'UPDATE_PROSPECT_LIST_ITEM';

export const FETCH_PROSPECT_DETAIL = 'FETCH_PROSPECT_DETAIL';
export const FETCH_PROSPECT_DETAIL_SUCCESS = 'FETCH_PROSPECT_DETAIL_SUCCESS';
export const FETCH_PROSPECT_DETAIL_FAILURE = 'FETCH_PROSPECT_DETAIL_FAILURE';
export const RESET_PROSPECT_DETAIL_DATA = 'RESET_PROSPECT_DETAIL_DATA';

export const UPDATE_PROSPECT_LIST_ON_SINGLE_UPDATE = 'UPDATE_PROSPECT_LIST_ON_SINGLE_UPDATE';
