import {
  isToday, isSameWeek, isSameMonth, isValid,
} from 'date-fns';
import { isObject, isArray, isString } from './dataType.util';
import {
  CUSTOM_FIELD_HTML_TYPE_MAP, CUSTOM_FIELD_TYPE_ENUM,
} from '../constants';
import { generateName, makeAllOptions } from './helper.util';
import { getRelativeDifferenceString } from './date.util';
import { validateTags } from './formValidation.util';
import { BOOLEAN_OPTIONS } from '../constants/filter.constant';

export const getUserName = (userObject) => {
  const { basicInfo } = userObject;
  if (isObject(basicInfo)) {
    return generateName(basicInfo);
  }
  return '';
};

export function generateIndividualScreenActivityData(activities, filterActivityEvents = []) {
  let sortedActivities = activities.sort((a, b) => b.createdAt - a.createdAt);
  if (filterActivityEvents.length) {
    sortedActivities = sortedActivities.filter((activity) => filterActivityEvents.includes(activity.event));
  }

  const dataMap = {
    Today: [],
    'Last Week': [],
    'Current Week': [],
    'Current Month': [],
    Other: [],
  };
  const todayDate = Date.now();
  sortedActivities.forEach((d) => {
    const { createdAt } = d;
    if (isToday(createdAt)) {
      dataMap.Today.push(d);
    } else if (isSameWeek(todayDate, createdAt)) {
      dataMap['Current Week'].push(d);
    } else if (isSameMonth(todayDate, createdAt)) {
      dataMap['Current Month'].push(d);
    } else {
      dataMap.Other.push(d);
    }
  });
  return dataMap;
}

export function generateFieldMapFromCustomFields(customFields) {
  const fieldMap = { };
  customFields.forEach((d) => {
    const {
      fieldName, isMandatory, id, fieldMetaInfo: {
        options, type: fieldType,
      },
    } = d;
    fieldMap[id] = {
      ...CUSTOM_FIELD_HTML_TYPE_MAP[fieldType],
      isMandatory,
      placeholder: `${fieldName}`,
      value: '',
      customFieldName: fieldName,
      defaultOptions: isArray(options) ? makeAllOptions(options) : [],
      fieldType,
    };
  });
  return fieldMap;
}

export const generateTagsOption = (settings) => (isObject(settings) && isArray(settings.tags)
  ? makeAllOptions(validateTags(settings.tags))
  : []);

export const combineAndGenerateValidTags = (oldTags, newTags) => {
  let filteredTags = [];
  if (isArray(oldTags)) {
    filteredTags = filteredTags.concat(oldTags.filter((d) => isString(d)));
  }
  if (isArray(newTags)) {
    filteredTags = filteredTags.concat(newTags.filter((d) => isString(d)));
  }
  return Array.from(new Set(filteredTags));
};

export const calculateLastContactedForAccount = ({ prospectClientAccountMap, account }) => {
  const { id, lastContacted = 0 } = account;
  let contactValue = lastContacted;
  if (prospectClientAccountMap[id]) {
    prospectClientAccountMap[id].forEach((d) => {
      const { lastContacted: ls = 0 } = d;
      if (ls && contactValue < ls) {
        contactValue = ls;
      }
    });
  }
  return contactValue;
};
export const calculateLastContactedForOpportunity = ({
  opportunity, prospectMap, clientAccountMap, prospectClientAccountMap,
}) => {
  const { prospects, account } = opportunity;
  let contactValue = 0;
  if (isArray(prospects) && prospects.length) {
    prospects.forEach((d) => {
      if (prospectMap[d]) {
        const { lastContacted = 0 } = prospectMap[d];
        if (lastContacted && contactValue < lastContacted) {
          contactValue = lastContacted;
        }
      }
    });
  } else if (account && clientAccountMap[account]) {
    contactValue = calculateLastContactedForAccount({ account: clientAccountMap[account], prospectClientAccountMap });
  }
  return contactValue;
};

export const calculateLastContactedString = (value) => (!isValid(value) || value === 0 ? 'Not contacted yet' : `${getRelativeDifferenceString(value)} ago`);
export const generateFieldArrFromCustomField = (customFields, fieldsOrder = []) => {
  let resp = [];
  if (isArray(customFields)) {
    resp = customFields.filter((d) => [
      CUSTOM_FIELD_TYPE_ENUM.Text,
      CUSTOM_FIELD_TYPE_ENUM.List,
      CUSTOM_FIELD_TYPE_ENUM.Date,
      CUSTOM_FIELD_TYPE_ENUM.Amount,
      CUSTOM_FIELD_TYPE_ENUM.Number,
      CUSTOM_FIELD_TYPE_ENUM.MultiList,
      CUSTOM_FIELD_TYPE_ENUM.Boolean,
      CUSTOM_FIELD_TYPE_ENUM.LongText,
      CUSTOM_FIELD_TYPE_ENUM.Phone,
      CUSTOM_FIELD_TYPE_ENUM.Email,
      CUSTOM_FIELD_TYPE_ENUM.URL,
      CUSTOM_FIELD_TYPE_ENUM.Percentage,
    ].includes(d.fieldMetaInfo.type))
      .sort((a, b) => fieldsOrder.indexOf(a.id) - fieldsOrder.indexOf(b.id))
      .map((d) => ({
        label: d.fieldName,
        elemProps: {
          options: isArray(d.fieldMetaInfo.options) ? makeAllOptions(d.fieldMetaInfo.options) : (d.fieldMetaInfo.type === CUSTOM_FIELD_TYPE_ENUM.Boolean) ? BOOLEAN_OPTIONS : [],
          isMulti: d.fieldMetaInfo.type === CUSTOM_FIELD_TYPE_ENUM.MultiList,
        },
        value: d.id,
        isCustomField: true,
        reactElement: CUSTOM_FIELD_HTML_TYPE_MAP[d.fieldMetaInfo.type].htmlElement,
        type: CUSTOM_FIELD_HTML_TYPE_MAP[d.fieldMetaInfo.type].type,
        validation: CUSTOM_FIELD_HTML_TYPE_MAP[d.fieldMetaInfo.type].validation,
      }));
  }
  return resp;
};
