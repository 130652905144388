import { endOfMonth, startOfMonth } from 'date-fns';
import startCase from 'lodash.startcase';
import { CUSTOM_FIELD_TYPE_ENUM, DATE_RANGE_REPORTS_MAP } from '../constants';
import {
  conditionMapping, OPERAND_ENUM, REVERSE_OPERAND_ENUM, AdvancedFilterPropMapping, CUSTOM_FIELD_HTML_CROSS_MAP, BOOLEAN_OPTIONS,
} from '../constants/filter.constant';
import { isArray } from './dataType.util';
import { makeAllOptions } from './helper.util';

export const generateOperandOptions = () => Object.keys(OPERAND_ENUM)
  .map((d) => ({
    label: startCase(d).toLowerCase(),
    value: OPERAND_ENUM[d],
  }));

export const generateConditionalOperands = (mappingType) => {
  const operandArr = conditionMapping[mappingType] || [];
  return operandArr.map((d) => ({ label: startCase(REVERSE_OPERAND_ENUM[d]).toLowerCase() || REVERSE_OPERAND_ENUM[d], value: d }));
};

export const generateConditionalOperandsFromKey = ({ key, mappingObject }) => {
  const { mappingType } = mappingObject[key] || {};
  const operandArr = conditionMapping[mappingType] || [];
  return operandArr.map((d) => ({ label: REVERSE_OPERAND_ENUM[d], value: d }));
};

export const generateValuePropsForAdvFilter = ({ mappingType, operand }) => AdvancedFilterPropMapping?.[mappingType]?.elementMap?.[operand];

const customFieldValidation = (fieldType) => {
  switch (fieldType) {
    case CUSTOM_FIELD_TYPE_ENUM.Percentage:
      return (val) => val <= 100;
    default:
      return undefined;
  }
};
export function generateAdvancedFilterMappingFromCustomFields(customFields) {
  const fieldMap = { };
  customFields
    .filter((item) => [
      CUSTOM_FIELD_TYPE_ENUM.Text,
      CUSTOM_FIELD_TYPE_ENUM.List,
      CUSTOM_FIELD_TYPE_ENUM.MultiList,
      CUSTOM_FIELD_TYPE_ENUM.Percentage,
      CUSTOM_FIELD_TYPE_ENUM.Number,
      CUSTOM_FIELD_TYPE_ENUM.Date,
      CUSTOM_FIELD_TYPE_ENUM.Amount,
      CUSTOM_FIELD_TYPE_ENUM.Boolean,
    ].includes(item.fieldMetaInfo.type))
    .forEach((d) => {
      const {
        fieldName, id, fieldMetaInfo: {
          type: fieldType,
        },
      } = d;
      fieldMap[id] = {
        type: 'custom',
        label: fieldName,
        mappingType: CUSTOM_FIELD_HTML_CROSS_MAP[fieldType],
        validation: customFieldValidation(fieldType),
      };
    });
  return fieldMap;
}

export function generateAdvancedFilterOptionsFromCustomFields(customFields) {
  return customFields.map((d) => {
    const { fieldName: label, id: value } = d;
    return { label, value };
  });
}

export function generateAdvancedFilterPropsFromCustomFields(customFields) {
  const mappingObject = {};
  customFields.forEach((d) => {
    const { id, fieldMetaInfo: { options, type, ...rest } } = d;
    mappingObject[id] = { options: makeAllOptions(isArray(options) ? options : []), type, ...rest };
    if (type === CUSTOM_FIELD_TYPE_ENUM.Boolean) {
      mappingObject[id].options = BOOLEAN_OPTIONS;
    }
  });
  return mappingObject;
}

export const getFilterValue = (value) => (isArray(value) ? value.map((d2) => d2.value) : (value?.value || []));

export const getPropertyValue = (searchKey, obj = {}) => {
  const keys = searchKey.split('.');
  if (keys.length === 1) return obj[keys[0]];
  const newObj = obj[keys[0]] || {};
  return getPropertyValue(keys.slice(1).join('.'), newObj);
};

export const rowsFilterCallbackForArrFilterType = ({ rows, filterType, searchKey }) => rows.filter((row) => {
  const keyValue = getPropertyValue(searchKey, row);
  return filterType.includes(keyValue);
});

export const rowsFilterCallbackForStringFilterType = ({ rows, filterType, searchKey }) => rows.filter((row) => {
  const keyValue = getPropertyValue(searchKey, row);
  return filterType === keyValue;
});

export const reMapFiltersCallback = ({ newFilters, selectedView, filterMap }) => {
  const { filters: parentFilters } = selectedView;
  parentFilters.forEach((d) => {
    const { filter, value } = d;
    (filterMap[filter]?.id) && newFilters.push({
      id: filterMap[filter]?.id,
      value: filterMap[filter].isDateRange ? value : getFilterValue(value),
    });
  });
};

export const handleCustomFieldValueChanges = ({ value, index, filterApplied }) => {
  const newFilterList = [...filterApplied];
  const temp = newFilterList[index];
  temp.value = value;
  newFilterList.splice(index, 1, temp);
  return filterApplied;
};

export const handleCustomFieldListOrNumberFilterChange = ({
  name, value, i, filterApplied,
}) => {
  const newFilterList = [...filterApplied];
  const temp = newFilterList[i];
  temp[name] = value;
  temp.value = null;
  temp.fieldType = 'custom';
  newFilterList.splice(i, 1, temp);
  return newFilterList;
};

export const handleCustomFieldDateFilteChange = ({
  name, value, i, filterApplied,
}) => {
  const newFilterList = [...filterApplied];
  const temp = newFilterList[i];
  temp[name] = value;
  temp.value = {
    rangeType: DATE_RANGE_REPORTS_MAP['This Month'],
    startDate: startOfMonth(Date.now()),
    endDate: endOfMonth(Date.now()),
  };
  temp.fieldType = 'custom';
  newFilterList.splice(i, 1, temp);
  return newFilterList;
};

export const isSameMultiList = ({ filterValue, value }) => value?.length === 1 && filterValue === value?.[0];

export const isAnyOfTheFilterList = ({ filterValue, value }) => value?.find((item) => filterValue?.includes(item));

export const isBetweenDateRange = ({ dateRange, value }) => value >= dateRange.startDate && value <= dateRange.endDate;

export const rowsFilterCallbackForStringContains = ({ rows, filterType, searchKey }) => rows.filter((row) => {
  const keyValue = getPropertyValue(searchKey, row);
  return keyValue?.includes(filterType);
});
