import { add, endOfDay } from 'date-fns';
import * as TYPES from '../action/type';
import { addSingleView, deleteSingleView } from '../Utils/filterReducer.util';
import { generateDefaultDateRange } from '../Utils/inactiveTasks.util';

const initialState = {
  views: [],
  isLoading: NaN,
  error: '',
  selectedView: { filters: [], operator: 'And' },
  sortingOption: { label: 'Log Time', id: 'callLogTime' },
  direction: false,
  search: '',
  tile: 'All',
  dateRange: {
    ...generateDefaultDateRange(6),
    endDate: endOfDay(add(Date.now(), { days: 1 })).getTime(),
  },
};
export const callFilterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TYPES.FETCH_CALL_VIEWS:
      newState = {
        ...curState,
        error: null,
        isLoading: TYPES.FETCH_CALL_VIEWS,
      };
      break;
    case TYPES.FETCH_CALL_VIEWS_SUCCESS:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_CALL_VIEWS_SUCCESS,
        views: action.payload.views,
      };
      break;
    case TYPES.FETCH_CALL_VIEWS_FAILURE:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_CALL_VIEWS_FAILURE,
        error: action.payload,
      };
      break;
    case TYPES.SET_CALL_SELECTED_VIEW:
      newState = {
        ...curState,
        isLoading: TYPES.SET_CALL_SELECTED_VIEW,
        selectedView: action.payload.selectedView,
      };
      break;
    case TYPES.SET_CALL_SORTING_FIELD:
      newState = {
        ...curState,
        isLoading: TYPES.SET_CALL_SORTING_FIELD,
        sortingOption: action.payload.sortingOption,
      };
      break;
    case TYPES.SET_CALL_SORTING_DIRECTION:
      newState = {
        ...curState,
        isLoading: TYPES.SET_CALL_SORTING_DIRECTION,
        direction: action.payload.direction,
      };
      break;
    case TYPES.UPDATE_SINGLE_VIEW_CALLS:
      newState = addSingleView(curState, action);
      break;
    case TYPES.ADD_SINGLE_VIEW_CALLS:
      newState = addSingleView(curState, action);
      break;
    case TYPES.DELETE_SINGLE_VIEW_CALLS:
      newState = deleteSingleView(curState, action);
      break;
    case TYPES.SET_CALL_SEARCH:
      newState = {
        ...curState,
        search: action.payload,
      };
      break;
    case TYPES.SET_CALL_TILE:
      newState = {
        ...curState,
        tile: action.payload,
      };
      break;
    case TYPES.UPDATE_CALL_DATE_RANGE:
      newState = {
        ...curState,
        dateRange: action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};

