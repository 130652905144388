import API from '../libs/amplify.lib';
import { API_NAME } from '../constants';
import { generateQueryStringFromObject } from '../Utils/query.v2.util';
import { parseResponse } from '../libs/response.lib';

const getCapabilityToken = async (query = {}) => API.get(API_NAME,
  `/twilio/token?${generateQueryStringFromObject(query)}`);

const createOutboundCall = async (query = {}) => API.get(API_NAME, `/twilio/voice?${generateQueryStringFromObject(query)}`)
  .then(parseResponse);

const getCountryCodes = async (query = {}) => API.get(API_NAME,
  `/twilio/countryCode?${generateQueryStringFromObject(query)}`);

const getAvailableNumbers = async (query = {}) => API.get(API_NAME,
  `/twilio/availableNumber?${generateQueryStringFromObject(query)}`);

const purchaseNumber = async ({ accountId, phoneNumber, phoneMetadata }) => API.put(API_NAME,
  '/twilio/purchaseNumber', { body: { accountId, phoneNumber, phoneMetadata } });

const getRecordings = async (query = { }) => API.get(API_NAME,
  `/twilio/getRecordings?${generateQueryStringFromObject(query)}`);

const deleteNumber = async ({ accountId, phoneNumber }) => API.put(API_NAME,
  '/twilio/deleteNumber', { body: { accountId, phoneNumber } });

export const addCallerId = async ({ accountId, phoneNumber }) => API.put(API_NAME,
  '/twilio/addNewCallerId', { body: { accountId, phoneNumber } });

export const deleteCallerId = async ({ accountId, outgoingCallerIdSid }) => API.put(API_NAME,
  '/twilio/deleteCallerId', { body: { accountId, outgoingCallerIdSid } });

export const sendVoicemail = async ({ CallSid, accountId, url }) => API.post(API_NAME,
  '/twilio/modifyOngoingCall', { body: { CallSid, accountId, url } });

export {
  getCapabilityToken, createOutboundCall, getCountryCodes, getAvailableNumbers,
  purchaseNumber, getRecordings, deleteNumber,
};
