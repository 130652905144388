import { endOfDay, startOfMonth } from 'date-fns';
import {
  FETCH_ACTIVITY, FETCH_ACTIVITY_SUCCESS, FETCH_ACTIVITY_FAILURE, FETCH_ACTIVITIES_BY_ID, RESET_ACTIVITY, FETCH_NEXT_ACTIVITY,
} from '../action/type';
import { updateActivitiesInRedux } from '../Utils/reducer.util';

const initialState = {
  activities: [],
  isLoading: NaN,
  error: '',
  startTime: startOfMonth(Date.now()).getTime(),
  endTime: endOfDay(Date.now()).getTime(),
  LastEvaluatedKey: null,
};
export const activityReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case RESET_ACTIVITY:
      newState = {
        ...curState,
        activities: [],
        LastEvaluatedKey: null,
      };
      break;
    case FETCH_ACTIVITY:
      newState = {
        ...curState,
        error: '',
        isLoading: FETCH_ACTIVITY,
      };
      break;
    case FETCH_ACTIVITY_SUCCESS:
      newState = {
        ...curState,
        error: '',
        isLoading: FETCH_ACTIVITY_SUCCESS,
        activities: payload.activities,
        LastEvaluatedKey: payload.LastEvaluatedKey,
        startTime: payload.startTime,
        endTime: payload.endTime,
      };
      break;
    case FETCH_ACTIVITY_FAILURE:
      newState = {
        ...curState,
        isLoading: FETCH_ACTIVITY_FAILURE,
        error: payload,
      };
      break;
    case FETCH_ACTIVITIES_BY_ID: {
      newState = updateActivitiesInRedux({ payload: action.payload, curState });
      break;
    }
    case FETCH_NEXT_ACTIVITY: {
      newState = {
        ...curState,
        error: '',
        isLoading: FETCH_NEXT_ACTIVITY,
        activities: curState.activities.concat(payload.activities).sort((a, b) => b.createdAt - a.createdAt),
        LastEvaluatedKey: payload.LastEvaluatedKey,
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
