import { isObject } from './dataType.util';
import { isBetweenDateRange } from './filter.util';

export const convertArrayToObject = ({ items, key = 'id' }) => items.reduce((prev, curr) => {
  const newCopy = { ...prev };
  if (isObject(curr) && Object.keys(curr).length) {
    newCopy[curr[key]] = curr;
  }
  return newCopy;
}, {});

export const constructIndividualProfileActivityNewState = ({ action, curState, loadingState }) => {
  const { activities, LastEvaluatedKey, entityId } = action.payload;
  const { activities: curActivities = [] } = curState;
  return {
    ...curState,
    LastEvaluatedKey,
    entityId,
    isLoading: loadingState,
    activities: curActivities.concat(activities).sort((a, b) => b.createdAt - a.createdAt),
  };
};

export const updateActivitiesInRedux = ({ payload = [], curState }) => {
  const { activities } = curState;
  const activitiesCopy = [...activities];
  const aIdMap = activitiesCopy.map((d) => d.id);
  payload.forEach((item) => {
    const oldIndex = aIdMap.indexOf(item.id);
    const newItem = { ...item };
    if (oldIndex > -1) {
      activitiesCopy[oldIndex] = newItem;
    } else {
      activitiesCopy.push(newItem);
    }
  });
  activitiesCopy.sort((a, b) => b.createdAt - a.createdAt);
  return {
    ...curState,
    activities: activitiesCopy,
  };
};

const isBetweenFetchedDateRanges = ({ completionTime, inactiveObject }) => {
  const { fetchedDateRanges } = inactiveObject;
  const result = fetchedDateRanges.reduce((p, c) => isBetweenDateRange({
    dateRange: {
      startDate: c[0], endDate: c[1],
    },
    value: completionTime,
  }) || p, false);
  return result;
};

export const findAndUpdateInObject = ({ arrObject, item, onlyUpdate = false }) => {
  if (arrObject[item.id]) {
    // eslint-disable-next-line no-param-reassign
    arrObject[item.id] = {
      ...arrObject[item.id],
      ...item,
    };
  // eslint-disable-next-line no-param-reassign
  } else if (!onlyUpdate) arrObject[item.id] = item;
};

export const findAndUpdateTask = ({
  item, seekArray, items, onlyUpdate = false,
}) => {
  const indeOfItem = seekArray.indexOf(item.id);
  if (indeOfItem > -1) {
    // eslint-disable-next-line no-param-reassign
    items[indeOfItem] = {
      ...items[indeOfItem],
      ...item,
    };
  // eslint-disable-next-line no-param-reassign
  } else if (!onlyUpdate) items.push(item);
};

export const generateSeekArray = (items, key = 'id') => {
  const { length } = items;
  const seekArray = [];
  for (let index = 0; index < length; index += 1) {
    seekArray.push(items[index][key]);
  }
  return seekArray;
};

const preCheckForCalculation = (tasks) => {
  let updateInactiveEmails = false;
  let updateInactiveCalls = false;
  let updateInactiveOutbox = false;
  let updateInactiveLinkedIn = false;
  let updateInactiveProspectOther = false;
  let updateInactiveAccountOther = false;
  let updateActive = false;
  const { length } = tasks;
  for (let index = 0; index < length; index += 1) {
    const { accountIdStatusType } = tasks[index];
    switch (true) {
      case accountIdStatusType.includes(':active'):
        updateActive = true;
        break;
      case accountIdStatusType.includes(':call'):
        updateInactiveCalls = true;
        break;
      case accountIdStatusType.includes(':email'): {
        updateInactiveEmails = true;
        break;
      }
      case accountIdStatusType.includes(':bulkCompose'): {
        updateInactiveOutbox = true;
        break;
      }
      case accountIdStatusType.includes(':linkedIn'):
        updateInactiveLinkedIn = true;
        break;
      case accountIdStatusType.includes(':prospectOther'):
        updateInactiveProspectOther = true;
        break;
      case accountIdStatusType.includes(':accountOther'):
        updateInactiveAccountOther = true;
        break;
      default:
        break;
    }
  }
  return {
    updateInactiveEmails,
    updateInactiveAccountOther,
    updateInactiveCalls,
    updateInactiveLinkedIn,
    updateInactiveOutbox,
    updateInactiveProspectOther,
    updateActive,
  };
};

export const setNewlyFetchedTasks = ({ newTasks, curState }) => {
  const {
    tasks, selectedTasks, inactiveCalls, inactiveEmails, inactiveAccountOther, inactiveLinkedIn, inactiveProspectOther, outboxEmails,
    seekIndexedArrayOfSelectedTasks, seekIndexedArrayOfTasks,
  } = curState;
  const {
    updateActive, updateInactiveEmails, updateInactiveAccountOther, updateInactiveCalls, updateInactiveLinkedIn, updateInactiveOutbox, updateInactiveProspectOther,
  } = preCheckForCalculation(newTasks);
  let taskCopy = updateActive ? [...tasks] : null;
  const selectedTasksObject = [...selectedTasks];
  const inactiveCallsObject = updateInactiveCalls ? [...inactiveCalls.items] : null;
  const inactiveEmailsObject = (updateInactiveEmails || updateInactiveOutbox) ? [...inactiveEmails.items] : null;
  const inactiveLinkedInObject = updateInactiveLinkedIn ? [...inactiveLinkedIn.items] : null;
  const inactiveAccountOtherObject = updateInactiveAccountOther ? [...inactiveAccountOther.items] : null;
  const inactiveProspectOtherObject = updateInactiveProspectOther ? [...inactiveProspectOther.items] : null;
  const outboxEmailsObject = (updateInactiveOutbox || updateInactiveEmails) ? [...outboxEmails.items] : null;
  const newState = { ...curState };
  let removedFromActiveTasks = false;
  newTasks.forEach((item) => {
    const { completionTime, accountIdStatusType } = item;
    switch (true) {
      case accountIdStatusType.includes(':active'): {
        findAndUpdateTask({ items: taskCopy, item, seekArray: seekIndexedArrayOfTasks });
        break;
      }
      case accountIdStatusType.includes(':call'):
        if (isBetweenFetchedDateRanges({ completionTime, inactiveObject: curState.inactiveCalls })) {
          findAndUpdateTask({ items: inactiveCallsObject, item, seekArray: inactiveCalls.seekIndexedArray });
        }
        break;
      case accountIdStatusType.includes(':email'):
      case accountIdStatusType.includes(':bulkCompose'): {
        if (isBetweenFetchedDateRanges({ completionTime, inactiveObject: curState.inactiveEmails })) {
          findAndUpdateTask({ items: inactiveEmailsObject, item, seekArray: inactiveEmails.seekIndexedArray });
        }
        if (isBetweenFetchedDateRanges({ completionTime, inactiveObject: curState.outboxEmails })) {
          findAndUpdateTask({ items: outboxEmailsObject, item, seekArray: outboxEmails.seekIndexedArray });
        }
        break;
      }
      case accountIdStatusType.includes(':linkedIn'):
        if (isBetweenFetchedDateRanges({ completionTime, inactiveObject: curState.inactiveLinkedIn })) {
          findAndUpdateTask({ items: inactiveLinkedInObject, item, seekArray: inactiveLinkedIn.seekIndexedArray });
        }
        break;
      case accountIdStatusType.includes(':prospectOther'):
        if (isBetweenFetchedDateRanges({ completionTime, inactiveObject: curState.inactiveProspectOther })) {
          findAndUpdateTask({ items: inactiveProspectOtherObject, item, seekArray: inactiveProspectOther.seekIndexedArray });
        }
        break;
      case accountIdStatusType.includes(':accountOther'):
        if (isBetweenFetchedDateRanges({ completionTime, inactiveObject: curState.inactiveAccountOther })) {
          findAndUpdateTask({ items: inactiveAccountOtherObject, item, seekArray: inactiveAccountOther.seekIndexedArray });
        }
        break;
      default:
        break;
    }
    if (accountIdStatusType.includes(':inactive')) {
      const indexoFActiveTask = seekIndexedArrayOfTasks.indexOf(item.id);
      if (indexoFActiveTask > -1) {
        // Delete task
        if (!taskCopy) taskCopy = [...tasks];
        taskCopy[indexoFActiveTask] = null;
        seekIndexedArrayOfTasks[indexoFActiveTask] = null;
        removedFromActiveTasks = true;
      }
    }
    findAndUpdateTask({
      items: selectedTasksObject, item, seekArray: seekIndexedArrayOfSelectedTasks, onlyUpdate: true,
    });
  });
  newState.selectedTasks = [...selectedTasksObject];
  if (updateActive || removedFromActiveTasks) {
    if (taskCopy) newState.tasks = [...(removedFromActiveTasks ? taskCopy.filter((d) => d) : taskCopy)];
    if (removedFromActiveTasks || (taskCopy.length !== tasks.length)) {
      newState.seekIndexedArrayOfTasks = generateSeekArray(newState.tasks);
    }
  }
  if (inactiveCallsObject) newState.inactiveCalls.items = [...inactiveCallsObject];
  if (inactiveEmailsObject) newState.inactiveEmails.items = [...inactiveEmailsObject];
  if (inactiveLinkedInObject) newState.inactiveLinkedIn.items = [...inactiveLinkedInObject];
  if (inactiveProspectOtherObject) newState.inactiveProspectOther.items = [...inactiveProspectOtherObject];
  if (inactiveAccountOtherObject) newState.inactiveAccountOther.items = [...inactiveAccountOtherObject];
  if (outboxEmailsObject) newState.outboxEmails.items = [...outboxEmailsObject];
  return newState;
};

const checkIfExistInseekIndexedArray = ({ items, seekIndexArray, id }) => {
  const indexOf = seekIndexArray.indexOf(id);
  if (indexOf > -1) {
    // eslint-disable-next-line no-param-reassign
    items[indexOf] = null;
    return true;
  }
  return false;
};

export const removeTasks = ({ ids, curState }) => {
  const {
    tasks, selectedTasks, seekIndexedArrayOfSelectedTasks, seekIndexedArrayOfTasks,
  } = curState;
  const newStateObj = { };
  const taskObject = [...tasks];
  const selectedTasksObject = [...selectedTasks];
  let isTasksModified = false;
  let isSelectedTasksModified = false;

  ids.forEach((id) => {
    isTasksModified = checkIfExistInseekIndexedArray({ items: taskObject, seekIndexArray: seekIndexedArrayOfTasks, id }) || isTasksModified;
    isSelectedTasksModified = checkIfExistInseekIndexedArray({ items: selectedTasksObject, seekIndexArray: seekIndexedArrayOfSelectedTasks, id }) || isSelectedTasksModified;
  });
  if (isTasksModified) {
    newStateObj.tasks = taskObject.filter((d) => d);
    newStateObj.seekIndexedArrayOfTasks = generateSeekArray(newStateObj.tasks);
  }
  if (isSelectedTasksModified) {
    newStateObj.selectedTasks = selectedTasksObject.filter((d) => d);
    newStateObj.seekIndexedArrayOfSelectedTasks = generateSeekArray(newStateObj.selectedTasks);
  }
  return newStateObj;
};

export const addFetchedDateRangeObject = ({
  start, end, isLoading, stateObject,
}) => {
  const fetchedRangeCopy = [...stateObject.fetchedDateRanges];
  fetchedRangeCopy.push([start, end, isLoading]);
  return { ...stateObject, fetchedDateRanges: fetchedRangeCopy };
};

export const concatFetchedDateRangeInactiveTasks = ({
  items, stateObject, startTime, endTime,
}) => {
  const itemCopy = [...stateObject.items].concat(items);
  const copyOfFetchArr = [...stateObject.fetchedDateRanges];
  const indexOf = copyOfFetchArr.findIndex((range) => range[0] === startTime && range[1] === endTime);
  if (indexOf > -1) {
    copyOfFetchArr[indexOf][2] = false;
  }
  return {
    ...stateObject, items: itemCopy, fetchedDateRanges: copyOfFetchArr, seekIndexedArray: generateSeekArray(itemCopy),
  };
};

export const onErrorWillFetchingInactiveTasks = ({
  error, stateObject, startTime, endTime,
}) => {
  const copyOfFetchArr = [...stateObject.fetchedDateRanges];
  const indexOf = copyOfFetchArr.findIndex((range) => range[0] === startTime && range[1] === endTime);
  if (indexOf > -1) {
    copyOfFetchArr[indexOf][2] = false;
  }
  return { ...stateObject, fetchedDateRanges: copyOfFetchArr, error };
};

export const modifyTasksStatus = (tasks) => tasks.map((d) => ({
  ...d,
  status: ['due', 'pending'].includes(d.status) ? (d.executionTime < Date.now() ? 'pending' : 'due') : d.status,
}));

export const updateItemsWithNewProperties = ({ items, dataMap, uniqueIdentifier = 'id' }) => {
  const itemsCopy = convertArrayToObject({ items, key: uniqueIdentifier });
  const ids = Object.keys(dataMap);
  let isModified = false;
  ids.forEach((id) => {
    if (itemsCopy[id]) {
      isModified = true;
      itemsCopy[id] = {
        ...itemsCopy[id],
        ...dataMap[id],
      };
    }
  });
  return { itemsCopy: Object.values(itemsCopy), isModified };
};

const isChangedFromActiveToInactive = (oldFlag, newFlag) => oldFlag.includes(':active') && newFlag.includes(':inactive');

export const updateActiveTasksWithNewProperty = ({ items, dataMap, seekIndexArray }) => {
  const itemsCopy = [...items];
  const ids = Object.keys(dataMap);
  let isModified = false;
  const inactiveItems = [];
  ids.forEach((id) => {
    const existInIndexedItem = seekIndexArray.indexOf(id);
    if (existInIndexedItem > -1) {
      const newData = dataMap[id];
      isModified = true;
      if (newData?.accountIdStatusType && isChangedFromActiveToInactive(itemsCopy[existInIndexedItem]?.accountIdStatusType, newData?.accountIdStatusType)) {
        inactiveItems.push({ ...itemsCopy[existInIndexedItem], ...newData });
        itemsCopy[existInIndexedItem] = null;
      } else {
        itemsCopy[existInIndexedItem] = { ...itemsCopy[existInIndexedItem], ...newData };
      }
    }
  });
  return { itemsCopy, isModified, inactiveItems };
};

export const createDataMapOfIds = ({ ids, data }) => {
  const map = {};
  ids.forEach((id) => { map[id] = data; });
  return map;
};

export const updateTasksWithNewProperty = ({
  items, dataMap, seekIndexArray, deleteTask = true,
}) => {
  const itemsCopy = [...items];
  const ids = Object.keys(dataMap);
  let isModified = false;
  ids.forEach((id) => {
    const existInIndexedItem = seekIndexArray.indexOf(id);
    if (existInIndexedItem > -1) {
      const newData = dataMap[id];
      isModified = true;
      itemsCopy[existInIndexedItem] = { ...itemsCopy[existInIndexedItem], ...newData };
      // eslint-disable-next-line no-param-reassign
      deleteTask && delete dataMap[id];
    }
  });
  return { itemsCopy, isModified };
};
