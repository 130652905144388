import { INSIGHTS_LOADING, INSIGHTS_LAST_MODIFIED } from '../action/type';

const initialState = {
  loading: false,
  lastModified: '',
};
export const insightsReducer = (state = initialState, action = { type: '', payload: '' }) => {
  switch (action.type) {
    case INSIGHTS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }; }
    case INSIGHTS_LAST_MODIFIED: {
      return {
        ...state,
        lastModified: action.payload,
      };
    }
    default:
      return state;
  }
};
