export const WORKFLOW_PROSPECT_TILE_DATA = 'WORKFLOW_PROSPECT_TILE_DATA';
export const WORKFLOW_PROSPECT_TILE_DATA_SUCCESS = 'WORKFLOW_PROSPECT_TILE_DATA_SUCCESS';
export const WORKFLOW_PROSPECT_TILE_DATA_FAILURE = 'WORKFLOW_PROSPECT_TILE_DATA_FAILURE';
export const RESET_WORKFLOW_PROSPECT_TILE_DATA = 'RESET_WORKFLOW_PROSPECT_TILE_DATA';

export const FETCH_WORKFLOW_PROSPECT = 'FETCH_WORKFLOW_PROSPECT';
export const FETCH_WORKFLOW_PROSPECT_SUCCESS = 'FETCH_WORKFLOW_PROSPECT_SUCCESS';
export const FETCH_WORKFLOW_PROSPECT_FAILURE = 'FETCH_WORKFLOW_PROSPECT_FAILURE';

export const SET_WORKFLOW_PROSPECT_PAGE_SIZE = 'SET_WORKFLOW_PROSPECT_PAGE_SIZE';
export const SET_WORKFLOW_PROSPECT_PAGE_INDEX = 'SET_WORKFLOW_PROSPECT_PAGE_INDEX';
export const RESET_WORKFLOW_PROSPECT_ITEMS = 'RESET_WORKFLOW_PROSPECT_ITEMS';
export const UPDATE_WORKFLOW_PROSPECT_DETAIL = 'UPDATE_WORKFLOW_PROSPECT_DETAIL';
export const SET_WORKFLOW_PROSPECT_TILE = 'SET_WORKFLOW_PROSPECT_TILE';
export const RELOAD_WORKFLOW_PROSPECT_ITEMS = 'RELOAD_WORKFLOW_PROSPECT_ITEMS';
