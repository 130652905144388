/**
* @module Constants - Add all the application constants here and use via importing them.
*/
import {
  linkedinBlueIcon, GenericBlue, autoEmailTaskSmallBlue,
  home, prospects, workflows, templates,
  calendar,
  tasks,
  settings,
  analytics,
  calls,
  account,
  opportunities,
  outbox,
  whatsappIcon,
  // envelope,
} from '../assets/images/icons';
import { isBoolean } from '../Utils/dataType.util';
import {
  isEmail, isPhoneNumber, isShortText, isValidPercentage, isValidWebsiteURL,
} from '../Utils/formValidation.util';
import { googleSignIn } from '../assets/images';

const EMAIL_PROVIDER_OPTIONS = {
  'Gmail Provider (Recommended)': {
    text: 'Sign In With Google',
    icon: 'google-plus-official',
    account: 'Google',
    img: googleSignIn,
  },
  'Microsoft Outlook': {
    text: 'Sign In With Microsoft Outlook',
    icon: 'windows',
    account: 'Microsoft',
  },
};

const API_NAME = 'upscale';

const RIGHT_CLICK_POPUP_WIDTH = 160;

const SAMPLE_FILE_PATH = `${process.env.PUBLIC_URL}/SampleFile.csv`;

const MAX_MENU_HEIGHT_THREE_OPTIONS = 36 * 3;
const MAX_MENU_HEIGHT_SIX_OPTIONS = 36 * 6;
const MAX_MENU_HEIGHT_FOUR_OPTIONS = 36 * 4;

const PRIORITY_CSS_CLASS_MAP = {
  'No priority': 'highlightGray',
  Low: 'highlightGray',
  Urgent: 'highlightBlue',
  High: 'highlightBlue',
  Normal: 'highlightGreen',
};

const TASK_TYPES = [
  'Auto Email',
  'Phone Call',
  'Manual Email',
  'Generic Task',
  'LinkedIn Task - View a profile',
  'LinkedIn Task - Send InMail',
  'LinkedIn Task - Send connection request',
  'LinkedIn Task - Send message',
  'Action Item',
  'Meet in person',
  'WhatsApp Message',
];

const TASK_TYPE_ICON_MAP = {
  img: {
    'View a profile': linkedinBlueIcon,
    'Generic Task': GenericBlue,
    'LinkedIn Task - View a profile': linkedinBlueIcon,
    'LinkedIn Task - Interact with a post': linkedinBlueIcon,
    'LinkedIn Task - Send connection request': linkedinBlueIcon,
    'LinkedIn Task - Send message': linkedinBlueIcon,
    'LinkedIn Task - Send InMail': linkedinBlueIcon,
    'Auto Email': autoEmailTaskSmallBlue,
    'Action Item': GenericBlue,
    'Meet in person': GenericBlue,
    [TASK_TYPES[10]]: whatsappIcon,
  },
  material: {
    'Manual Email': 'email',
    'Phone Call': 'phone',
    Note: 'edit_note',
    'Manual Call Log': 'perm_phone_msg',
  },
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const IS_NOT_PRODUCTION = API_BASE_URL !== 'https://api.upscale.ai';

const APP_ENV_URL = window.location.origin;

const TASK_TYPE_ENUM = {
  'Auto Email': 'Auto Email',
  'Phone Call': 'Phone Call',
  'Manual Email': 'Manual Email',
  'Generic Task': 'Generic Task',
  'LinkedIn Task - View a profile': 'LinkedIn Task - View a profile',
  'LinkedIn Task - Send InMail': 'LinkedIn Task - Send InMail',
  'LinkedIn Task - Send connection request': 'LinkedIn Task - Send connection request',
  'LinkedIn Task - Send message': 'LinkedIn Task - Send message',
  'Action Item': 'Action Item',
  'Meet in person': 'Meet in person',
  'WhatsApp Message': TASK_TYPES[10],
};
Object.freeze(TASK_TYPE_ENUM);

export const TASK_CATEGORY = [
  'Active',
  'Finished',
];

const WORKFLOW_TYPES = [
  'Steps by day interval',
  'Steps by exact date/time',
];

const WORKFLOW_INTERVAL_TYPES = {
  calendarDays: 'calendarDays',
  scheduleDays: 'scheduleDays',
};

const WORKFLOW_TYPES_OPTIONS = [{
  name: WORKFLOW_TYPES[0],
  description: 'Steps are separated by time intervals, which can range from immediately to months in the future',
}, {
  name: WORKFLOW_TYPES[1],
  description: 'Used when a one time event is being scheduled, such as a webinar, conference, or trade show',
}];

const TASK_PRIORITY_OPTIONS = [
  { value: 0, label: 'Low' },
  { value: 1, label: 'Normal' },
  { value: 2, label: 'High' },
];

const TASK_PRIORITY_MAP = {
  'No priority': -1,
  Low: 0,
  Normal: 1,
  High: 2,
  Urgent: 3,
};

const DATE_RANGE = [
  { value: 'All', label: 'All' },
  { value: 'Today', label: 'Today' },
  { value: 'Today and Later', label: 'Today and Later' },
  { value: 'Until Today', label: 'Until Today' },
  { value: 'Tomorrow', label: 'Tomorrow' },
  { value: 'Yesterday', label: 'Yesterday' },
  { value: 'This Week', label: 'This Week' },
  { value: 'Last 7 days', label: 'Last 7 days' },
];

const Gender = [
  'Male',
  'Female', 'Others',
];

const SCHEDULE_TYPES = [
  'Weekday Business Hours (India)',
  'Weekday Business Hours (US)',
  'Weekend Business Hours - Client Support (India)',
];

const DIRECTION = [
  { label: 'Ascending', value: 0 },
  { label: 'Descending', value: 1 },
];

const TASK_STATUS_MAP = {
  finished: 'finished',
  due: 'due', // overdue
  skipped: 'skipped',
  pending: 'pending', // due
  bounced: 'bounced',
  snoozed: 'snoozed',
  paused: 'paused',
  scheduled: 'scheduled',
  workflowPending: 'workflowPending',
  failed: 'failed',
};

const STATUS = [
  { label: 'All', value: 'All' },
  { label: 'Incomplete', value: 'Incomplete' },
  { label: 'Complete', value: 'Complete' },
  { label: 'Skipped', value: 'Skipped' },
];

const EVENT_STATUS_MAP = {
  cancelled: 'cancelled',
  confirmed: 'confirmed',
};

const UPSCALE_ADMIN_EMAIL = process.env.REACT_APP_UPSCALE_ADMIN_EMAIL;
const REGISTERATION_PASSWORD = process.env.REACT_APP_TEMP_REG_PASSWORD;
const DATE_RANGE_REPORTS = [
  { value: 'Yesterday', label: 'Yesterday' },
  { value: 'Today', label: 'Today' },
  { value: 'Last Week', label: 'Last Week' },
  { value: 'This Week', label: 'This Week' },
  { value: 'Last Month', label: 'Last Month' },
  { value: 'This Month', label: 'This Month' },
  { value: 'Custom Date Range', label: 'Custom Date Range' },
];

const DATE_RANGE_REPORTS_MAP = {
  Yesterday: 'Yesterday',
  Today: 'Today',
  'Last Week': 'Last Week',
  'This Week': 'This Week',
  'Last Month': 'Last Month',
  'This Month': 'This Month',
  'Custom Date Range': 'Custom Date Range',
};

const ACTIVITY_MAP = [
  { value: 'All Activity', label: 'All Activity' },
  { value: 'Emails', label: 'Emails' },
  { value: 'Calls', label: 'Calls' },
];

export const CUSTOM_FIELD_TYPE_ENUM = {
  Phone: 'Phone',
  Text: 'Text',
  Number: 'Number',
  List: 'List',
  Date: 'Date',
  MultiList: 'MultiList',
  LongText: 'LongText',
  Email: 'Email',
  Boolean: 'Boolean',
  URL: 'URL',
  Percentage: 'Percentage',
  Amount: 'Amount',
  AsyncListV2: 'AsyncListV2',
  SelectV2: 'SelectV2',
  TimeRange: 'TimeRange',
};
const CUSTOM_FIELD_TYPES_VALIDATION_MESSAGE = {
  [CUSTOM_FIELD_TYPE_ENUM.Text]: 'Write quick notes of up to 64 characters only',
  [CUSTOM_FIELD_TYPE_ENUM.LongText]: 'Create long description with unlimited characters',
  [CUSTOM_FIELD_TYPE_ENUM.Number]: 'Include numeric values',
  [CUSTOM_FIELD_TYPE_ENUM.Date]: 'Select date from the calendar',
  [CUSTOM_FIELD_TYPE_ENUM.Email]: 'Insert a valid email address',
  [CUSTOM_FIELD_TYPE_ENUM.Phone]: 'Enter a valid phone or mobile number',
  [CUSTOM_FIELD_TYPE_ENUM.List]: 'Select a single item from the list of option',
  [CUSTOM_FIELD_TYPE_ENUM.MultiList]: 'Select multiple items from the list of option',
  [CUSTOM_FIELD_TYPE_ENUM.Percentage]: 'Apply percentage validation between 0 to 100',
  [CUSTOM_FIELD_TYPE_ENUM.Boolean]: 'Include True/False values',
  [CUSTOM_FIELD_TYPE_ENUM.URL]: 'Insert valid URL of a website, profile, etc.',
  [CUSTOM_FIELD_TYPE_ENUM.Amount]: 'Add value or amount with currency option',
};

const SUPPORT_EMAIL = 'support@upscale.ai';

const SETTING_YOU_ROUTE = '/settings/you';

const APP_MAIN_ROUTE = {
  PROSPECTS: '/prospects',
  ACCOUNTS: '/accounts',
  DASHBOARD: '/',
  WORKFLOWS: '/workflows',
  TASKS: '/tasks',
  MEETINGS: '/meetings',
  TEMPLATES: '/templates',
  REPORTS: '/reports',
  SETTINGS: `${SETTING_YOU_ROUTE}`,
  OPPORTUNITIES: '/opportunities',
  OUTBOX: '/outbox',
  CALLS: '/calls',
};

const CUSTOM_FIELD_HTML_TYPE_MAP = {
  [CUSTOM_FIELD_TYPE_ENUM.Phone]: { htmlElement: 'input', type: 'number', validation: isPhoneNumber },
  [CUSTOM_FIELD_TYPE_ENUM.List]: { htmlElement: 'select' },
  [CUSTOM_FIELD_TYPE_ENUM.Text]: { htmlElement: 'input', type: 'text', validation: isShortText },
  [CUSTOM_FIELD_TYPE_ENUM.Number]: { htmlElement: 'input', type: 'number' },
  [CUSTOM_FIELD_TYPE_ENUM.LongText]: { htmlElement: 'textarea', type: 'text' },
  [CUSTOM_FIELD_TYPE_ENUM.MultiList]: { htmlElement: 'multiSelect' },
  [CUSTOM_FIELD_TYPE_ENUM.Date]: { htmlElement: 'reactDate' },
  [CUSTOM_FIELD_TYPE_ENUM.Amount]: { htmlElement: 'currency' },
  [CUSTOM_FIELD_TYPE_ENUM.Boolean]: { htmlElement: 'select', validation: isBoolean },
  [CUSTOM_FIELD_TYPE_ENUM.URL]: { htmlElement: 'input', type: 'text', validation: isValidWebsiteURL },
  [CUSTOM_FIELD_TYPE_ENUM.Percentage]: { htmlElement: 'input', type: 'number', validation: isValidPercentage },
  [CUSTOM_FIELD_TYPE_ENUM.Email]: { htmlElement: 'input', type: 'text', validation: isEmail },
};

const ICON_SET = {
  [TASK_TYPE_ENUM['Generic Task']]: 'fa-calendar-check-o',
  [TASK_TYPE_ENUM['Manual Email']]: 'fa-envelope',
  [TASK_TYPE_ENUM['Auto Email']]: 'fa-envelope',
  [TASK_TYPE_ENUM['Phone Call']]: 'fa-phone',
  [TASK_TYPE_ENUM['Action Item']]: 'fa-calendar-check-o',
  [TASK_TYPE_ENUM['Meet in person']]: 'fa-calendar-check-o',
  [TASK_TYPE_ENUM['LinkedIn Task - View a profile']]: 'fa-linkedin',
  [TASK_TYPE_ENUM['LinkedIn Task - Send InMail']]: 'fa-linkedin',
  [TASK_TYPE_ENUM['LinkedIn Task - Send connection request']]: 'fa-linkedin',
  [TASK_TYPE_ENUM['LinkedIn Task - Send message']]: 'fa-linkedin',
  [TASK_TYPES[10]]: 'fa-whatsapp',
  default: 'fa-linkedin',
};

const WORKFLOW_SHARED_OPTIONS_ENUM = [
  'Private to you',
  'Let others use it',
];

const WORKFLOW_SHARED_OPTIONS = [
  { name: WORKFLOW_SHARED_OPTIONS_ENUM[0], label: 'Private to owner', value: WORKFLOW_SHARED_OPTIONS_ENUM[0] },
  { name: WORKFLOW_SHARED_OPTIONS_ENUM[1], label: WORKFLOW_SHARED_OPTIONS_ENUM[1], value: WORKFLOW_SHARED_OPTIONS_ENUM[1] },
];
Object.freeze(WORKFLOW_SHARED_OPTIONS);

export const TEMPLATE_SHARED_OPTIONS_MAP = {
  [WORKFLOW_SHARED_OPTIONS_ENUM[0]]: 'Usage of this template after its creation is limited only to you. Please note that this template cannot be used in Public Sequences.',
  [WORKFLOW_SHARED_OPTIONS_ENUM[1]]: 'This template can be used in Public and Private Sequences after its creation.',
};

const ACCOUNT_TASK_TYPES = {
  'Action Item': {
    type: 'manual',
    name: 'Action Item',
  },
  'Meet in person': {
    type: 'manual',
    name: 'Meet in person',
  },
};
Object.freeze(ACCOUNT_TASK_TYPES);

const TASK_TAG_ENUM = {
  prospect: 'prospect',
  account: 'account',
};
Object.freeze(ACCOUNT_TASK_TYPES);

export const UPSCALE_UNAUTHORIZE_ROUTES = [
  {
    route: '/login', name: 'Login',
  }, {
    route: '/signup', name: 'Signup',
  }, {
    route: '/signupComplete', name: 'Signup Complete',
  }, {
    route: '/resetPassword', name: 'Reset Password',
  }, {
    route: '/Unsubscribe', name: 'Unsubscribe',
  },
];

const UPSCALE_ROUTES = [
  {
    img: home,
    route: '/',
    name: 'Dashboard',
  }, { img: prospects, route: '/prospects', name: 'Prospects' },
  { img: account, route: '/accounts', name: 'Accounts' },
  { img: opportunities, route: '/opportunities', name: 'Opportunities' },
  { img: workflows, route: '/workflows', name: 'Sequences' },
  { img: tasks, route: '/tasks', name: 'Tasks' },
  { img: outbox, route: '/outbox', name: 'Outbox' },
  { img: calls, route: '/calls', name: 'Calls' },
  // ['commenting', '/comments'],
  // ['bar-chart', '/chart'],
  { img: templates, route: '/templates', name: 'Templates' },
  { img: calendar, route: '/meetings', name: 'Meetings' },
  { img: analytics, route: '/reports', name: 'Reports' },
];

const SETTING_ITEM = {
  img: settings, route: SETTING_YOU_ROUTE, name: 'Settings',
};

const ADD_USER_ROUTE = '/settings/users/add';

const MAILBOX_ROUTE = `${SETTING_YOU_ROUTE}?tab=Mailboxes`;

const BILLING_ROUTE = '/settings/billing';

const MODIFIED_TASK_ORDER = ['Auto Email', 'Manual Email', 'Phone Call', 'LinkedIn Task - View a profile', 'LinkedIn Task - Send connection request', 'LinkedIn Task - Send message', 'LinkedIn Task - Send InMail', 'Generic Task', TASK_TYPES[10]];

const TASK_SUMMARY_END_SHARED_OPTIONS = [
  'after a prospect is added to the workflow.',
  'once previous step is executed.',
];

const TASK_SUMMARY_START_SHARED_OPTIONS = {
  'Auto Email': 'Step Summary: An email will be automatically delivered ',
  'Phone Call': 'Step Summary: A phone call task will be due ',
  'Manual Email': 'Step Summary: An email task will be due ',
  'Generic Task': 'Step Summary: A generic task will be due ',
  'LinkedIn Task - View a profile': 'Step Summary: A LinkedIn task to view a profile will be due ',
  'LinkedIn Task - Send InMail': 'Step Summary: A LinkedIn task to send in mail will be due ',
  'LinkedIn Task - Send connection request': 'Step Summary: A LinkedIn task to send connection request will be due ',
  'LinkedIn Task - Send message': 'Step Summary: A LinkedIn task to send message will be due ',
  [TASK_TYPES[10]]: 'Step Summary: A WhatsApp task to send message will be due',
  cannotAddEmailStepsNote: 'Note: A new email step cannot be added between existing email steps.',
  exactDateInvalidTime: 'To add steps between two steps, you must specify the time interval between them.',
};

const HOME_SCREEN_TASK_NAMES = {
  'Auto Email': 'Auto Emails',
  'LinkedIn Task - Send InMail': 'LinkedIn',
  'LinkedIn Task - Interact with a post': 'LinkedIn',
  'LinkedIn Task - Send connection request': 'LinkedIn',
  'LinkedIn Task - Send message': 'LinkedIn',
  'LinkedIn Task - View a profile': 'LinkedIn',
  'Generic Task': 'Generic',
  'Action Item': 'Generic',
  'Meet in person': 'Generic',
  'Manual Email': 'Manual Emails',
  'Phone Call': 'Calls',
  [TASK_TYPES[10]]: 'WhatsApp',
};
const WORKFLOW_TASK_NAMES = {
  'Auto Email': 'Send an Auto Email',
  'LinkedIn Task - Send InMail': 'Send an InMail',
  'LinkedIn Task - Send connection request': 'Send a Connection Request',
  'LinkedIn Task - Send message': 'Send a Message',
  'LinkedIn Task - View a profile': 'View a Profile',
  'Generic Task': 'Set a Generic Task',
  'Action Item': 'Set a Generic Task',
  'Meet in person': 'Meet in person',
  'Manual Email': 'Send a Manual Email',
  'Phone Call': 'Call a Prospect',
  [TASK_TYPES[10]]: 'Send a WhatsApp Message',
};

const PING_URL = 'https://www.google.com/';

const NO_SIGNATURE = 'No Signature';

const ONE_MB_SIZE = 1048576;
const WEBSOCKET_CONFIG = {
  reconnectInterval: 10000,
  maxReconnectInterval: 30000,
  reconnectDecay: 1.5,
  maxReconnectAttempts: 6,
  pingInterval: 10000, // ping interval is 10 secs
};
Object.freeze(WEBSOCKET_CONFIG);

const PROSPECT_FIELD_MAPPER = ['jobTitle', 'firstName', 'lastName', 'prospectEmail', 'workPhone', 'fullName', 'organisation', 'personalPhone', 'otherPhone'];
const ACCOUNT_FIELD_MAPPER = ['accountName', 'accountDomain'];
const SENDER_MAPPER = ['sender.name', 'sender.email', 'sender.company', 'sender.title'];
const PROSPECT_BASIC_PHONE_FIELDS = ['personalPhone', 'workPhone', 'otherPhone'];

const DYNAMIC_FIELD_MAPPER = {
  jobTitle: 'position',
  firstName: 'firstName',
  lastName: 'lastName',
  stage: 'stage',
  prospectEmail: 'prospectEmail',
  workPhone: 'workPhone',
  fullName: 'prospectName',
  organisation: 'organisation',
  personalPhone: 'personalPhone',
  otherPhone: 'otherPhone',
  accountName: 'accountName',
  accountDomain: 'accountDomain',
  'sender.name': 'sender.name',
  'sender.email': 'sender.email',
  'sender.company': 'sender.company',
  'sender.title': 'sender.title',
};

// NOTE: Please do not add space between comma separated rgb values
const MISSING_VARIABLE_COLOR = 'rgb(248, 206, 204)';
const AVAIALABLE_VARIABLE_COLOR = 'rgb(204, 255, 230)';

const CALL_STATUS = {
  completed: 'Completed',
  busy: 'Busy',
  'no-answer': 'No answer',
  canceled: 'Canceled',
  failed: 'Failed',
  unknown: 'Unknown',
  IN_PROGRESS: 'in-progress',
};

const MAX_LENGTH_FOR_STAGE_NAME = 64;
const MAX_LENGTH_FOR_STAGE_NAME_TABLE = 24;
const MAX_LENGTH_FOR_CALL_NOTES = 50;
const MAX_LENGTH_FOR_STAGE_TILES = 30;
const MAX_LENGTH_FOR_PROSPECT_NAME_OVERVIEW = 20;
const MAX_LENGTH_FOR_WORKFLOW_NAME = 45;

const CALL_TYPE_MAP = {
  0: 'Outbound',
  1: 'Inbound',
};

const CALL_TYPE_OPTIONS = Object.keys(CALL_TYPE_MAP).map((d) => ({ label: CALL_TYPE_MAP[d], value: d }));

export const CURRENCY_OPTION_MAP = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  INR: '₹',
  SGD: 'S$',
};

export const CURRENCY_FORMAT_MAP = {
  USD: 'en-US',
  // INR: 'en-IN',
  INR: 'en-US',
  JPY: 'ja-JP',
  SGD: 'en-US',
};

export const WORKFLOW_PROSPECT_STATUS_MAP = {
  pending: 'Pending',
  active: 'Active',
  paused: 'Paused',
  finished: 'Finished',
  completed: 'Completed',
  replied: 'Replied',
  noReply: 'No Reply',
  optedOut: 'Opted Out',
  unsubscribed: 'Unsubscribed',
  finishedReplied: 'Finished (Replied)',
  finishedNoReply: 'Finished (No Reply)',
  bounced: 'Bounced',
  skipped: 'Skipped',
  opened: 'Opened',
  clicked: 'Clicked',
};

export const WORKFLOW_PROSPECT_STATUS_COLOR_CODE = {
  pending: '#BDBDBD',
  active: '#78909C',
  paused: '#FFD54F',
  optedOut: '#F44336',
  unsubscribed: '#F44336',
  finishedReplied: '#2E7D32',
  finishedNoReply: '#81C784',
  default: '#03dac6',
};

export const STEP_INSIGHT_LABELS = {
  isOpened: 'Opened',
  isClicked: 'Clicked',
  isReplied: 'Replied',
  isDelivered: 'Delivered',
  attempted: 'Attempted',
  answered: 'Answered',
  notAnswered: 'No Answer',
  // averageCallDuration: 'Avg Call Duration',
};

export const OCR_LEGENDS = [
  'Delivered', 'Opened', 'Clicked', 'Replied',
];

export const MEETING_BOOKED_INSIGHT = {
  meetingBooked: 'Meetings Booked',
};

const WORKFLOW_STEP_INSIGHT_COLOR_MAP = {
  active: '#6ba63c',
  completed: '#3874cb',
  bounced: '#de6969',
  paused: '#f6b500',
  skipped: '#118ab2',
  unsubscribed: '#de6969',
  optedOut: '#de6969',
  finished: 'rgb(88, 87, 87)',
};

export const WORKFLOW_GRAPH_INSIGHT_COLOR_MAP = {
  Opened: '#EB9285',
  Clicked: '#70B738',
  Replied: '#F6B500',
  Delivered: '#9f9f9f',
  Attempted: '#8A7026',
  Answered: '#61CDBB',
  'No Answer': '#C39DDA',
  'Meetings Booked': '#7879F1',
};

const INDIVIDUAL_WORKFLOW_INSIGHT_TYPES = ['Step Wise Insights', 'Summarized Insights'];

export const TASK_SUB_TYPES_ENUM = {
  TASK: 'TASK',
  CALL_LOGGED: 'CALL_LOGGED',
  EMAIL: 'EMAIL',
};

export const CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP = {
  account: 'a',
  prospect: 'p',
};

const PASSWORD_CHECKS = [
  'Contain at least 8 characters',
  'Have at least one uppercase and at least one lowercase letter',
  'Have at least one number',
  'Have at least one special character (!,@,#,$,% etc.)',
  'Is not your sign in username/email',
];

const PASSWORD_FORMAT_CHECK_ERROR_MESSAGE = 'Password does not satisfy format requirements';

export const NUMBER_FILTER_SELECTION_MAP = {
  '=': 'Equal to',
  '<': 'Less than',
  '>': 'Greater than',
  '<>': 'Between',
};

export const TASK_CREATE_FOR = [
  'workflow', 'bulkTask', 'trigger',
];
const DATE_RANGE_INSIGHTS = [
  { value: 'Last Week', label: 'Last Week' },
  { value: 'This Week', label: 'This Week' },
  { value: 'Last Month', label: 'Last Month' },
  { value: 'This Month', label: 'This Month' },
  { value: 'Custom Date Range', label: 'Custom Date Range' },
];
const DATE_RANGE_INSIGHTS_MAP = {
  'Last Week': 'Last Week',
  'This Week': 'This Week',
  'Last Month': 'Last Month',
  'This Month': 'This Month',
  'Custom Date Range': 'Custom Date Range',
};

const REPORTS_WORKFLOW_FILTER_MAP = [
  { value: 'All', label: 'All (Sequence + Non Sequence)' },
  { value: 'workflow', label: 'Sequence' },
  { value: 'nonWorkflow', label: 'Non Sequence' },
];

export const TASK_STATUS_TYPE = ['Due', 'Overdue', 'Completed', 'Skipped', 'Paused', 'Scheduled', 'Failed'];
export const FILTER_TASK_STATUS_MAP = {
  [TASK_STATUS_TYPE[0]]: [TASK_STATUS_MAP.due],
  [TASK_STATUS_TYPE[1]]: [TASK_STATUS_MAP.pending, TASK_STATUS_MAP.snoozed],
  [TASK_STATUS_TYPE[2]]: [TASK_STATUS_MAP.finished],
  [TASK_STATUS_TYPE[3]]: [TASK_STATUS_MAP.skipped],
  [TASK_STATUS_TYPE[4]]: [TASK_STATUS_MAP.paused],
  [TASK_STATUS_TYPE[5]]: [TASK_STATUS_MAP.scheduled],
  [TASK_STATUS_TYPE[6]]: [TASK_STATUS_MAP.failed],
};

export const ALLOWED_PROSPECT_FOR_REACT_SELECT = 20;
export const ALLOWED_PROSPECT_FOR_REACT_SELECT_IN_BULK_COMPOSE = 5;

// Change this as and when change in backend
export const TIME_BETWEEN_TWO_EMAILS = 6 * 1000; // in ms

export const ONE_DAY_IN_MS = 86399999; // 1 ms less

export const WORKFLOW_PROSPECT_OPERATIONS = {
  markAsFinished: 'markAsFinished',
  removeFromWorkflow: 'removeFromWorkflow',
  pauseWorkflow: 'pauseWorkflow',
  markAsReplied: 'markAsReplied',
  moveToAStep: 'moveToAStep',
  resumeWorkflow: 'resumeWorkflow',
  optedOut: 'optedOut',
  retry: 'retry',
  forceAssignWorkflow: 'forceAssignWorkflow',
  activate: 'activate',
};
Object.freeze(WORKFLOW_PROSPECT_OPERATIONS);
export const UNSUBSCRIBE_CONTENT_CLASSNAME = 'editor-unsubscribed-content-6cd33710764836a78b74212320210716';

export const MAX_PROSPECT_CAN_BE_ADDED_TO_WORKFLOW_AT_A_TIME = 5000;

export const MIN_TIME_BETWEEN_TWO_CONSECUTIVE_WORKFLOW_STEP = 5 * 60 * 1000; // in ms

export const DEFAULT_PROSPECT_STAGES = ['Unsubscribed', 'Opted Out'];
export const SUBSCRIBE_TYPES = ['unsubscribedEmail', 'optedOut'];
export const WORKFLOW_STATUS_FILTER_OPTION = [
  { value: 'enabled', label: 'Enabled' },
  { value: 'disabled', label: 'Disabled' },
];

export const ACTION_REPORT_SUCCESS_TOAST_MAP = {
  'Skip/Delete Tasks': 'Successfully skipped/deleted tasks',
  'Assign Prospect To Workflow': 'Successfully assigned prospect(s) to sequence',
  'Bulk Update Prospect': 'Successfully updated prospect(s)',
  'Add Prospect To Account': 'Successfully added prospect(s) to account',
  'Workflow paused for prospect': 'Successfully paused sequence for prospect(s)',
  'Workflow resumed for prospect': 'Successfully resumed sequence for prospect(s)',
  'Workflow marked as finished for prospect': 'Successfully sequence is marked as finished for the prospect',
  'Prospect removed from workflow': 'Successfully removed prospect(s) from sequence',
  'Prospect marked as replied': 'Successfully marked prospect(s) as replied',
  'Moved to step': 'Successfully moved to step',
  'Prospect marked as Opted Out': 'Successfully marked prospect(s) as Opted Out',
  'Reschedule Task': 'Successfully rescheduled tasks',
  'Reassign Task': 'Successfully reassigned tasks',
};

export const MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_CONNECTION_REQUEST = 300;
export const MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_INMAIL = 8000;
export const MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_MESSAGE = 8000;
export const MAX_CHARACTERS_ALLOWED_FOR_WHATSAPP_MESSAGE = 65535;

const REPORT_TABS = [
  'Team Activity',
  'Email',
  'Calls',
  'All Tasks',
];

const CHART_TYPE_MAP = [
  { value: 'Line', label: 'Line' },
  { value: 'Bar', label: 'Bar' },
];

export const SEND_ME_TEST_EMAIL_BUTTON_TEXT = 'Send Test Email';
export const FOLLOW_UP_EMAIL_SUBJECT = 'Re: Send as reply to previous thread';
export const SIGNATURE_STARTING_REGEX = '<p style="display: none; margin: 0px; box-sizing: inherit;" fr-original-style="display: none;">--</p>';
export const MAX_SIZE_OF_EMAIL_BODY_ALLOWED = 260; // in KB
export const MAX_SIZE_OF_CELL_ALLOWED_IN_DB = 350; // in KB
export const CREATE_SCHEDULE_ROUTE = 'Create Schedule';
export const MAILBOX_UNSYNC_ERR = 'In order to use the email feature, please link any of your mailbox account to upscale.';

export const STAGE_RESERVERED_KEYWORDS = [
  "don't update", 'do not update',
];
export const NON_EDITABLE_TASK_STATUS = [TASK_STATUS_MAP.finished, TASK_STATUS_MAP.skipped, TASK_STATUS_MAP.failed];

export const INDIVIDUAL_PROFILE_ENTITY_TYPE = {
  prospect: 'prospect',
  account: 'account',
  opportunity: 'opportunity',
};

export const ACTIVITY_ITEMS_PER_PAGE = 5;

export const USER_OBJECT = {
  notifications: {
    'Exclude open/click notifications if we think it’s you': true,
    'Send a weekly email with stats and leaderboard for your subordinates': true,
    'Enable Desktop Notification': true,
    'Send a daily digest email of tasks and stats': true,
    'Notify by email if we detect a reply from a non-recipient': false,
    'Send alerts by email on mailbox sync errors': false,
    'Notify by email if you’re approaching your bounce limit': false,
  },
  basicInfo: {
    firstName: '',
    lastName: '',
    upscaleId: '',
    phoneNumber: '',
    email: '',
  },
  emailSending: {
    cc: [],
    bcc: [],
  },
  roleAndProfile: {
    title: 'Admin',
    governanceProfile: 'Admin',
    role: 'Governance Role',
  },
  companyEmail: '',
  accountId: '',
  tags: [],
};

export const ACCOUNT_INITIAL_STATE = {
  addedBy: '',
  clientEmail: '',
  isVerified: true,
  maxUserCount: 1,
  modifiedClientEmail: '',
  plan: 'basic',
  subscription: '',
  subscriptionStatus: true,
  supportExecutiveEmail: '',
};

export const ADDRESS_INITIAL_STATE = {
  address: '',
  city: '',
  postalCode: '',
  companyRegistrationId: '',
  companyName: '',
  primaryPhone: '',
  secondaryPhone: '',
  state: '',
  country: '',
  defaultTimeZone: '',
};
export const GOOGLE_SHEET_ICON_URL = 'https://www.gstatic.com/images/branding/product/2x/sheets_2020q4_48dp.png';
export const COMPETITOR_DOMAINS = ['outplayhq.com', 'outplayhq.co', 'outplay.ai', 'outplaycloud.com', 'reply.io', 'activecampaign.com', 'outreach.io', 'apollo.io', 'salesloft.com', 'mixmax.com',
  'vanillasoft.com', 'mailshake.com', 'smartreach.io', 'yesware.com', 'klenty.com', 'salesflare.com', 'persistiq.com', 'interseller.io', 'salesmate.io', 'insidesales.com', 'autoklose.com',
  'funnelflare.io', 'smartreach.io', 'snov.io', 'prospect.io', 'saleshandy.com', 'airborneapp.io', 'mailshake.com', 'salesblink.io', 'conquer.io', 'gryphon.ai', 'koncert.com,', 'revenue.io',
  'vymo.com', 'insidesales.com', 'spotio.com', 'bloobirds.com', 'salesgear.io'];

export const SETTING_PLUGINS_ROUTE = '/settings/plugins';

export const isLocal = process.env.NODE_ENV === 'development';

export const EXEMPTED_STATUS_CODES = [200, 202, 102, 432, 431, 433];

export {
  UPSCALE_ROUTES, SETTING_ITEM, ADD_USER_ROUTE, MAILBOX_ROUTE,
  RIGHT_CLICK_POPUP_WIDTH, PROSPECT_BASIC_PHONE_FIELDS,
  SAMPLE_FILE_PATH, MAX_MENU_HEIGHT_THREE_OPTIONS, MAX_MENU_HEIGHT_SIX_OPTIONS,
  TASK_TYPES, API_NAME, API_BASE_URL, WORKFLOW_TYPES, IS_NOT_PRODUCTION,
  TASK_PRIORITY_OPTIONS, SCHEDULE_TYPES, Gender, PRIORITY_CSS_CLASS_MAP,
  TASK_TYPE_ICON_MAP, DIRECTION, TASK_STATUS_MAP,
  DATE_RANGE, STATUS, EVENT_STATUS_MAP,
  TASK_PRIORITY_MAP, UPSCALE_ADMIN_EMAIL,
  REGISTERATION_PASSWORD, DATE_RANGE_REPORTS, ACTIVITY_MAP,
  SUPPORT_EMAIL, APP_MAIN_ROUTE, EMAIL_PROVIDER_OPTIONS,
  CUSTOM_FIELD_TYPES_VALIDATION_MESSAGE, TASK_TYPE_ENUM, CUSTOM_FIELD_HTML_TYPE_MAP,
  DATE_RANGE_REPORTS_MAP, ICON_SET, WORKFLOW_SHARED_OPTIONS, ACCOUNT_TASK_TYPES,
  TASK_TAG_ENUM, APP_ENV_URL, MODIFIED_TASK_ORDER, TASK_SUMMARY_START_SHARED_OPTIONS,
  TASK_SUMMARY_END_SHARED_OPTIONS, HOME_SCREEN_TASK_NAMES,
  WORKFLOW_SHARED_OPTIONS_ENUM, WORKFLOW_TYPES_OPTIONS,
  WEBSOCKET_CONFIG, NO_SIGNATURE, PROSPECT_FIELD_MAPPER, ACCOUNT_FIELD_MAPPER, SENDER_MAPPER, CALL_STATUS,
  MAX_LENGTH_FOR_STAGE_NAME, MAX_LENGTH_FOR_STAGE_NAME_TABLE, MAX_LENGTH_FOR_CALL_NOTES, MAX_LENGTH_FOR_STAGE_TILES,
  MAX_LENGTH_FOR_PROSPECT_NAME_OVERVIEW,
  MAX_LENGTH_FOR_WORKFLOW_NAME, CALL_TYPE_MAP, CALL_TYPE_OPTIONS, DYNAMIC_FIELD_MAPPER,
  MISSING_VARIABLE_COLOR, AVAIALABLE_VARIABLE_COLOR, PASSWORD_CHECKS,
  DATE_RANGE_INSIGHTS, DATE_RANGE_INSIGHTS_MAP, REPORTS_WORKFLOW_FILTER_MAP,
  ONE_MB_SIZE, REPORT_TABS, CHART_TYPE_MAP, PASSWORD_FORMAT_CHECK_ERROR_MESSAGE, BILLING_ROUTE, SETTING_YOU_ROUTE,
  WORKFLOW_INTERVAL_TYPES, WORKFLOW_TASK_NAMES, WORKFLOW_STEP_INSIGHT_COLOR_MAP, INDIVIDUAL_WORKFLOW_INSIGHT_TYPES, PING_URL,
  MAX_MENU_HEIGHT_FOUR_OPTIONS,
};
