import {
  FETCH_TASK_VIEW_FAILURE_V2, FETCH_TASK_VIEW_SUCCESS_V2, FETCH_TASK_VIEW_V2, RESET_TASK_VIEW_ITEMS,
  SET_ACTIVE_TASK_ID,
} from '../action/Task';

const initialState = {
  isLoading: NaN,
  error: '',
  items: [],
  totalCount: 0,
  requestId: '',
  activeTaskId: '',
};
export const taskViewReducerV2 = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_TASK_VIEW_V2:
      newState = {
        ...curState,
        error: null,
        isLoading: true,
        requestId: action.payload.requestId,
      };
      break;
    case FETCH_TASK_VIEW_FAILURE_V2:
      newState = {
        ...curState, isLoading: false, skip: 0, error: action.payload, requestId: '',
      };
      break;
    case FETCH_TASK_VIEW_SUCCESS_V2: {
      const {
        resultArr, requestId,
      } = action.payload;
      if (requestId === curState.requestId) {
        newState = {
          ...curState,
          totalCount: resultArr.length,
          isLoading: false,
          items: resultArr.map((d) => d.id),
        };
      } else {
        newState = curState;
      }

      break;
    }
    case RESET_TASK_VIEW_ITEMS: {
      newState = { ...initialState };
      break;
    }
    case SET_ACTIVE_TASK_ID: {
      newState = {
        ...curState,
        activeTaskId: action.payload,
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

