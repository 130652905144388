import {
  RESET_WORKFLOW_PROSPECT_TILE_DATA, WORKFLOW_PROSPECT_TILE_DATA, WORKFLOW_PROSPECT_TILE_DATA_FAILURE, WORKFLOW_PROSPECT_TILE_DATA_SUCCESS,
} from '../action/WorkflowProspect';

const initialState = {
  workflowId: '',
  isLoading: NaN,
  error: '',
  data: { All: 0 },
};
export const workflowProspectTilesReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case WORKFLOW_PROSPECT_TILE_DATA: {
      newState = {
        ...curState,
        workflowId: action.payload.workflowId,
        isLoading: true,
      };
      break;
    }
    case WORKFLOW_PROSPECT_TILE_DATA_SUCCESS: {
      const newDataObj = { all: 0 };
      action.payload.resultArr.forEach((data) => {
        newDataObj[data._id] = data.count;
      });
      newState = {
        ...curState,
        data: newDataObj,
        isLoading: false,
      };
      break;
    }
    case WORKFLOW_PROSPECT_TILE_DATA_FAILURE: {
      newState = {
        ...curState,
        error: action.payload,
        isLoading: false,
      };
      break;
    }
    case RESET_WORKFLOW_PROSPECT_TILE_DATA: {
      newState = { ...curState, isLoading: NaN, error: '' };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

