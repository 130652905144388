import {
  FETCH_UPSCALE_SETTINGS, FETCH_UPSCALE_SETTINGS_FAILURE,
  FETCH_UPSCALE_SETTINGS_SUCCESS,
} from '../action/type';

const initialState = {
  plan: '', upscaleSetting: { planInfo: { }, commonSettings: { } }, isLoading: NaN, error: '',
};
export const upscaleSettingReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case FETCH_UPSCALE_SETTINGS:
      newState = {
        ...curState, error: null, isLoading: FETCH_UPSCALE_SETTINGS, ...payload,
      };
      break;
    case FETCH_UPSCALE_SETTINGS_SUCCESS:
      newState = { ...curState, isLoading: FETCH_UPSCALE_SETTINGS_SUCCESS, ...payload };
      break;
    case FETCH_UPSCALE_SETTINGS_FAILURE:
      newState = {
        ...curState, isLoading: FETCH_UPSCALE_SETTINGS_FAILURE, email: '', error: payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};
