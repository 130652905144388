import mixpanel from 'mixpanel-browser';
import { HOME_SCREEN_TASK_NAMES, WORKFLOW_SHARED_OPTIONS_ENUM } from '../constants';
import { isArray, isObject } from '../Utils/dataType.util';
import { generateName, isChangedObject } from '../Utils/helper.util';

const token = process.env.REACT_APP_MIXPANEL_TOKEN;

const MIXPANEL_EVENTS = {
  'User Verified': 'User Verified',
  'Sign Up': 'Sign Up',
  'Self Signed Up': 'Self Signed Up',
  'Self Signed Up Failed': 'Self Signed Up Failed',
  'Sign In': 'Sign In',
  'Sign Out': 'Sign Out',
  'Sign Up Failed': 'Sign Up Failed',
  'Raise a ticket': 'Raise a ticket',
  'View Help & FAQ': 'View Help & FAQ',
  'Talk To Us': 'Talk To Us',
  'Upscale Documentation Visit': 'Upscale Documentation Visit',
  'Sync Email': 'Sync Email',
  'Invite Users': 'Invite Users',
  'Email sent': 'Email sent',
  'Made outbound call': 'Made outbound call',
  'Edit Prospect': 'Edit Prospect',
  'Import Prospects': 'Import Prospects',
  'Execute task': 'Execute task',
  'Edit prospects': 'Edit prospects',
  'View Prospect': 'View Prospect',
  'Add tags': 'Add tags',
  'Navigation Tab': 'Navigation Tab',
  'Table Pagination': 'Table Pagination',
  'View Dashboard': 'View Dashboard',
  'Sort Tasks by Priority: Dashboard': 'Sort Tasks by Priority: Dashboard',
  'View Task': 'View Task',
  'Notes Activity': 'Notes Activity',
  'Delete Prospect': 'Delete Prospect',
  'Update Prospect': 'Update Prospect',
  'Add a Prospect': 'Add a Prospect',
  'Add Account': 'Add Account',
  'Add Prospect to Account': 'Add Prospect to Account',
  'Update Account': 'Update Account',
  'Edit Account': 'Edit Account',
  'Remove Prospect': 'Remove Prospect',
  'Delete Account': 'Delete Account',
  'Create Account Task': 'Create Account Task',
  'Add Opportunity': 'Add Opportunity',
  'Add Prospect to Opportunity': 'Add Prospect to Opportunity',
  'Update Opportunity': 'Update Opportunity',
  'Edit Opportunity': 'Edit Opportunity',
  'Delete Opportunity': 'Delete Opportunity',

  'Create Workflow': 'Create New Workflow',
  'Customize a Blueprint Workflow': 'Customize a Blueprint Workflow',
  'Clone a Workflow': 'Clone a Workflow',
  'Edit Workflow Settings': 'Edit Workflow Settings',
  'Add Step': 'Add Step to Workfow',
  'Edit Step': 'Edit Workflow Step',
  'Copy Workflow Step': 'Copy Workflow Step',
  'Delete Step': 'Delete Workflow Step',
  'Add Template to Workflow Step': 'Add Template to Workflow Step',
  'Assign Prospects to Workflow': 'Assign Prospects to Workflow',
  'Enable Workflow': 'Enable Workflow',
  'Disable Workflow': 'Disable Workflow',
  'Delete Workflow': 'Delete Workflow',
  '3-Dot hover in Workflow': '3-Dot hover in Workflow',

  'Create Task': 'Create Task',
  'View Task Tile': 'View Task Tile',
  'Execute Call Task': 'Execute Call Task',
  'Execute LinkedIn Task': 'Execute LinkedIn Task',
  'Execute Other Task': 'Execute Other Task',
  'Execute Email Task': 'Execute Email Task',
  'Skip Task': 'Skip Task',
  'Delete Task': 'Delete Task',

  'Compose Email': 'Compose Email',
  'View Email Activity': 'View Email Activity',
  'Respond to Email': 'Respond to Email',
  'Add Variable Field': 'Add Variable Field',
  'Insert Image': 'Insert Image',
  'Insert Attachment': 'Insert Attachment',
  'Insert Link': 'Insert Link',
  'Manage Signature': 'Manage Signature',
  'Email Preview': 'Email Preview',
  'Formatting Options': 'Formatting Options',
  'View Email Thread': 'View Email Thread',

  'Dial a Prospect': 'Dial a Prospect',
  'Log Call': 'Log Call',
  'Edit Logged Call': 'Edit Logged Call',
  'Call Back: Missed Call': 'Call Back: Missed Call',
  'Listen to Call Recording': 'Listen to Call Recording',

  'Create Template': 'Create Template',
  'Edit template': 'Edit template',
  'Send me a test email': 'Send me a test email',
  'Edit Blueprint Template': 'Edit Blueprint Template',
  'Clone Template': 'Clone Template',
  'Edit template Setting': 'Edit template Setting',
  'Delete Template': 'Delete Template',
  'Add Tags To Template': 'Add Tags To Template',
  // Settings
  'Add Call Disposition': 'Add Call Disposition',
  'Edit Call Disposition': 'Edit Call Disposition',
  'Delete Call Disposition': 'Delete Call Disposition',
  'Add Call Purpose': 'Add Call Purpose',
  'Edit Call Purpose': 'Edit Call Purpose',
  'Delete Call Purpose': 'Delete Call Purpose',
  'Add Ruleset': 'Add Ruleset',
  'Edit Ruleset': 'Edit Ruleset',
  'Clone Ruleset': 'Clone Ruleset',
  'Delete Ruleset': 'Delete Ruleset',
  'Add Custom Field': 'Add Custom Field',
  'Edit Custom Field': 'Edit Custom Field',
  'Delete Custom Field': 'Delete Custom Field',
  'Add New Stage': 'Add New Stage',
  'Edit Stage': 'Edit Stage',
  'Delete Stage': 'Delete Stage',
  'Add Profile': 'Add Profile',
  'Edit Profile': 'Edit Profile',
  'Clone Profile': 'Clone Profile',
  'Delete Profile': 'Delete Profile',
  'Add Schedule': 'Add Schedule',
  'View Schedule': 'View Schedule',
  'Edit Schedule': 'Edit Schedule',
  'Delete Schedule': 'Delete Schedule',
  'Clone Schedule': 'Clone Schedule',
  'Disconnect Mailbox': 'Disconnect Mailbox',
  'Call Recording': 'Call Recording',
  'Call Routing': 'Call Routing',
  'Add Routing Number': 'Add Routing Number',
  'Delete Routing Number': 'Delete Routing Number',
  'Lock User': 'Lock User',
  'Purchase Phone Number': 'Purchase Phone Number',
  'Delete Purchased Number': 'Delete Purchased Number',
  'Edit Purchased Number': 'Edit Purchased Number',
  'Delete Signature': 'Delete Signature',
  'Edit Signature Body': 'Edit Signature Body',
  'Edit Signature Name': 'Edit Signature Name',
  'Create Signature': 'Create Signature',
  // Subscription settings
  'Delete Caller ID': 'Delete Caller ID',
  'Add Credits': 'Add Credits',
  'Manage Cards': 'Manage Cards',
  'Update Subscription': 'Update Subscription',
  'Upgrade Subscription': 'Upgrade Subscription',

  // Management toolbar
  'Create Smart View': 'Create Smart View',
  'Edit Smart View': 'Edit Smart View',
  'Delete Smart View': 'Delete Smart View',
  'Use Smart View': 'Use Smart View',
  'Click Stage Tile': 'Click Stage Tile',
  'Add Filter': 'Add Filter',
  'Clear Filter': 'Clear Filter',
  'Use Search Tool': 'Use Search Tool',
  // Reports
  'View Workflow Performance': 'View Workflow Performance',
  'View Activities Completed': 'View Activities Completed',

  'Auto Logged Out': 'Auto Logged Out',
  'Sign Out Free Trial Unpaid Customer': 'Sign Out Free Trial Unpaid Customer',
};

Object.freeze(MIXPANEL_EVENTS);

const isTokenDetected = () => token || '';

const initializeMixpanel = () => {
  if (!isTokenDetected()) return;
  mixpanel.init(token, { batch_requests: true, protocol: 'https' });
};

// wait until Mixpanel is loaded
// alternatively you may use the loaded callback in the init() or set_config(): https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L95
function waitUntilLoad(func) {
  if (mixpanel.__loaded) func();
  else {
    setTimeout(() => {
      waitUntilLoad(func);
    }, 10);
  }
}

const registerCommonSettings = (user) => {
  waitUntilLoad(() => {
    if (!isTokenDetected()) return;
    if (isObject(user)) {
      const {
        email, accountId, id, basicInfo = {}, organisationName,
      } = user;
      mixpanel.identify(id);
      const userName = generateName(basicInfo);
      /* Capture organisation as Company Name in super prop (Industry to be disc) */
      const properties = {
        $email: email, // only reserved properties need the $
        $distinct_id: id, // use human-readable names
        $name: userName,
        'Account Id': accountId,
        'Company Name': organisationName || '',
      };
      mixpanel.people.set(properties);
      mixpanel.register({
        email, accountId, id, name: userName, 'Company Name': organisationName || '',
      });
    }
  });
};

const onlyIdentifyUser = (id) => {
  waitUntilLoad(() => {
    if (!isTokenDetected()) return;
    id && mixpanel.identify(id);
  });
};

const getSectionFromURL = () => {
  const { pathname } = window.location;
  switch (true) {
    case pathname === '/prospects':
      return 'Prospect Table';
    case pathname === '/accounts':
      return 'Accounts Table';
    case pathname === '/opportunities':
      return 'Opportunities Table';
    case pathname === '/workflows':
      return 'Workflows Table';
    case pathname === '/tasks':
      return 'Tasks Table';
    case pathname === '/templates':
      return 'Templates Table';
    case pathname === '/calls':
      return 'Calls Table';
    case pathname === '/outbox':
      return 'Outbox Table';
    case pathname === '/reports':
      return 'Reports Table';
    case pathname === '/':
      return 'Dashboard';
    case pathname === '/settings/prospects':
      return 'Prospects';
    case pathname === '/settings/accounts':
      return 'Accounts';
    case pathname === '/settings/opportunities':
      return 'Opportunities';
    case pathname === '/templates/switchTemplate':
      return 'Template Creation';
    case pathname.includes('templates/view'):
      return 'Template Page';
    case pathname.includes('/prospects'):
      return 'Prospect Profile';
    case pathname.includes('/accounts'):
      return 'Account Profile';
    case pathname.includes('/opportunities'):
      return 'Opportunity Profile';
    case pathname.includes('/workflows/view'):
      return 'Workflow Profile';
    case pathname === '/meetings':
      return 'Meetings';
    default:
      return pathname;
  }
};

const VOICE_SETTING_FLAG_MAP = {
  recordAllCalls: 'isCallRecordingEnabled',
  routeCallsWhenOffline: 'isCallRoutingUsed',
};
const VOICE_SETTING_FLAG_MIXPANEL_EVENTS = {
  recordAllCalls: 'Call Recording',
  routeCallsWhenOffline: 'Call Routing',
};

const MIXPANEL_SHARING_OPTIONS_MAP = {
  [WORKFLOW_SHARED_OPTIONS_ENUM[0]]: 'Private',
  [WORKFLOW_SHARED_OPTIONS_ENUM[1]]: 'Public',
};

// the actual increment logic
function incrementLogic(property, value) {
  const prop = {};
  prop[property] = 0;
  mixpanel.register_once(prop);
  prop[property] = mixpanel.get_property(property) + value;
  mixpanel.register(prop);
}

// wrapper for the incremental event logic
function increment(property, value) {
  waitUntilLoad(() => {
    incrementLogic(property, value);
  });
}

// increment the user property and setup the event incrementory
function superIncrementLogic(property, amount) {
  increment(property, amount);
  mixpanel.people.increment(property, amount); // comment this line out if you do not wish to add a 'user profile property'
}

// a wrapper which acts a superIncrement method accepting a 'property' and 'amount'
function superIncrement(property, amount) {
  waitUntilLoad(() => {
    superIncrementLogic(property, amount);
  });
}

const logEvent = (event, data = {}, superProperty = {}) => {
  if (!isTokenDetected()) return;
  const pageName = getSectionFromURL();
  if (isArray(superProperty)) {
    superProperty.forEach((d) => {
      const { property, value } = d;
      superIncrement(property, value);
    });
  } else if (isObject(superProperty)) {
    if (superProperty.property) superIncrement(superProperty.property, superProperty.value);
  }
  mixpanel.track(event, { ...data, pageName });
};

const generateViewTaskEventProperties = (task) => {
  const { taskName, workflowId } = task;
  return { taskType: HOME_SCREEN_TASK_NAMES[taskName], taskMode: workflowId ? 'Workflow' : 'Independent' };
};

const generateViewProspectEventProperties = (prospect) => {
  const {
    lastContacted, stage,
  } = prospect;
  return {
    prospectLastcontacted: lastContacted || 0,
    prospectStage: stage,
  };
};

const generateEditProspectProperties = ({ prevProp, newProp }) => {
  const checkProp = (key) => isChangedObject(newProp[key], prevProp[key]);
  return {
    editStage: checkProp('stage'),
    editAccount: checkProp('prospectAccountId'),
    editTags: checkProp('tags'),
    editJobTitle: checkProp('position'),
    editOrganisation: checkProp('organisation'),
    editCountry: checkProp('country'),
    editWorkEmail: checkProp('workEmail'),
    editPersonalEmail: checkProp('personalEmail'),
    editLinkedIn: checkProp('linkedInURL'),
    editWebsiteURL: checkProp('websiteURL'),
    isCustomFieldEdited: checkProp('customFields'),
    editWorkPhone: checkProp('workPhone'),
    editOtherPhone: checkProp('otherPhone'),
    editPersonalPhone: checkProp('personalPhone'),
  };
};

const generateAddAProspectProperties = (prospect) => {
  const hasProp = (key) => !!prospect[key];
  return {
    addType: 'Individual',
    prospectAccount: hasProp('prospectAccountId'),
    prospectTagsCount: prospect?.tags?.length,
    prospectJobTitle: hasProp('position'),
    prospectOrganisation: hasProp('organisation'),
    prospectCountry: hasProp('country'),
    prospectWorkEmail: hasProp('workEmail'),
    prospectPersonalEmail: hasProp('personalEmail'),
    prospectLinkedIn: hasProp('linkedInURL'),
    prospectWebsiteURL: hasProp('websiteURL'),
    prospectWorkPhone: hasProp('workPhone'),
    prospectPersonalPhone: hasProp('personalPhone'),
    prospectOtherPhone: hasProp('otherPhone'),
    isCustomFieldFilled: !!Object.keys(prospect.customFields || {}).length,
  };
};

const getPathForActions = (key) => {
  const path = getSectionFromURL();
  switch (true) {
    case path.toLowerCase().includes('profile'):
      return `${key} Profile`;
    case path.toLowerCase().includes('table'):
      return `${key} Table`;
    default:
      return 'Actions';
  }
};

const checkItrativelyProp = (keys, prevProp, newProp) => {
  let val1; let val2;
  keys.forEach((key) => {
    val1 = prevProp[key];
    val2 = newProp[key];
  });
  return isChangedObject(val1, val2);
};

const generateEditAccountProperties = ({ prevProp, newProp }) => {
  const checkProp = (key) => isChangedObject(newProp[key], prevProp[key]);
  return {
    editName: checkProp('name'),
    editDomain: checkProp('domain'),
    isCustomFieldEdited: checkProp('customFIelds'),
  };
};

const generateEditOpportunityProperties = ({ prevProp, newProp }) => {
  const checkProp = (key) => isChangedObject(newProp[key], prevProp[key]);
  return {
    editName: checkProp('name'),
    editConfidenceInterval: checkProp('probability'),
    editStage: checkProp('stage'),
    editClosedate: checkProp('closeDate'),
    editAccount: checkProp('account'),
    isCurrencyEdited: checkProp('amount'),
    editCurrency: newProp.amount.currency,
    editAmount: newProp.amount.value,
    isCustomFieldEdited: checkProp('customFields'),
  };
};

const generateEditWorkflowSettingsProperties = ({ prevProp, newProp }) => ({
  isEditName: checkItrativelyProp(['name'], prevProp, newProp),
  isEditWorkflowRuleset: checkItrativelyProp(['workflowRuleset'], prevProp, newProp),
  isEditOwner: checkItrativelyProp(['userId'], prevProp, newProp),
});

const generateEditWorkflowStepProperties = ({ prevProp, newProp }) => ({
  isEditStepType: checkItrativelyProp(['task', 'name'], prevProp, newProp),
  editStepTypeTo: newProp.task?.name,
  isEditTaskPriority: checkItrativelyProp(['taskpriority'], prevProp, newProp),
  editTaskPriorityto: newProp.taskpriority,
  isEditTaskNotes: checkItrativelyProp(['note'], prevProp, newProp),
  taskNotesLength: (newProp.note || '').length,
});

const generateEditLoggedCallProperties = ({ prevProp, newProp }) => ({
  isEditCallType: checkItrativelyProp(['type'], prevProp, newProp),
  isEditCallPurpose: checkItrativelyProp(['purpose'], prevProp, newProp),
  isEditCallDisposition: checkItrativelyProp(['disposition'], prevProp, newProp),
  isEditCallTags: checkItrativelyProp(['tags'], prevProp, newProp),
  editedCallTagsCount: (newProp.tags || []).length,
  isEditCallNotes: checkItrativelyProp(['note'], prevProp, newProp),
  editedCallNotesLength: (newProp.note || []).length,
});

export const managePreviewToggle = (callback, showPreview) => {
  callback && callback();
  logEvent(MIXPANEL_EVENTS['Email Preview'], {
    previewStatus: showPreview ? 'Hide' : 'Show',
  });
};

const generateTemplateEditProperties = ({ prevProp, newProp }) => ({
  isEditName: checkItrativelyProp(['name'], prevProp, newProp),
  isEditOwner: checkItrativelyProp(['owner'], prevProp, newProp),
  isEditSharing: checkItrativelyProp(['sharingOption'], prevProp, newProp),
  isEditTags: checkItrativelyProp(['tags'], prevProp, newProp),
  editSharingTo: newProp.sharingOption,
  tagsCount: (newProp.tags || []).length,
});

const generateSettingEditCallDispositionProperties = ({ prevProp, newProp }) => ({
  editDispositionName: checkItrativelyProp(['name'], prevProp, newProp),
  editDispositionType: checkItrativelyProp(['status'], prevProp, newProp),
  editMarkPositiveOutcome: checkItrativelyProp(['isPositive'], prevProp, newProp),
});

const generateSettingEditStageProperties = ({ prevProp, newProp }) => ({
  editSection: getSectionFromURL(),
  editName: checkItrativelyProp(['name'], prevProp, newProp),
  editStageType: checkItrativelyProp(['opportunityType'], prevProp, newProp),
});

const getStageSectionFromURL = () => {
  const path = getSectionFromURL();
  switch (true) {
    case path.toLowerCase().includes('table'):
      return path.split(' ')[0];
    case path === 'Workflow Profile':
      return 'Workflow Prospect';
    default:
      return path;
  }
};

const generateEditSmartViewProperties = ({ prevProp, newProp }) => ({
  editSection: getStageSectionFromURL(),
  editOwner: checkItrativelyProp(['ownerId'], prevProp, newProp),
  editSharing: checkItrativelyProp(['sharingOption'], prevProp, newProp),
  viewSharing: MIXPANEL_SHARING_OPTIONS_MAP[newProp.sharingOption],
  editFilters: isChangedObject(prevProp.filters, newProp.filters),
  editOperator: isChangedObject(prevProp.operator, newProp.operator),
});

const getEventAndDataPropertyForReportInisghts = (action) => {
  let event;
  switch (action) {
    case 'activityType': case 'dateRange':
      event = MIXPANEL_EVENTS['View Activities Completed'];
      break;
    case 'workFlowBy':
      event = MIXPANEL_EVENTS['View Workflow Performance'];
      break;
    default:
      break;
  }
  return event;
};

const captureEditSignatureMixPanelEvent = ({ prevProp, newProp }) => {
  let totalSignatureEdited = 0;
  for (let index = prevProp.length - 1; index >= 0; index -= 1) {
    const prevSignatureObj = prevProp[index];
    const newSignatureObj = newProp[index];
    if (newSignatureObj) {
      const isBodyEdited = checkItrativelyProp(['body'], prevSignatureObj, newSignatureObj);
      totalSignatureEdited += !!isBodyEdited;
    }
  }
  if (totalSignatureEdited) {
    logEvent(MIXPANEL_EVENTS['Edit Signature Body'], {}, {
      property: '#totalSignatureBodyEdited', value: totalSignatureEdited,
    });
  }
};

export {
  MIXPANEL_EVENTS, initializeMixpanel, registerCommonSettings, logEvent, onlyIdentifyUser,
  getSectionFromURL, generateViewTaskEventProperties,
  generateViewProspectEventProperties, generateEditProspectProperties,
  superIncrement, generateAddAProspectProperties, getPathForActions,
  generateEditAccountProperties, generateEditOpportunityProperties,
  generateEditWorkflowSettingsProperties, generateEditWorkflowStepProperties,
  generateEditLoggedCallProperties, generateTemplateEditProperties,
  generateSettingEditCallDispositionProperties, generateSettingEditStageProperties,
  VOICE_SETTING_FLAG_MAP, VOICE_SETTING_FLAG_MIXPANEL_EVENTS, MIXPANEL_SHARING_OPTIONS_MAP,
  generateEditSmartViewProperties, getStageSectionFromURL, getEventAndDataPropertyForReportInisghts, captureEditSignatureMixPanelEvent,
};
