import emitter from '../Models/Emitter';

export const APP_EVENTS = {
  'Add Prospect': 'Add Prospect',
  'Log Call': 'Log Call',
  Dial: 'Dial',
  'Create Task': 'Create Task',
  'Create Sequence': 'Create Sequence',
  'Create Template': 'Create Template',
  'Bulk Compose': 'Bulk Compose',
  'Import Prospects': 'Import Prospects',
  'Add Account': 'Add Account',
  'Add User': 'Add User',
  'Add Opportunity': 'Add Opportunity',
  updateTags: 'updateTags',
  createNewMeet: 'createNewMeet',
  globalSearch: 'globalSearch',
};

Object.freeze(APP_EVENTS);

export const fireAppEvent = (event, params) => {
  emitter.emit(event, params);
};

export const addEmitterListener = (event, eventFunction) => emitter.addListener(
  event, eventFunction,
);

export const fireCreateTask = ({ defaultSelectedProspects, bulkCount, queryParams }) => emitter.emit(APP_EVENTS['Create Task'], { defaultSelectedProspects, bulkCount, queryParams });

export const removeEmitterListener = (token) => token.remove();

window.upscaleEmitter = emitter;
