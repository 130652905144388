import { OPPORTUNITY_TILE_DATA, OPPORTUNITY_TILE_DATA_FAILURE, OPPORTUNITY_TILE_DATA_SUCCESS, RESET_OPPORTUNITY_TILE_DATA } from '../action/Opportunity';

const initialState = {
  isLoading: NaN,
  data: { All: { count: 0, value: 0 } },
  error: '',
};
export const opportunityTilesReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case OPPORTUNITY_TILE_DATA: {
      newState = {
        ...curState,
        isLoading: true,
      };
      break;
    }
    case OPPORTUNITY_TILE_DATA_SUCCESS: {
      const newDataObj = { All: { count: 0, value: 0 } };
      action.payload.resultArr.forEach((data) => {
        newDataObj[data._id] = {
          ...data,
          value: data.amount,
        };
        newDataObj.All.value += data.amount;
        newDataObj.All.count += data.count;
      });
      newState = {
        ...curState,
        data: newDataObj,
        isLoading: false,
      };
      break;
    }
    case OPPORTUNITY_TILE_DATA_FAILURE: {
      newState = {
        ...curState,
        error: action.payload,
        isLoading: false,
      };
      break;
    }
    case RESET_OPPORTUNITY_TILE_DATA: {
      newState = { ...initialState };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

