import {
  PROSPECT_TILE_DATA, PROSPECT_TILE_DATA_FAILURE, PROSPECT_TILE_DATA_SUCCESS, RESET_PROSPECT_TILE_DATA,
} from '../action/Prospect';

const initialState = {
  isLoading: NaN,
  error: '',
  data: { All: 0 },
};
export const prospectTilesReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case PROSPECT_TILE_DATA: {
      newState = {
        ...curState,
        isLoading: true,
      };
      break;
    }
    case PROSPECT_TILE_DATA_SUCCESS: {
      const newDataObj = { All: 0 };
      let totalCount = 0;
      action.payload.resultArr.forEach((data) => {
        newDataObj[data._id] = data.count;
        totalCount += Number(data.count);
      });
      newDataObj.All = totalCount;
      newState = {
        ...curState,
        data: newDataObj,
        isLoading: false,
      };
      break;
    }
    case PROSPECT_TILE_DATA_FAILURE: {
      newState = {
        ...curState,
        error: action.payload,
        isLoading: false,
      };
      break;
    }
    case RESET_PROSPECT_TILE_DATA: {
      newState = { ...curState, isLoading: NaN, error: '' };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

