import {
  SET_SEARCH_KEYWORD, FETCH_RECENT_SEARCH, FETCH_RECENT_SEARCH_SUCESS, FETCH_RECENT_SEARCH_FAILURE, ADD_SEARCH_VIEW,
} from '../action/type';

const initialState = {
  accountId: '',
  searchKeyword: '',
  recentSearches: [],
  isLoading: NaN,
  error: '',
};
export const universalSearchReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case SET_SEARCH_KEYWORD:
      newState = {
        ...curState,
        error: null,
        isLoading: SET_SEARCH_KEYWORD,
        searchKeyword: action.payload,
      };
      break;
    case FETCH_RECENT_SEARCH:
      newState = {
        ...curState,
        isLoading: FETCH_RECENT_SEARCH,
        recentSearches: [],
      };
      break;
    case FETCH_RECENT_SEARCH_SUCESS:
      newState = {
        ...curState,
        isLoading: FETCH_RECENT_SEARCH_SUCESS,
        recentSearches: action.payload,
      };
      break;
    case FETCH_RECENT_SEARCH_FAILURE:
      newState = {
        ...curState,
        isLoading: FETCH_RECENT_SEARCH_FAILURE,
        error: action.payload,
      };
      break;
    case ADD_SEARCH_VIEW: {
      const newRecentSearches = [...curState.recentSearches];
      newRecentSearches.unshift(action.payload);
      newState = {
        ...curState,
        isLoading: ADD_SEARCH_VIEW,
        recentSearches: newRecentSearches.slice(0, 20),
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

