import {
  FETCH_OUTBOX_FAILURE_V2, FETCH_OUTBOX_SUCCESS_V2, FETCH_OUTBOX_V2, OUTBOX_FAILED_COUNT, RELOAD_OUTBOX_ITEMS, RESET_OUTBOX_ITEMS, SET_OUTBOX_PAGE_INDEX, SET_OUTBOX_PAGE_SIZE,
} from '../action/Outbox';

const initialState = {
  isLoading: NaN,
  error: '',
  items: [],
  pageIndex: 0,
  pageSize: 10,
  totalCount: 0,
  requestId: '',
  outboxFailedCount: 0,
};
export const outboxReducerV2 = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_OUTBOX_V2:
      newState = {
        ...curState,
        error: null,
        isLoading: true,
        ...action.payload,
      };
      break;
    case FETCH_OUTBOX_FAILURE_V2:
      newState = {
        ...curState, isLoading: false, skip: 0, error: action.payload, requestId: '',
      };
      break;
    case FETCH_OUTBOX_SUCCESS_V2: {
      const {
        resultArr, totalCount, requestId,
      } = action.payload;
      if (requestId === curState.requestId) {
        newState = {
          ...curState,
          totalCount,
          isLoading: false,
          items: curState.items.concat(resultArr),
        };
      } else {
        newState = curState;
      }

      break;
    }
    case SET_OUTBOX_PAGE_SIZE: {
      newState = {
        ...initialState,
        isLoading: curState.isLoading,
        pageSize: action.payload,
      };
      break;
    }
    case SET_OUTBOX_PAGE_INDEX: {
      newState = {
        ...curState,
        pageIndex: action.payload,
      };
      break;
    }
    case RESET_OUTBOX_ITEMS: {
      newState = { ...initialState };
      break;
    }
    case RELOAD_OUTBOX_ITEMS: {
      const { pageSize } = curState;
      newState = { ...initialState, pageIndex: 0, pageSize };
      break;
    }
    case OUTBOX_FAILED_COUNT: {
      newState = { ...curState, outboxFailedCount: action.payload };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

