import * as TYPES from '../action/type';
import { FALLBACK_COUNTRY_LIST } from '../assets/externalData/country.constant';

const initialState = {
  isLoading: NaN,
  error: '',
  countryList: FALLBACK_COUNTRY_LIST,
  auth_token: '',
};

export const ThirdPartyDataReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TYPES.FETCH_COUNTRY_DATA_SUCCESS:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_COUNTRY_DATA_SUCCESS,
        countryList: action.payload.countryList,
        auth_token: action.payload.auth_token,
      };
      break;
    case TYPES.FETCH_COUNTRY_DATA_FAILURE:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_COUNTRY_DATA_FAILURE,
        error: action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};

