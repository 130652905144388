import { isFunction } from '../Utils/dataType.util';

export const hideHelpCrunch = () => isFunction(window.HelpCrunch) && window.HelpCrunch('closeChat');

export const openHelpCrunchChatWindow = () => isFunction(window.HelpCrunch) && window.HelpCrunch('openChat');

export const loadHelpcrunchScript = () => {
  window.helpcrunchSettings = {
    organization: 'upscale',
  };
  // eslint-disable-next-line
  (function (w, d) { var hS = w.helpcrunchSettings; if (!hS || !hS.organization) { return; } var widgetSrc = 'https://' + hS.organization + '.widget.helpcrunch.com/'; w.HelpCrunch = function () { w.HelpCrunch.q.push(arguments) }; w.HelpCrunch.q = []; function r() { if (d.querySelector('script[src="' + widgetSrc + '"')) { return; } var s = d.createElement('script'); s.async = 1; s.type = 'text/javascript'; s.src = widgetSrc; (d.body || d.head).appendChild(s); } if (d.readyState === 'complete' || hS.loadImmediately) { r(); } else if (w.attachEvent) { w.attachEvent('onload', r) } else { w.addEventListener('load', r, false) } })(window, document)
};

export const initializeHelpcrunch = ({
  email, name, phone, company, userId,
}) => {
  window.HelpCrunch('init', 'upscale', {
    appId: process.env.REACT_APP_HELPCRUNCH_APP_ID,
    user: {
      email,
      name,
      phone,
      company,
      user_id: userId,
    },
  });
  window.HelpCrunch('onNewUnreadChat', openHelpCrunchChatWindow);

  window.HelpCrunch('onAgentMessage', openHelpCrunchChatWindow);
  window.HelpCrunch('onReady', () => {
    const helpcrunchDiv = document.getElementsByClassName('helpcrunch-iframe-wrapper')[0];
    if (helpcrunchDiv) {
      const iframeDocument = helpcrunchDiv.children[0].contentWindow.document;
      iframeDocument.head.insertAdjacentHTML('beforeend', '<style>.helpcrunch-trigger-button-show{display:none}</style>');
    }
    hideHelpCrunch();
  });
  window.HelpCrunch('onChatClose', () => {
    const helpcrunchIframe = document.getElementsByClassName('helpcrunch-iframe-wrapper')[0]?.children[0];
    if (helpcrunchIframe) {
      helpcrunchIframe.style.display = 'none';
    }
  });

  window.HelpCrunch('onChatOpen', () => {
    const helpcrunchIframe = document.getElementsByClassName('helpcrunch-iframe-wrapper')[0]?.children[0];
    if (helpcrunchIframe) {
      helpcrunchIframe.style.display = 'block';
    }
  });
};
