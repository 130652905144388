import {
  SPINNER_COUNTER,
} from '../action/type';

const initialState = {
  spinnerCounter: 0,
};

export const spinnerCounterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState = curState;
  const { type } = action;
  if (type === SPINNER_COUNTER) {
    const { spinnerCounter } = curState;
    newState = {
      ...curState,
      spinnerCounter: spinnerCounter + 1,
    };
  }
  return newState;
};

