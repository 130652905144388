import React, { Component } from 'react';
import Spinner from 'react-spinkit';
import './index.scss';

export default class FullScreenSpinner extends Component {
// ClearTimeout not added
  componentDidMount() {
    const { functionReturningPromise, onResolve, onReject } = this.props;
    setTimeout(() => {
      functionReturningPromise && functionReturningPromise()
        .then(onResolve)
        .catch(onReject);
    });
  }

  render() {
    const {
      customClass, name, style, spinnerColor = '#1E78F0', text = '',
    } = this.props;
    const customeName = name || 'ball-clip-rotate';
    return (
      <div className={`fullscreen-spinner flex align-items-center justify-content-center ${customClass}`} style={style}>
        <Spinner name={customeName} color={spinnerColor} fadeIn="none" />
        {text && <p className="spinnerInnerText marginLeftSix font15 bold">{text}</p>}
      </div>
    );
  }
}
