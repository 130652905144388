import {
  FETCH_CUSTOM_FIELDS, FETCH_CUSTOM_FIELDS_SUCCESS,
  FETCH_CUSTOM_FIELDS_FAILURE,
  ADD_NEW_STAGE_SUCCESS,
  UPDATE_STAGE_SUCCESS,
  DELETE_STAGE_SUCCESS,
  ADD_NEW_PROSPECT_CUSTOM_FIELD_SUCCESS,
  UPDATE_PROSPECT_CUSTOM_FIELD_SUCCESS,
  DELETE_PROSPECT_CUSTOM_FIELD_SUCCESS,
  ADD_NEW_RULESET_SUCCESS,
  ADD_NEW_ACCOUNT_CUSTOM_FIELD_SUCCESS,
  UPDATE_ACCOUNT_CUSTOM_FIELD_SUCCESS,
  DELETE_ACCOUNT_CUSTOM_FIELD_SUCCESS,
  UPDATE_RULESET_SUCCESS,
  DELETE_RULESET_SUCCESS,
  UPDATE_CALL_DISPOSITION_SUCCESS,
  DELETE_CALL_DISPOSITION_SUCCESS,
  ADD_NEW_CALL_PURPOSE_SUCCESS,
  UPDATE_CALL_PURPOSE_SUCCESS,
  DELETE_CALL_PURPOSE_SUCCESS,
  ADD_NEW_CALL_DISPOSITION_SUCCESS,
  ADD_NEW_PROFILE_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  DELETE_PROFILE_SUCCESS,
  ADD_NEW_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_NUMBER_SUCCESS,
  DELETE_PHONE_NUMBER_SUCCESS,
  UPDATE_HUBSPOT_USER_MAP,
  UPDATE_HUBSPOT_STAGE_MAP,
  UPDATE_PIPEDRIVE_USER_MAP,
  ADD_NEW_OPPORTUNITY_STAGE_SUCCESS,
  UPDATE_OPPORTUNITY_STAGE_SUCCESS,
  DELETE_OPPORTUNITY_STAGE_SUCCESS,
  ADD_NEW_OPPORTUNITY_CUSTOM_FIELD_SUCCESS,
  UPDATE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS,
  DELETE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS,
  SET_UPSCALE_PIPEDRIVE_OPPORTUNITY_STGAE_MAPPING,
  ADD_NEW_TRIGGER_SUCCESS,
  UPDATE_TRIGGER_SUCCESS,
  DELETE_TRIGGER_SUCCESS,
  ADD_NEW_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_SUCCESS,
  UPDATE_SALESFORCE_USER_MAP,
  UPDATE_SALESFORCE_STAGE_MAP,
  ADD_CUSTOM_FIELD_IN_ARR,
  UPDATE_CUSTOM_FIELD_IN_ARR,
  CUSTOM_FIELD_TYPE_MAP,
  DELETE_CUSTOM_FIELD_IN_ARR,
  UPDATE_CUSTOM_FIELD_IN_MAP,
  DELETE_CUSTOM_FIELD_IN_MAP,
  BULK_UPDATE_CUSTOM_FIELD_IN_ARR,
  UPDATE_SALESFORCE_OPPORTUNITY_STAGE_MAP,
  ADD_MAILBOX_SUCCESS,
  UPDATE_MAILBOX_SUCCESS,
  DELETE_MAILBOX_SUCCESS,
  UPDATE_ZOHO_USER_MAP,
  UPDATE_ZOHO_STAGE_MAP,
  UPDATE_ZOHO_OPPORTUNITY_STAGE_MAP,
  UPDATE_SLACK_USER_MAP,
} from '../action/type';

const initialState = {
  accountId: '',
  isLoading: NaN,
  error: '',
  prospectStages: [],
  callDispositions: [],
  callPurposes: [],
  prospectCustomFields: [],
  accountCustomFields: [],
  ruleSets: [],
  profiles: [],
  twilioNumbers: [],
  opportunityStages: [],
  opportunityCustomFields: [],
  triggers: [],
  schedules: [],
  mailboxes: [],
  mailboxOptions: [],
  upscaleHubspotUserMapping: {},
  upscaleHubspotStageMapping: {},
  upscalePipedriveUserMapping: {},
  upscalePipedriveOpportunityStageMapping: {},
  upscaleSalesforceUserMapping: {},
  upscaleSalesforceStageMapping: {},
  upscaleSalesforceOpportunityStageMapping: {},
  upscaleZohoUserMapping: {},
  upscaleZohoStageMapping: {},
  upscaleZohoOpportunityStageMapping: {},
  upscaleSlackUserMapping: {},
};
export const customFieldsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_CUSTOM_FIELDS:
      newState = {
        ...curState,
        error: null,
        isLoading: FETCH_CUSTOM_FIELDS,
        ...action.payload,
      };
      break;
    case FETCH_CUSTOM_FIELDS_SUCCESS: {
      newState = {
        ...curState,
        ...action.payload,
        isLoading: FETCH_CUSTOM_FIELDS_SUCCESS,
      };
      break;
    }
    case FETCH_CUSTOM_FIELDS_FAILURE:
      newState = {
        ...curState,
        isLoading: FETCH_CUSTOM_FIELDS_FAILURE,
        accountId: '',
        error: action.payload,
      };
      break;
    case ADD_NEW_STAGE_SUCCESS:
    case ADD_NEW_PROSPECT_CUSTOM_FIELD_SUCCESS:
    case ADD_NEW_ACCOUNT_CUSTOM_FIELD_SUCCESS:
    case ADD_NEW_RULESET_SUCCESS:
    case ADD_NEW_CALL_DISPOSITION_SUCCESS:
    case ADD_NEW_CALL_PURPOSE_SUCCESS:
    case ADD_NEW_PROFILE_SUCCESS:
    case ADD_NEW_PHONE_NUMBER_SUCCESS:
    case ADD_NEW_OPPORTUNITY_STAGE_SUCCESS:
    case ADD_NEW_OPPORTUNITY_CUSTOM_FIELD_SUCCESS:
    case ADD_NEW_TRIGGER_SUCCESS:
    case ADD_NEW_SCHEDULE_SUCCESS:
    case ADD_MAILBOX_SUCCESS: {
      newState = {
        ...curState,
        isLoading: action.type,
      };
      break;
    }
    case UPDATE_STAGE_SUCCESS: {
      const { stage } = action.payload;
      const { prospectStages } = curState;
      const index = prospectStages.findIndex((d) => d.id === stage.id);
      const newProspectStage = [...prospectStages];
      newProspectStage[index] = stage;
      newState = {
        ...curState,
        prospectStages: newProspectStage,
        isLoading: UPDATE_STAGE_SUCCESS,
      };
      break;
    }
    case UPDATE_MAILBOX_SUCCESS: {
      const { item } = action.payload;
      const { mailboxes } = curState;
      const index = mailboxes.findIndex((d) => d.id === item.id);
      const newList = [...mailboxes];
      newList[index] = { ...newList[index], ...item };
      newState = {
        ...curState,
        mailboxes: newList,
        isLoading: UPDATE_MAILBOX_SUCCESS,
      };
      break;
    }
    case DELETE_MAILBOX_SUCCESS: {
      const { item } = action.payload;
      const { mailboxes } = curState;
      const index = mailboxes.findIndex((d) => d.id === item.id);
      if (index !== -1) {
        mailboxes.splice(index, 1);
        newState = {
          ...curState,
          mailboxes,
          isLoading: DELETE_MAILBOX_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case DELETE_STAGE_SUCCESS: {
      const { stage } = action.payload;
      const { prospectStages } = curState;
      const index = prospectStages.findIndex((d) => d.id === stage.id);
      if (index !== -1) {
        prospectStages.splice(index, 1);
        newState = {
          ...curState,
          prospectStages,
          isLoading: DELETE_STAGE_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_PROSPECT_CUSTOM_FIELD_SUCCESS: {
      const { field } = action.payload;
      const { prospectCustomFields } = curState;
      const newProspectCustomFields = [...prospectCustomFields];
      const index = newProspectCustomFields.findIndex((d) => d.id === field.id);
      newProspectCustomFields[index] = field;
      newState = {
        ...curState,
        prospectCustomFields: newProspectCustomFields,
        isLoading: UPDATE_PROSPECT_CUSTOM_FIELD_SUCCESS,
      };
      break;
    }
    case DELETE_PROSPECT_CUSTOM_FIELD_SUCCESS: {
      const { field } = action.payload;
      const { prospectCustomFields } = curState;
      const index = prospectCustomFields.findIndex((d) => d.id === field.id);
      if (index !== -1) {
        const newProspectCustomFields = [...prospectCustomFields];
        newProspectCustomFields.splice(index, 1);
        newState = {
          ...curState,
          prospectCustomFields: newProspectCustomFields,
          isLoading: DELETE_PROSPECT_CUSTOM_FIELD_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_ACCOUNT_CUSTOM_FIELD_SUCCESS: {
      const { field } = action.payload;
      const { accountCustomFields } = curState;
      const newAccountCustomFields = [...accountCustomFields];
      const index = newAccountCustomFields.findIndex((d) => d.id === field.id);
      newAccountCustomFields[index] = field;
      newState = {
        ...curState,
        accountCustomFields: newAccountCustomFields,
        isLoading: UPDATE_ACCOUNT_CUSTOM_FIELD_SUCCESS,
      };
      break;
    }
    case DELETE_ACCOUNT_CUSTOM_FIELD_SUCCESS: {
      const { field } = action.payload;
      const { accountCustomFields } = curState;
      const index = accountCustomFields.findIndex((d) => d.id === field.id);
      if (index !== -1) {
        const newAccountCustomFields = [...accountCustomFields];
        newAccountCustomFields.splice(index, 1);
        newState = {
          ...curState,
          accountCustomFields: newAccountCustomFields,
          isLoading: DELETE_ACCOUNT_CUSTOM_FIELD_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_RULESET_SUCCESS: {
      const { ruleset } = action.payload;
      const { ruleSets = [] } = curState;
      const newRulesets = [...ruleSets];
      const index = newRulesets.findIndex((d) => d.id === ruleset.id);
      newRulesets[index] = ruleset;
      newState = {
        ...curState,
        ruleSets: newRulesets,
        isLoading: UPDATE_RULESET_SUCCESS,
      };
      break;
    }
    case DELETE_RULESET_SUCCESS: {
      const { ruleset } = action.payload;
      const { ruleSets = [] } = curState;
      const index = ruleSets.findIndex((d) => d.id === ruleset.id);
      if (index !== -1) {
        const newRulesets = [...ruleSets];
        newRulesets.splice(index, 1);
        newState = {
          ...curState,
          ruleSets: newRulesets,
          isLoading: DELETE_RULESET_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_CALL_DISPOSITION_SUCCESS: {
      const { disposition } = action.payload;
      const { callDispositions = [] } = curState;
      const items = [...callDispositions];
      const index = items.findIndex((d) => d.id === disposition.id);
      items[index] = disposition;
      newState = {
        ...curState,
        callDispositions: items,
        isLoading: UPDATE_CALL_DISPOSITION_SUCCESS,
      };
      break;
    }
    case DELETE_CALL_DISPOSITION_SUCCESS: {
      const { disposition } = action.payload;
      const { callDispositions = [] } = curState;
      const index = callDispositions.findIndex((d) => d.id === disposition.id);
      if (index !== -1) {
        const items = [...callDispositions];
        items.splice(index, 1);
        newState = {
          ...curState,
          callDispositions: items,
          isLoading: DELETE_CALL_DISPOSITION_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_CALL_PURPOSE_SUCCESS: {
      const { purpose } = action.payload;
      const { callPurposes = [] } = curState;
      const items = [...callPurposes];
      const index = items.findIndex((d) => d.id === purpose.id);
      items[index] = purpose;
      newState = {
        ...curState,
        callPurposes: items,
        isLoading: UPDATE_CALL_PURPOSE_SUCCESS,
      };
      break;
    }
    case DELETE_CALL_PURPOSE_SUCCESS: {
      const { purpose } = action.payload;
      const { callPurposes = [] } = curState;
      const index = callPurposes.findIndex((d) => d.id === purpose.id);
      if (index !== -1) {
        const items = [...callPurposes];
        items.splice(index, 1);
        newState = {
          ...curState,
          callPurposes: items,
          isLoading: DELETE_CALL_PURPOSE_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_PROFILE_SUCCESS: {
      const { profile } = action.payload;
      const { profiles = [] } = curState;
      const items = [...profiles];
      const index = items.findIndex((d) => d.id === profile.id);
      items[index] = profile;
      newState = {
        ...curState,
        profiles: items,
        isLoading: UPDATE_PROFILE_SUCCESS,
      };
      break;
    }
    case DELETE_PROFILE_SUCCESS: {
      const { profile } = action.payload;
      const { profiles = [] } = curState;
      const index = profiles.findIndex((d) => d.id === profile.id);
      if (index !== -1) {
        const items = [...profiles];
        items.splice(index, 1);
        newState = {
          ...curState,
          profiles: items,
          isLoading: DELETE_PROFILE_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_PHONE_NUMBER_SUCCESS: {
      const { twilioNumber } = action.payload;
      const { twilioNumbers = [] } = curState;
      const newTwilioNumbers = [...twilioNumbers];
      const index = newTwilioNumbers.findIndex((d) => d.id === twilioNumber.id);
      newTwilioNumbers[index] = twilioNumber;
      newState = {
        ...curState,
        twilioNumbers: newTwilioNumbers,
        isLoading: UPDATE_PHONE_NUMBER_SUCCESS,
      };
      break;
    }
    case DELETE_PHONE_NUMBER_SUCCESS: {
      const { twilioNumber } = action.payload;
      const { twilioNumbers = [] } = curState;
      const index = twilioNumbers.findIndex((d) => d.id === twilioNumber.id);
      if (index !== -1) {
        const newTwilioNumbers = [...twilioNumbers];
        newTwilioNumbers.splice(index, 1);
        newState = {
          ...curState,
          twilioNumbers: newTwilioNumbers,
          isLoading: DELETE_PHONE_NUMBER_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_HUBSPOT_USER_MAP: {
      const { upscaleHubspotUserMapping: newObject } = action.payload;
      const { upscaleHubspotUserMapping } = curState;
      newState = {
        ...curState,
        upscaleHubspotUserMapping: { ...upscaleHubspotUserMapping, ...newObject },
        isLoading: UPDATE_HUBSPOT_USER_MAP,
      };
      break;
    }
    case UPDATE_HUBSPOT_STAGE_MAP: {
      const { upscaleHubspotStageMapping: newObject } = action.payload;
      const { upscaleHubspotStageMapping } = curState;
      newState = {
        ...curState,
        upscaleHubspotStageMapping: { ...upscaleHubspotStageMapping, ...newObject },
        isLoading: UPDATE_HUBSPOT_STAGE_MAP,
      };
      break;
    }
    case UPDATE_PIPEDRIVE_USER_MAP: {
      const { upscalePipedriveUserMapping: newObject } = action.payload;
      const { upscalePipedriveUserMapping } = curState;
      newState = {
        ...curState,
        upscalePipedriveUserMapping: { ...upscalePipedriveUserMapping, ...newObject },
        isLoading: UPDATE_HUBSPOT_USER_MAP,
      };
      break;
    }
    case UPDATE_OPPORTUNITY_STAGE_SUCCESS: {
      const { stage } = action.payload;
      const { opportunityStages } = curState;
      const index = opportunityStages.findIndex((d) => d.id === stage.id);
      const newOpportunityStage = [...opportunityStages];
      newOpportunityStage[index] = stage;
      newState = {
        ...curState,
        opportunityStages: newOpportunityStage,
        isLoading: UPDATE_OPPORTUNITY_STAGE_SUCCESS,
      };
      break;
    }
    case DELETE_OPPORTUNITY_STAGE_SUCCESS: {
      const { stage } = action.payload;
      const { opportunityStages } = curState;
      const index = opportunityStages.findIndex((d) => d.id === stage.id);
      if (index !== -1) {
        const newStages = [...opportunityStages];
        newStages.splice(index, 1);
        newState = {
          ...curState,
          opportunityStages: newStages,
          isLoading: DELETE_OPPORTUNITY_STAGE_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS: {
      const { field } = action.payload;
      const { opportunityCustomFields } = curState;
      const newCustomFields = [...opportunityCustomFields];
      const index = newCustomFields.findIndex((d) => d.id === field.id);
      newCustomFields[index] = field;
      newState = {
        ...curState,
        opportunityCustomFields: newCustomFields,
        isLoading: UPDATE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS,
      };
      break;
    }
    case DELETE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS: {
      const { field } = action.payload;
      const { opportunityCustomFields } = curState;
      const index = opportunityCustomFields.findIndex((d) => d.id === field.id);
      if (index !== -1) {
        const newCustomFields = [...opportunityCustomFields];
        newCustomFields.splice(index, 1);
        newState = {
          ...curState,
          opportunityCustomFields: newCustomFields,
          isLoading: DELETE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case SET_UPSCALE_PIPEDRIVE_OPPORTUNITY_STGAE_MAPPING: {
      const data = action.payload;
      newState = {
        ...curState,
        upscalePipedriveOpportunityStageMapping: data,
        isLoading: SET_UPSCALE_PIPEDRIVE_OPPORTUNITY_STGAE_MAPPING,
      };
      break;
    }
    case UPDATE_TRIGGER_SUCCESS: {
      const { trigger } = action.payload;
      const { triggers = [] } = curState;
      const newTriggers = [...triggers];
      const index = triggers.findIndex((d) => d.id === trigger.id);
      newTriggers[index] = trigger;
      newState = {
        ...curState,
        triggers: newTriggers,
        isLoading: UPDATE_TRIGGER_SUCCESS,
      };
      break;
    }
    case DELETE_TRIGGER_SUCCESS: {
      const { trigger } = action.payload;
      const { triggers = [] } = curState;
      const index = triggers.findIndex((d) => d.id === trigger.id);
      if (index !== -1) {
        const newTriggers = [...triggers];
        newTriggers.splice(index, 1);
        newState = {
          ...curState,
          triggers: newTriggers,
          isLoading: DELETE_TRIGGER_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_SCHEDULE_SUCCESS: {
      const { schedule } = action.payload;
      const { schedules = [] } = curState;
      const newSchedules = [...schedules];
      const index = schedules.findIndex((d) => d.id === schedule.id);
      newSchedules[index] = schedule;
      newState = {
        ...curState,
        schedules: newSchedules,
        isLoading: UPDATE_SCHEDULE_SUCCESS,
      };
      break;
    }
    case DELETE_SCHEDULE_SUCCESS: {
      const { schedule } = action.payload;
      const { schedules = [] } = curState;
      const index = schedules.findIndex((d) => d.id === schedule.id);
      if (index !== -1) {
        const newSchedules = [...schedules];
        newSchedules.splice(index, 1);
        newState = {
          ...curState,
          schedules: newSchedules,
          isLoading: DELETE_SCHEDULE_SUCCESS,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case UPDATE_ZOHO_USER_MAP: {
      const { upscaleZohoUserMapping: newObject } = action.payload;
      const { upscaleZohoUserMapping } = curState;
      newState = {
        ...curState,
        upscaleZohoUserMapping: { ...upscaleZohoUserMapping, ...newObject },
        isLoading: UPDATE_ZOHO_USER_MAP,
      };
      break;
    }
    case UPDATE_ZOHO_STAGE_MAP: {
      const { upscaleZohoStageMapping: newObject } = action.payload;
      const { upscaleZohoStageMapping } = curState;
      newState = {
        ...curState,
        upscaleZohoStageMapping: { ...upscaleZohoStageMapping, ...newObject },
        isLoading: UPDATE_ZOHO_STAGE_MAP,
      };
      break;
    }
    case UPDATE_SLACK_USER_MAP: {
      const { upscaleSlackUserMapping: newObject } = action.payload;
      const { upscaleSlackUserMapping } = curState;
      newState = {
        ...curState,
        upscaleSlackUserMapping: { ...upscaleSlackUserMapping, ...newObject },
        isLoading: UPDATE_SLACK_USER_MAP,
      };
      break;
    }
    case UPDATE_SALESFORCE_USER_MAP: {
      const { upscaleSalesforceUserMapping: newObject } = action.payload;
      const { upscaleSalesforceUserMapping } = curState;
      newState = {
        ...curState,
        upscaleSalesforceUserMapping: { ...upscaleSalesforceUserMapping, ...newObject },
        isLoading: UPDATE_SALESFORCE_USER_MAP,
      };
      break;
    }
    case UPDATE_SALESFORCE_STAGE_MAP: {
      const { upscaleSalesforceStageMapping: newObject } = action.payload;
      const { upscaleSalesforceStageMapping } = curState;
      newState = {
        ...curState,
        upscaleSalesforceStageMapping: { ...upscaleSalesforceStageMapping, ...newObject },
        isLoading: UPDATE_SALESFORCE_STAGE_MAP,
      };
      break;
    }
    case UPDATE_SALESFORCE_OPPORTUNITY_STAGE_MAP: {
      const { upscaleSalesforceOpportunityStageMapping: newObject } = action.payload;
      const { upscaleSalesforceOpportunityStageMapping } = curState;
      newState = {
        ...curState,
        upscaleSalesforceOpportunityStageMapping: { ...upscaleSalesforceOpportunityStageMapping, ...newObject },
        isLoading: UPDATE_SALESFORCE_STAGE_MAP,
      };
      break;
    }
    case UPDATE_ZOHO_OPPORTUNITY_STAGE_MAP: {
      const { upscaleZohoOpportunityStageMapping: newObject } = action.payload;
      const { upscaleZohoOpportunityStageMapping } = curState;
      newState = {
        ...curState,
        upscaleZohoOpportunityStageMapping: { ...upscaleZohoOpportunityStageMapping, ...newObject },
        isLoading: UPDATE_ZOHO_OPPORTUNITY_STAGE_MAP,
      };
      break;
    }
    case ADD_CUSTOM_FIELD_IN_ARR: {
      const { type, id } = action.payload;
      if (CUSTOM_FIELD_TYPE_MAP[type]) {
        const oldData = curState[CUSTOM_FIELD_TYPE_MAP[type]] || [];
        const newData = [...oldData];
        const index = newData.findIndex((d) => d.id === id);
        if (index === -1) {
          newData.push(action.payload);
          newState = {
            ...curState,
            [CUSTOM_FIELD_TYPE_MAP[type]]: newData,
          };
        } else {
          newState = curState;
        }
      } else newState = curState;
      break;
    }
    case UPDATE_CUSTOM_FIELD_IN_ARR: {
      const { type, id } = action.payload;
      if (CUSTOM_FIELD_TYPE_MAP[type]) {
        const oldData = curState[CUSTOM_FIELD_TYPE_MAP[type]] || [];
        const newData = [...oldData];
        const index = newData.findIndex((d) => d.id === id);
        if (index > -1) {
          newData[index] = { ...newData[index], ...action.payload };
        }
        newState = {
          ...curState,
          [CUSTOM_FIELD_TYPE_MAP[type]]: newData,
        };
      } else newState = { ...curState };
      break;
    }
    case DELETE_CUSTOM_FIELD_IN_ARR: {
      const { type, id } = action.payload;
      if (CUSTOM_FIELD_TYPE_MAP[type]) {
        const oldData = curState[CUSTOM_FIELD_TYPE_MAP[type]] || [];
        const index = oldData.findIndex((d) => d.id === id);
        if (index !== -1) {
          const newData = [...oldData];
          newData.splice(index, 1);
          newState = {
            ...curState,
            [CUSTOM_FIELD_TYPE_MAP[type]]: newData,
          };
        } else {
          newState = curState;
        }
      } else newState = curState;
      break;
    }
    case BULK_UPDATE_CUSTOM_FIELD_IN_ARR: {
      const ids = Object.keys(action.payload);
      newState = { ...curState };
      ids.forEach((id) => {
        const { type, ...data } = action.payload[id];
        if (CUSTOM_FIELD_TYPE_MAP[type]) {
          const oldData = newState[CUSTOM_FIELD_TYPE_MAP[type]] || [];
          const newData = [...oldData];
          const index = newData.findIndex((d) => d.id === id);
          if (index > -1) {
            newData[index] = { ...newData[index], ...data };
          }
          newState = {
            ...newState,
            [CUSTOM_FIELD_TYPE_MAP[type]]: newData,
          };
        }
      });
      break;
    }
    case UPDATE_CUSTOM_FIELD_IN_MAP: {
      const { type } = action.payload;
      if (CUSTOM_FIELD_TYPE_MAP[type]) {
        const oldData = curState[CUSTOM_FIELD_TYPE_MAP[type]] || {};
        newState = {
          ...curState,
          [CUSTOM_FIELD_TYPE_MAP[type]]: { ...oldData, ...action.payload },
        };
      } else newState = { ...curState };
      break;
    }
    case DELETE_CUSTOM_FIELD_IN_MAP: {
      const fieldsToReset = action.payload;
      newState = { ...curState };
      fieldsToReset.forEach((type) => {
        if (CUSTOM_FIELD_TYPE_MAP[type]) {
          newState = {
            ...curState,
            [CUSTOM_FIELD_TYPE_MAP[type]]: {},
          };
        }
      });
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
