import {
  FETCH_OWNED_NUMBERS_SETTINGS_FAILURE, FETCH_TWILIO_RECORDINGS_SUCCESS,
} from '../action/type';

const initialState = {
  accountId: '', twilioNumbers: [], isLoading: NaN, error: '', recordings: null,
};
export const ownedNumbersReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_OWNED_NUMBERS_SETTINGS_FAILURE:
      newState = {
        ...curState, isLoading: FETCH_OWNED_NUMBERS_SETTINGS_FAILURE, accountId: '', error: action.payload,
      };
      break;
    case FETCH_TWILIO_RECORDINGS_SUCCESS:
      newState = {
        ...curState, isLoading: FETCH_TWILIO_RECORDINGS_SUCCESS, ...action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};
