export const FETCH_CLIENT_ACCOUNT_V2 = 'FETCH_CLIENT_ACCOUNT_V2';
export const FETCH_CLIENT_ACCOUNT_SUCCESS_V2 = 'FETCH_CLIENT_ACCOUNT_SUCCESS_V2';
export const FETCH_CLIENT_ACCOUNT_FAILURE_V2 = 'FETCH_CLIENT_ACCOUNT_FAILURE_V2';

export const SET_CLIENT_ACCOUNT_PAGE_SIZE = 'SET_CLIENT_ACCOUNT_PAGE_SIZE';
export const SET_CLIENT_ACCOUNT_PAGE_INDEX = 'SET_CLIENT_ACCOUNT_PAGE_INDEX';
export const RESET_CLIENT_ACCOUNT_ITEMS = 'RESET_CLIENT_ACCOUNT_ITEMS';
export const RELOAD_ACCOUNTS = 'RELOAD_ACCOUNTS';

export const FETCH_CLIENT_ACCOUNT_DETAIL = 'FETCH_CLIENT_ACCOUNT_DETAIL';
export const FETCH_CLIENT_ACCOUNT_DETAIL_SUCCESS = 'FETCH_CLIENT_ACCOUNT_DETAIL_SUCCESS';
export const FETCH_CLIENT_ACCOUNT_DETAIL_FAILURE = 'FETCH_CLIENT_ACCOUNT_DETAIL_FAILURE';
export const RESET_CLIENT_ACCOUNT_DETAIL_DATA = 'RESET_CLIENT_ACCOUNT_DETAIL_DATA';
export const UPDATE_CLIENT_ACCOUNT_DETAIL = 'UPDATE_CLIENT_ACCOUNT_DETAIL';
