export const updateSingleView = (curState, action) => {
  const { views } = curState;
  const viewsCopy = [...views];
  if (viewsCopy) {
    const { id } = action.payload;
    const oldIndex = viewsCopy.findIndex((d) => d.id === id);
    if (oldIndex > -1) {
      viewsCopy[oldIndex] = {
        ...viewsCopy[oldIndex],
        ...action.payload,
      };
    } else {
      viewsCopy.push(action.payload);
    }
  }
  return {
    ...curState,
    views: viewsCopy,
  };
};

export const addSingleView = (curState, action) => {
  const { views } = curState;
  const viewsCopy = [...views];
  if (action.payload?.id) {
    viewsCopy.push(action.payload);
  }
  return {
    ...curState,
    views: viewsCopy,
  };
};

export const deleteSingleView = (curState, action) => {
  const { views } = curState;
  const viewsCopy = [...views];
  if (viewsCopy) {
    const oldIndex = viewsCopy.findIndex((d) => d.id === action.payload.id);
    if (oldIndex > -1) {
      viewsCopy.splice(oldIndex, 1);
    }
  }
  return {
    ...curState,
    views: viewsCopy,
  };
};
