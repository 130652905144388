import {
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { SETTING_ITEM, UPSCALE_ROUTES, UPSCALE_UNAUTHORIZE_ROUTES } from '../constants';

const combinedRoutes = [...UPSCALE_UNAUTHORIZE_ROUTES, ...UPSCALE_ROUTES];
const getActiveDetails = (pathname) => (pathname.includes('settings') ? SETTING_ITEM : combinedRoutes.find((item) => ((pathname === item.route))));

export const useDocumentTitleChange = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    const details = getActiveDetails(pathname);
    details && (document.title = `Upscale | ${details.name}`);
  }, [pathname]);
};

