import {
  CALL_TILE_DATA, CALL_TILE_DATA_FAILURE, CALL_TILE_DATA_SUCCESS, RESET_CALL_TILE_DATA,
} from '../action/Call';

const initialState = {
  isLoading: NaN,
  data: { All: 0 },
  error: '',
};
export const callTilesReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case CALL_TILE_DATA: {
      newState = {
        ...curState,
        isLoading: true,
      };
      break;
    }
    case CALL_TILE_DATA_SUCCESS: {
      const newDataObj = { All: 0 };
      let totalCount = 0;
      action.payload.resultArr.forEach((data) => {
        newDataObj[data._id] = data.count;
        totalCount += Number(data.count);
      });
      newDataObj.All = totalCount;
      newState = {
        ...curState,
        data: newDataObj,
        isLoading: false,
      };
      break;
    }
    case CALL_TILE_DATA_FAILURE: {
      newState = {
        ...curState,
        error: action.payload,
        isLoading: false,
      };
      break;
    }
    case RESET_CALL_TILE_DATA: {
      newState = { ...curState, isLoading: NaN, error: '' };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

