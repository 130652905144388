import { API, Storage } from 'aws-amplify';
import { API_NAME } from '../constants';
import { onError } from '../libs/errorLib';
import { parseResponse } from '../libs/response.lib';
import { generateQueryStringFromObject } from '../Utils/query.v2.util';

export const generateInsightReport = async ({
  accountId, timeZone, companyEmail, createdAt,
}) => API.get(API_NAME, `/reports?${generateQueryStringFromObject({
  accountId, timeZone, companyEmail, createdAt,
})}`);

export const s3InsightsDownload = async ({
  accountId, timeZone, setToggleInsightLoading, lastReportModifiedOn, companyEmail, createdAt,
}) => {
  const fallback = () => {
    setToggleInsightLoading(true);
    generateInsightReport({
      accountId, timeZone, companyEmail, createdAt,
    });
    return null;
  };
  if (!lastReportModifiedOn || (lastReportModifiedOn + (24 * 60 * 60 * 1000)) < Date.now()) {
    return fallback();
  }
  // TODO: Enable this when you run background scheduler
  // if (!lastReportModifiedOn) {
  //   return fallback();
  // }
  try {
    const resp = await Storage.get(`insights/${accountId}-${timeZone}.json`, { download: true, contentType: 'Application/json', cacheControl: 'no-cache' });
    return resp && JSON.parse(await resp.Body.text());
  } catch (error) {
    onError(error.message);
    return fallback();
  }
};

export const fetchReportDrillDown = async ({ type, ids, accountId }) => API.post(API_NAME, '/reports', { body: { type, ids, accountId } });

export const fetchEntityDetails = async ({
  type, ids, accountId, category,
}) => API.post(API_NAME, '/reports', {
  body: {
    type, ids, accountId, category,
  },
});

export const requestForDrilldownReport = async ({
  start, end, userId, reportUrl, accountId, type,
}) => API.post(API_NAME, '/reports?operation=getReport', {
  body: {
    start, end, userId, reportUrl, accountId, type,
  },
});

export const batchGetImportProspectHistory = async ({ LastEvaluatedKey, accountId }, query = { operation: 'fetchImportProspectHistory' }) => API.post(API_NAME,
  `/reports?${generateQueryStringFromObject(query)}`, { body: { LastEvaluatedKey, accountId } })
  .then(parseResponse);

