import API from '../libs/amplify.lib';
import { API_NAME } from '../constants';
import { generateQueryStringFromObject } from '../Utils/query.v2.util';
import { parseResponse } from '../libs/response.lib';

// TODO: Modify this when we migrate workflow

export const addWorkflow = async (payload, query = {}) => API.post(API_NAME, `/workflow?${generateQueryStringFromObject(query)}`, { body: payload })
  .then(parseResponse);

export const batchGetWorkflows = async (payload, query = { operation: 'batchGet' }) => API.post(API_NAME,
  `/workflow?${generateQueryStringFromObject(query)}`, { body: payload })
  .then(parseResponse);

export const deleteWorkflow = async (query, payload = {}) => API
  .del(API_NAME, `/workflow?${generateQueryStringFromObject(query)}`, { body: payload })
  .then(parseResponse);
export const updateWorkflow = async (payload, query = {}) => API
  .put(API_NAME, `/workflow?${generateQueryStringFromObject(query)}`, { body: payload })
  .then(parseResponse);

export const bulkDeleteWorkflow = async (payload) => API
  .post(API_NAME, '/workflow?type=multiple&method=delete', { body: payload })
  .then(parseResponse);

export const getWorkflowInsight = async (query) => API.get(API_NAME, `/workflow?${generateQueryStringFromObject(query)}`)
  .then(parseResponse);

/**
 * Function - Service function to update prospect workflow status
 * @param {object} - payload eg. {
 *  operation, prospectEmail, accountId, workflowAllocationStamp
 * }
 */
export const updateWorkflowProspectStatus = async (payload) => API.put(API_NAME, '/assignWorkflow', { body: payload })
  .then(parseResponse);

export const getWorkflowStepInsight = async ({ accountId, workflowId, queryType = 'workflowOverviewStepInsight' }) => API.get(API_NAME, `/workflow?${generateQueryStringFromObject({ accountId, workflowId, queryType })}`)
  .then(parseResponse);

export const getSentEmailsCountForTheDay = async ({ executionTime, timeZone, mailboxId }) => API.post(API_NAME, '/task?operation=getSentEmailsCountForTheDay', { body: { executionTime, timeZone, mailboxId } })
  .then(parseResponse);

export const getScheduledEmailsTimeStamps = async ({ mailboxId }) => API.post(API_NAME, '/task?operation=getScheduledEmailsTimeStamps', { body: { mailboxId } })
  .then(parseResponse);

export const getWorkflowStatisticV2 = async ({ accountId, startTime, endTime, workflowIds }) => API.post(API_NAME, '/workflow?operation=wfStatsV2', { body: { accountId, startTime, endTime, workflowIds } })
  .then(parseResponse);

export const updateSeqMailbox = async ({ accountId, id, mailboxId, aliasId, forceEdit, oldData = {} }) => API.put(API_NAME, '/workflow?operation=updateMailbox', { body: { data: { mailboxId, aliasId }, oldData, accountId, id, forceEdit } })
  .then(parseResponse);
