export const USER_LOGGED_OUT = 0;

export const USER = 1;
export const USER_SUCCESS = 2;
export const USER_FAILURE = 3;

export const USER_SETTINGS = 4;
export const USER_SETTINGS_SUCCESS = 5;
export const USER_SETTINGS_FAILURE = 6;
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_ACCESS_DETAILS = 'USER_ACCESS_DETAILS';

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';
export const UPDATE_USER_SETTING_BASIC_SETTING = 'UPDATE_USER_SETTING_BASIC_SETTING';
export const UPDATE_USER_SETTING_TABLE_CONFIG = 'UPDATE_USER_SETTING_TABLE_CONFIG';

export const ACCOUNT_SETTINGS = 7;
export const ACCOUNT_SETTINGS_SUCCESS = 8;
export const ACCOUNT_SETTINGS_FAILURE = 9;

export const GENERIC_INFO_SETTINGS = 10;
export const GENERIC_INFO_SETTINGS_SUCCESS = 11;
export const GENERIC_INFO_SETTINGS_FAILURE = 12;

export const ALL_USERS = 16;
export const ALL_USERS_SUCCESS = 17;
export const ALL_USERS_FAILURE = 18;

export const FETCH_TASKS = 22;
export const FETCH_TASKS_SUCCESS = 23;
export const FETCH_TASKS_FAILURE = 24;
export const SET_SELECTED_TASKS = 25;
export const SET_SELECTED_TASK_ID = 26;

export const FETCH_BULK_TEMPLATES = 'FETCH_BULK_TEMPLATES';
export const BULK_UPDATE_TEMPLATE = 'BULK_UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';

export const FETCH_ACTIVITY = 30;
export const FETCH_ACTIVITY_SUCCESS = 31;
export const FETCH_ACTIVITY_FAILURE = 32;
export const FETCH_NEXT_ACTIVITY = 'FETCH_NEXT_ACTIVITY';
export const RESET_ACTIVITY = 'RESET_ACTIVITY';

export const HIDE_DEMO = 33;

export const FETCH_CLIENT_ACCOUNT = 34;
export const FETCH_CLIENT_ACCOUNT_SUCCESS = 35;
export const FETCH_CLIENT_ACCOUNT_FAILURE = 36;
export const FETCH_NEXT_CLIENT_ACCOUNT_SUCCESS = 'FETCH_NEXT_CLIENT_ACCOUNT_SUCCESS';

export const FETCH_UPSCALE_SETTINGS = 37;
export const FETCH_UPSCALE_SETTINGS_SUCCESS = 38;
export const FETCH_UPSCALE_SETTINGS_FAILURE = 39;

export const FETCH_INTEGRATION_SETTINGS = 40;
export const FETCH_INTEGRATION_SETTINGS_SUCCESS = 41;
export const FETCH_INTEGRATION_SETTINGS_FAILURE = 42;

// 43, 44
export const FETCH_OWNED_NUMBERS_SETTINGS_FAILURE = 45;
export const FETCH_TWILIO_RECORDINGS_SUCCESS = 46;

export const FETCH_PROSPECT_VIEWS = 48;
export const FETCH_PROSPECT_VIEWS_SUCCESS = 49;
export const UPDATE_SINGLE_VIEW_PROSPECT = 'UPDATE_SINGLE_VIEW_PROSPECT';
export const ADD_SINGLE_VIEW_PROSPECT = 'ADD_SINGLE_VIEW_PROSPECT';
export const DELETE_SINGLE_VIEW_PROSPECT = 'DELETE_SINGLE_VIEW_PROSPECT';
export const SET_PROSPECT_TILE = 'SET_PROSPECT_TILE';
export const FETCH_PROSPECT_VIEWS_FAILURE = 50;
export const SET_PROSPECT_SORTING_FIELD = 51;
export const SET_PROSPECT_SORTING_DIRECTION = 52;
export const SET_PROSPECT_SELECTED_VIEW = 53;
export const SET_PROSPECT_SEARCH = 'SET_PROSPECT_SEARCH';

export const FETCH_TASK_VIEWS = 54;
export const FETCH_TASK_VIEWS_SUCCESS = 55;
export const UPDATE_SINGLE_VIEW_TASK = 'UPDATE_SINGLE_VIEW_TASK';
export const ADD_SINGLE_VIEW_TASK = 'ADD_SINGLE_VIEW_TASK';
export const DELETE_SINGLE_VIEW_TASK = 'DELETE_SINGLE_VIEW_TASK';
export const FETCH_TASK_VIEWS_FAILURE = 56;
export const SET_TASK_SORTING_FIELD = 57;
export const SET_TASK_SORTING_DIRECTION = 58;
export const SET_TASK_SELECTED_VIEW = 59;
export const SET_TASK_SEARCH = 'SET_TASK_SEARCH';
export const SET_TASK_TILE = 'SET_TASK_TILE';
export const UPDATE_TASK_DATE_RANGE = 'UPDATE_TASK_DATE_RANGE';
export const SET_TASK_GROUP_BY = 'SET_TASK_GROUP_BY';

export const FETCH_WORKFLOW_VIEWS = 60;
export const FETCH_WORKFLOW_VIEWS_SUCCESS = 61;
export const UPDATE_SINGLE_VIEW_WORKFLOW = 'UPDATE_SINGLE_VIEW_WORKFLOW';
export const ADD_SINGLE_VIEW_WORKFLOW = 'ADD_SINGLE_VIEW_WORKFLOW';
export const DELETE_SINGLE_VIEW_WORKFLOW = 'DELETE_SINGLE_VIEW_WORKFLOW';
export const FETCH_WORKFLOW_VIEWS_FAILURE = 62;
export const SET_WORKFLOW_SORTING_FIELD = 63;
export const SET_WORKFLOW_SORTING_DIRECTION = 64;
export const SET_WORKFLOW_SELECTED_VIEW = 65;
export const SET_WORKFLOW_REPORT_SELECTED_VIEW = 'SET_WORKFLOW_REPORT_SELECTED_VIEW';
export const SET_WORKFLOW_REPORT_DATE_RANGE = 'SET_WORKFLOW_REPORT_DATE_RANGE';
export const SET_WORKFLOW_REPORT_DATA = 'SET_WORKFLOW_REPORT_DATA';
export const SET_WORKFLOW_REPORT_DATA_SUCCESS = 'SET_WORKFLOW_REPORT_DATA_SUCCESS';
export const SET_WORKFLOW_REPORT_DATA_FAILURE = 'SET_WORKFLOW_REPORT_DATA_FAILURE';

export const FETCH_ACCOUNT_VIEWS = 66;
export const FETCH_ACCOUNT_VIEWS_SUCCESS = 67;
export const UPDATE_SINGLE_VIEW_CLIENT_ACCOUNT = 'UPDATE_SINGLE_VIEW_CLIENT_ACCOUNT';
export const ADD_SINGLE_VIEW_CLIENT_ACCOUNT = 'ADD_SINGLE_VIEW_CLIENT_ACCOUNT';
export const DELETE_SINGLE_VIEW_CLIENT_ACCOUNT = 'DELETE_SINGLE_VIEW_CLIENT_ACCOUNT';
export const FETCH_ACCOUNT_VIEWS_FAILURE = 68;
export const SET_ACCOUNT_SORTING_FIELD = 69;
export const SET_ACCOUNT_SORTING_DIRECTION = 70;
export const SET_ACCOUNT_SELECTED_VIEW = 71;
export const SET_ACCOUNT_SEARCH = 'SET_ACCOUNT_SEARCH';

export const FETCH_TEMPLATE_VIEWS = 72;
export const FETCH_TEMPLATE_VIEWS_SUCCESS = 73;
export const UPDATE_SINGLE_VIEW_TEMPLATE = 'UPDATE_SINGLE_VIEW_TEMPLATE';
export const ADD_SINGLE_VIEW_TEMPLATE = 'ADD_SINGLE_VIEW_TEMPLATE';
export const DELETE_SINGLE_VIEW_TEMPLATE = 'DELETE_SINGLE_VIEW_TEMPLATE';
export const FETCH_TEMPLATE_VIEWS_FAILURE = 74;
export const SET_TEMPLATE_SORTING_FIELD = 75;
export const SET_TEMPLATE_SORTING_DIRECTION = 76;
export const SET_TEMPLATE_SELECTED_VIEW = 77;

export const FETCH_CUSTOM_FIELDS = 81;
export const FETCH_CUSTOM_FIELDS_SUCCESS = 82;
export const FETCH_CUSTOM_FIELDS_FAILURE = 83;

export const FETCH_NEXT_PROSPECT_SUCCESS = 'FETCH_NEXT_PROSPECT_SUCCESS';
export const RESET_PROSPECTS = 'RESET_PROSPECTS';
export const BULK_UPDATE_PROSPECT = 'BULK_UPDATE_PROSPECT';
export const BULK_UPDATE_PROSPECT_CUSTOM_FIELD = 'BULK_UPDATE_PROSPECT_CUSTOM_FIELD';
export const BULK_UPDATE_PROSPECT_TAGS = 'BULK_UPDATE_PROSPECT_TAGS';
export const ADD_SINGLE_PROSPECT = 'ADD_SINGLE_PROSPECT';
export const ADD_PROSPECT_NOTE = 'ADD_PROSPECT_NOTE';
export const UPDATE_SINGLE_PROSPECT = 'UPDATE_SINGLE_PROSPECT';
export const BULK_UPDATE_PROSPECT_MULTIPLE_FIELD = 'BULK_UPDATE_PROSPECT_MULTIPLE_FIELD';
export const RESET_TASKS = 'RESET_TASKS';
export const RESET_CLIENT_ACCOUNTS = 'RESET_CLIENT_ACCOUNTS';
export const ADD_NEW_STAGE_SUCCESS = 84;
export const UPDATE_STAGE_SUCCESS = 85;
export const DELETE_STAGE_SUCCESS = 86;
export const PROSPECT_UPDATE_IN_PROGRESS = 'PROSPECT_UPDATE_IN_PROGRESS';
export const PROSPECT_UPDATE_CACHE = 'PROSPECT_UPDATE_CACHE';

export const ADD_NEW_PROSPECT_CUSTOM_FIELD_SUCCESS = 87;
export const UPDATE_PROSPECT_CUSTOM_FIELD_SUCCESS = 88;
export const DELETE_PROSPECT_CUSTOM_FIELD_SUCCESS = 89;

export const ADD_NEW_ACCOUNT_CUSTOM_FIELD_SUCCESS = 90;
export const UPDATE_ACCOUNT_CUSTOM_FIELD_SUCCESS = 91;
export const DELETE_ACCOUNT_CUSTOM_FIELD_SUCCESS = 92;

export const ADD_NEW_RULESET_SUCCESS = 93;
export const UPDATE_RULESET_SUCCESS = 94;
export const DELETE_RULESET_SUCCESS = 95;

export const ADD_NEW_CALL_DISPOSITION_SUCCESS = 96;
export const UPDATE_CALL_DISPOSITION_SUCCESS = 97;
export const DELETE_CALL_DISPOSITION_SUCCESS = 98;

export const ADD_NEW_CALL_PURPOSE_SUCCESS = 99;
export const UPDATE_CALL_PURPOSE_SUCCESS = 100;
export const DELETE_CALL_PURPOSE_SUCCESS = 101;

export const ADD_MAILBOX_SUCCESS = 'ADD_MAILBOX_SUCCESS';
export const UPDATE_MAILBOX_SUCCESS = 'UPDATE_MAILBOX_SUCCESS';
export const DELETE_MAILBOX_SUCCESS = 'DELETE_MAILBOX_SUCCESS';

export const SET_OFFLINE = 102;
export const SOCKET_EVENT = 103;
export const SOCKET_EVENT_SUCCESS = 104;
export const SOCKET_EVENT_FAILURE = 105;

export const ADD_NEW_PROFILE_SUCCESS = 106;
export const UPDATE_PROFILE_SUCCESS = 107;
export const DELETE_PROFILE_SUCCESS = 108;

export const FETCH_BULK_PROSPECT = 109;
export const REMOVE_PROSPECT = 110;

export const SPINNER_COUNTER = 'SPINNER_COUNTER';

export const FETCH_BULK_USER = 119;
export const BULK_UPDATE_USER_EMAIL_THROTTLE_METAINFO = 'BULK_UPDATE_USER_EMAIL_THROTTLE_METAINFO';
export const BULK_UPDATE_USER = 'BULK_UPDATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const REMOVE_USER = 120;

export const BULK_UPDATE_CLIENT_ACCOUNT = 'BULK_UPDATE_CLIENT_ACCOUNT';
export const BULK_UPDATE_CLIENT_ACCOUNT_TAGS = 'BULK_UPDATE_CLIENT_ACCOUNT_TAGS';
export const BULK_UPDATE_CLIENT_ACCOUNT_CUSTOM_FIELD = 'BULK_UPDATE_CLIENT_ACCOUNT_CUSTOM_FIELD';
export const ADD_SINGLE_CLIENT_ACCOUNT = 'ADD_SINGLE_CLIENT_ACCOUNT';
export const ADD_CLIENT_ACCOUNT_NOTE = 'ADD_CLIENT_ACCOUNT_NOTE';
export const BULK_UPDATE_CLIENT_ACCOUNT_MULTIPLE_FIELD = 'BULK_UPDATE_CLIENT_ACCOUNT_MULTIPLE_FIELD';
export const REMOVE_CLIENT_ACCOUNT = 122;

export const FETCH_ACTIVITIES_BY_ID = 123;
export const REMOVE_ACTIVITY = 124;

export const FETCH_FILTERS_BY_ID = 125;

export const SET_CALL_SELECTED_VIEW = 126;
export const FETCH_CALL_VIEWS = 127;
export const FETCH_CALL_VIEWS_SUCCESS = 128;
export const UPDATE_SINGLE_VIEW_CALLS = 'UPDATE_SINGLE_VIEW_CALLS';
export const ADD_SINGLE_VIEW_CALLS = 'ADD_SINGLE_VIEW_CALLS';
export const DELETE_SINGLE_VIEW_CALLS = 'DELETE_SINGLE_VIEW_CALLs';
export const FETCH_CALL_VIEWS_FAILURE = 129;
export const SET_CALL_SORTING_FIELD = 130;
export const SET_CALL_SORTING_DIRECTION = 131;
export const SET_CALL_SEARCH = 'SET_CALL_SEARCH';
export const SET_CALL_TILE = 'SET_CALL_TILE';
export const UPDATE_CALL_DATE_RANGE = 'UPDATE_CALL_DATE_RANGE';

export const ADD_NEW_PHONE_NUMBER_SUCCESS = 132;
export const UPDATE_PHONE_NUMBER_SUCCESS = 133;
export const DELETE_PHONE_NUMBER_SUCCESS = 134;

export const UPDATE_HUBSPOT_USER_MAP = 'UPDATE_HUBSPOT_USER_MAP';
export const UPDATE_HUBSPOT_STAGE_MAP = 'UPDATE_HUBSPOT_STAGE_MAP';

export const UPDATE_ZOHO_USER_MAP = 'UPDATE_ZOHO_USER_MAP';
export const UPDATE_ZOHO_STAGE_MAP = 'UPDATE_ZOHO_STAGE_MAP';
export const UPDATE_ZOHO_OPPORTUNITY_STAGE_MAP = 'UPDATE_ZOHO_OPPORTUNITY_STAGE_MAP';

export const FETCH_COUNTRY_DATA_FAILURE = 135;
export const FETCH_COUNTRY_DATA_SUCCESS = 136;

export const UPDATE_PIPEDRIVE_USER_MAP = 'UPDATE_PIPEDRIVE_USER_MAP';

export const ADD_NEW_OPPORTUNITY_STAGE_SUCCESS = 137;
export const UPDATE_OPPORTUNITY_STAGE_SUCCESS = 138;
export const DELETE_OPPORTUNITY_STAGE_SUCCESS = 139;

export const ADD_NEW_OPPORTUNITY_CUSTOM_FIELD_SUCCESS = 140;
export const UPDATE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS = 141;
export const DELETE_OPPORTUNITY_CUSTOM_FIELD_SUCCESS = 142;

export const BULK_UPDATE_OPPORTUNITY = 'BULK_UPDATE_OPPORTUNITY';
export const BULK_UPDATE_OPPORTUNITY_TAGS = 'BULK_UPDATE_OPPORTUNITY_TAGS';
export const BULK_UPDATE_OPPORTUNITY_CUSTOM_FIELD = 'BULK_UPDATE_OPPORTUNITY_CUSTOM_FIELD';
export const ADD_SINGLE_OPPORTUNITY = 'ADD_SINGLE_OPPORTUNITY';
export const ADD_OPPORTUNITY_NOTE = 'ADD_OPPORTUNITY_NOTE';
export const BULK_UPDATE_OPPORTUNITY_MULTIPLE_FIELD = 'BULK_UPDATE_OPPORTUNITY_MULTIPLE_FIELD';
export const REMOVE_OPPORTUNITY = 147;

export const UPDATE_PIPEDRIVE_OPPORTUNITY_STAGE_MAP = 'UPDATE_PIPEDRIVE_OPPORTUNITY_STAGE_MAP';

export const SET_UPSCALE_PIPEDRIVE_OPPORTUNITY_STGAE_MAPPING = 'SET_UPSCALE_PIPEDRIVE_OPPORTUNITY_STGAE_MAPPING';

export const FETCH_OPPORTUNITY_VIEWS = 'FETCH_OPPORTUNITY_VIEWS';
export const FETCH_OPPORTUNITY_VIEWS_SUCCESS = 'FETCH_OPPORTUNITY_VIEWS_SUCCESS';
export const UPDATE_SINGLE_VIEW_OPPORTUNITY = 'UPDATE_SINGLE_VIEW_OPPORTUNITY';
export const ADD_SINGLE_VIEW_OPPORTUNITY = 'ADD_SINGLE_VIEW_OPPORTUNITY';
export const DELETE_SINGLE_VIEW_OPPORTUNITY = 'DELETE_SINGLE_VIEW_OPPORTUNITY';
export const FETCH_OPPORTUNITY_VIEWS_FAILURE = 'FETCH_OPPORTUNITY_VIEWS_FAILURE';
export const SET_OPPORTUNITY_SORTING_FIELD = 'SET_OPPORTUNITY_SORTING_FIELD';
export const SET_OPPORTUNITY_SORTING_DIRECTION = 'SET_OPPORTUNITY_SORTING_DIRECTION';
export const SET_OPPORTUNITY_SELECTED_VIEW = 'SET_OPPORTUNITY_SELECTED_VIEW';
export const SET_OPPORTUNITY_TILE = 'SET_OPPORTUNITY_TILE';
export const SET_OPPORTUNITY_SEARCH = 'SET_OPPORTUNITY_SEARCH';

export const ADD_NEW_TRIGGER_SUCCESS = 148;
export const UPDATE_TRIGGER_SUCCESS = 149;
export const DELETE_TRIGGER_SUCCESS = 150;
export const FETCH_WORKFLOW_PROSPECT_VIEWS = 'FETCH_WORKFLOW_PROSPECT_VIEWS';
export const FETCH_WORKFLOW_PROSPECT_VIEWS_SUCCESS = 'FETCH_WORKFLOW_PROSPECT_VIEWS_SUCCESS';
export const FETCH_WORKFLOW_PROSPECT_VIEWS_FAILURE = 'FETCH_WORKFLOW_PROSPECT_VIEWS_FAILURE';
export const SET_WORKFLOW_PROSPECT_SORTING_FIELD = 'SET_WORKFLOW_PROSPECT_SORTING_FIELD';
export const SET_WORKFLOW_PROSPECT_SORTING_DIRECTION = 'SET_WORKFLOW_PROSPECT_SORTING_DIRECTION';
export const SET_WORKFLOW_PROSPECT_SELECTED_VIEW = 'SET_WORKFLOW_PROSPECT_SELECTED_VIEW';
export const UPDATE_SINGLE_VIEW_WORKFLOWPROSPECT = 'UPDATE_SINGLE_VIEW_WORKFLOWPROSPECT';
export const ADD_SINGLE_VIEW_WORKFLOWPROSPECT = 'ADD_SINGLE_VIEW_WORKFLOWPROSPECT';
export const DELETE_SINGLE_VIEW_WORKFLOWPROSPECT = 'DELETE_SINGLE_VIEW_WORKFLOWPROSPECT';
export const TOGGLE_COMPONENT_CHANGE = 'TOGGLE_COMPONENT_CHANGE';
export const SET_WORKFLOW_PROSPECT_SEARCH = 'SET_WORKFLOW_PROSPECT_SEARCH';

export const ADD_NEW_SCHEDULE_SUCCESS = 151;
export const UPDATE_SCHEDULE_SUCCESS = 152;
export const DELETE_SCHEDULE_SUCCESS = 153;
export const UPDATE_SALESFORCE_USER_MAP = 'UPDATE_SALESFORCE_USER_MAP';
export const UPDATE_SALESFORCE_STAGE_MAP = 'UPDATE_SALESFORCE_STAGE_MAP';
export const UPDATE_SALESFORCE_OPPORTUNITY_STAGE_MAP = 'UPDATE_SALESFORCE_OPPORTUNITY_STAGE_MAP';

export const UPDATE_CUSTOM_FIELD_IN_ARR = 'UPDATE_CUSTOM_FIELD_IN_ARR';
export const ADD_CUSTOM_FIELD_IN_ARR = 'ADD_CUSTOM_FIELD_IN_ARR';
export const DELETE_CUSTOM_FIELD_IN_ARR = 'DELETE_CUSTOM_FIELD_IN_ARR';
export const UPDATE_CUSTOM_FIELD_IN_MAP = 'UPDATE_CUSTOM_FIELD_IN_MAP';
export const ADD_CUSTOM_FIELD_IN_MAP = 'ADD_CUSTOM_FIELD_IN_MAP';
export const DELETE_CUSTOM_FIELD_IN_MAP = 'DELETE_CUSTOM_FIELD_IN_MAP';
export const BULK_UPDATE_CUSTOM_FIELD_IN_ARR = 'BULK_UPDATE_CUSTOM_FIELD_IN_ARR';

export const INSIGHTS_LOADING = 'INSIGHTS_LOADING';
export const INSIGHTS_LAST_MODIFIED = 'INSIGHTS_LAST_MODIFIED';

export const UPDATE_ACCOUNT_TAGS = 'UPDATE_ACCOUNT_TAGS';
export const UPDATE_ACCOUNT_EXCLUDED_DOMAINS = 'UPDATE_ACCOUNT_EXCLUDED_DOMAINS';
export const UPDATE_ACCOUNT_BLACKLISTED_DOMAINS = 'UPDATE_ACCOUNT_BLACKLISTED_DOMAINS';
export const UPDATE_ACCOUNT_BLACKLISTED_PHONE_CODES = 'UPDATE_ACCOUNT_BLACKLISTED_PHONE_CODES';
export const UPDATE_ACCOUNT_SETTING = 'UPDATE_ACCOUNT_SETTING';
export const UPDATE_ACCOUNT_CREDITS = 'UPDATE_ACCOUNT_CREDITS';

export const CUSTOM_FIELD_TYPE_MAP = {
  prospectCustomField: 'prospectCustomFields',
  accountCustomField: 'accountCustomFields',
  opportunityCustomField: 'opportunityCustomFields',
  callDisposition: 'callDispositions',
  callPurpose: 'callPurposes',
  opportunityStage: 'opportunityStages',
  prospectStage: 'prospectStages',
  ruleset: 'ruleSets',
  profile: 'profiles',
  upscaleHubspotUserMapping: 'upscaleHubspotUserMapping',
  upscaleHubspotStageMapping: 'upscaleHubspotStageMapping',
  upscalePipedriveUserMapping: 'upscalePipedriveUserMapping',
  upscalePipedriveStageMapping: 'upscalePipedriveStageMapping', // NOT in use
  upscalePipedriveOpportunityStageMapping: 'upscalePipedriveOpportunityStageMapping',
  upscaleSalesforceUserMapping: 'upscaleSalesforceUserMapping',
  upscaleSalesforceStageMapping: 'upscaleSalesforceStageMapping',
  upscaleSlackUserMapping: 'upscaleSlackUserMapping',
  trigger: 'triggers',
  upscaleSalesforceOpportunityStageMapping: 'upscaleSalesforceOpportunityStageMapping', // NOT in use
  schedule: 'schedules',
  twilioNumber: 'twilioNumbers',
  mailbox: 'mailboxes',
};
export const PROSPECT_TABLE_CUSTOM_COLUMN = 'PROSPECT_TABLE_CUSTOM_COLUMN';
export const CLIENT_ACCOUNT_TABLE_CUSTOM_COLUMN = 'CLIENT_ACCOUNT_TABLE_CUSTOM_COLUMN';
export const OPPORTUNITY_TABLE_CUSTOM_COLUMN = 'OPPORTUNITY_TABLE_CUSTOM_COLUMN';
export const WORKFLOW_PROSPECT_TABLE_CUSTOM_COLUMN = 'WORKFLOW_PROSPECT_TABLE_CUSTOM_COLUMN';

export const RESET_PROSPECT_PROFILE_ACTIVITY = 'RESET_PROSPECT_PROFILE_ACTIVITY';
export const FETCH_PROSPECT_PROFILE_ACTIVITY = 'FETCH_PROSPECT_PROFILE_ACTIVITY';
export const FETCH_PROSPECT_PROFILE_ACTIVITY_SUCCESS = 'FETCH_PROSPECT_PROFILE_ACTIVITY_SUCCESS';
export const FETCH_PROSPECT_PROFILE_ACTIVITY_FAILURE = 'FETCH_PROSPECT_PROFILE_ACTIVITY_FAILURE';
export const FETCH_PROSPECT_PROFILE_ACTIVITY_BY_ID = 'FETCH_PROSPECT_PROFILE_ACTIVITY_BY_ID';
export const FETCH_NEXT_PROSPECT_PROFILE_ACTIVITY = 'FETCH_NEXT_PROSPECT_PROFILE_ACTIVITY';
export const REMOVE_PROSPECT_PROFILE_ACTIVITY = 'REMOVE_PROSPECT_PROFILE_ACTIVITY';

export const RESET_CLIENT_ACCOUNT_PROFILE_ACTIVITY = 'RESET_CLIENT_ACCOUNT_PROFILE_ACTIVITY';
export const FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY = 'FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY';
export const FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_SUCCESS = 'FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_SUCCESS';
export const FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_FAILURE = 'FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_FAILURE';
export const FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_BY_ID = 'FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_BY_ID';
export const REMOVE_CLIENT_ACCOUNT_PROFILE_ACTIVITY = 'REMOVE_CLIENT_ACCOUNT_PROFILE_ACTIVITY';
export const FETCH_NEXT_CLIENT_ACCOUNT_PROFILE_ACTIVITY = 'FETCH_NEXT_CLIENT_ACCOUNT_PROFILE_ACTIVITY';

export const RESET_OPPORTUNITY_PROFILE_ACTIVITY = 'RESET_OPPORTUNITY_PROFILE_ACTIVITY';
export const FETCH_OPPORTUNITY_PROFILE_ACTIVITY = 'FETCH_OPPORTUNITY_PROFILE_ACTIVITY';
export const FETCH_OPPORTUNITY_PROFILE_ACTIVITY_SUCCESS = 'FETCH_OPPORTUNITY_PROFILE_ACTIVITY_SUCCESS';
export const FETCH_OPPORTUNITY_PROFILE_ACTIVITY_FAILURE = 'FETCH_OPPORTUNITY_PROFILE_ACTIVITY_FAILURE';
export const FETCH_OPPORTUNITY_PROFILE_ACTIVITY_BY_ID = 'FETCH_OPPORTUNITY_PROFILE_ACTIVITY_BY_ID';
export const REMOVE_OPPORTUNITY_PROFILE_ACTIVITY = 'REMOVE_OPPORTUNITY_PROFILE_ACTIVITY';
export const FETCH_FINISHED_TASKS = 154;

export const SET_OUTBOX_VIEW = 'SET_OUTBOX_VIEW';
export const FETCH_OUTBOX_VIEWS = 'FETCH_OUTBOX_VIEWS';
export const FETCH_OUTBOX_VIEWS_SUCCESS = 'FETCH_OUTBOX_VIEWS_SUCCESS';
export const DELETE_OTUBOX_EMAILS = 'DELETE_OTUBOX_EMAILS';
export const FETCH_OUTBOX_VIEWS_FAILURE = 'FETCH_OUTBOX_VIEWS_FAILURE';
export const SET_OUTBOX_SELECTED_VIEW = 'SET_OUTBOX_SELECTED_VIEW';
export const SET_OUTBOX_SORTING_FIELD = 'SET_OUTBOX_SORTING_FIELD';
export const SET_OUTBOX_SORTING_DIRECTION = 'SET_OUTBOX_SORTING_DIRECTION';
export const UPDATE_SINGLE_VIEW_OUTBOX = 'UPDATE_SINGLE_VIEW_OUTBOX';
export const ADD_SINGLE_VIEW_OUTBOX = 'ADD_SINGLE_VIEW_OUTBOX';
export const DELETE_SINGLE_VIEW_OUTBOX = 'DELETE_SINGLE_VIEW_OUTBOX';
export const SET_OUTBOX_SEARCH = 'SET_OUTBOX_SEARCH';
export const SET_OUTBOX_TILE = 'SET_OUTBOX_TILE';
export const UPDATE_OUTBOX_DATE_RANGE = 'UPDATE_OUTBOX_DATE_RANGE';
export const UPDATE_OUTBOX_ON_WORKFLOW_REPORT_NAV = 'UPDATE_OUTBOX_ON_WORKFLOW_REPORT_NAV';

export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILURE = 'FETCH_NOTIFICATION_FAILURE';
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const BULK_UPDATE_NOTIFICATION = 'BULK_UPDATE_NOTIFICATION';
export const FETCH_NEXT_NOTIFICATION = 'FETCH_NEXT_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export const FETCH_IMPORT_PROSPECT_HISTORY = 'FETCH_IMPORT_PROSPECT_HISTORY';
export const FETCH_IMPORT_PROSPECT_HISTORY_SUCCESS = 'FETCH_IMPORT_PROSPECT_HISTORY_SUCCESS';
export const FETCH_IMPORT_PROSPECT_HISTORY_FAILURE = 'FETCH_IMPORT_PROSPECT_HISTORY_FAILURE';
export const FETCH_IMPORT_PROSPECT_HISTORY_NEXT = 'FETCH_IMPORT_PROSPECT_HISTORY_NEXT';
export const RESET_IMPORT_PROSPECT_HISTORY = 'RESET_IMPORT_PROSPECT_HISTORY';

export const FETCH_IMPORT_HISTORY_VIEWS = 'FETCH_IMPORT_HISTORY_VIEWS';
export const FETCH_IMPORT_HISTORY_VIEWS_SUCCESS = 'FETCH_IMPORT_HISTORY_VIEWS_SUCCESS';
export const FETCH_IMPORT_HISTORY_VIEWS_FAILURE = 'FETCH_IMPORT_HISTORY_VIEWS_FAILURE';
export const UPDATE_IMPORT_HISTORY = 'UPDATE_IMPORT_HISTORY';
export const ADD_IMPORT_HISTORY = 'ADD_IMPORT_HISTORY';
export const DELETE_IMPORT_HISTORY = 'DELETE_IMPORT_HISTORY';
export const SET_IMPORT_HISTORY_SORTING_FIELD = 'SET_IMPORT_HISTORY_SORTING_FIELD';
export const SET_IMPORT_HISTORY_SORTING_DIRECTION = 'SET_IMPORT_HISTORY_SORTING_DIRECTION';
export const SET_IMPORT_HISTORY_SELECTED_VIEW = 'SET_IMPORT_HISTORY_SELECTED_VIEW';

export const UPDATE_SLACK_USER_MAP = 'UPDATE_SLACK_USER_MAP';

export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';
export const FETCH_RECENT_SEARCH = 'FETCH_RECENT_SEARCH';
export const FETCH_RECENT_SEARCH_SUCESS = 'FETCH_RECENT_SEARCH_SUCESS';
export const FETCH_RECENT_SEARCH_FAILURE = 'FETCH_RECENT_SEARCH_FAILURE';
export const ADD_SEARCH_VIEW = 'ADD_SEARCH_VIEW';

export const SET_PLAN_DETAILS = 'SET_PLAN_DETAILS';
