import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer';

const whitelist = [
  'UserSettings', // must
  'ProspectTableColumn',
  'ClientAccountTableColumn',
  'OpportunityTableColumn',
  'WorkflowProspectTableColumn',
  'UniversalSearch',
  'ProspectFilter',
  'AccountFilter',
  'OpportunityFilter',
];

const persistConfig = {
  key: process.env.REACT_APP_UPSCALE_APP_URL || 'localhost',
  storage,
  whitelist, // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);
// create store
const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

const persistor = persistStore(store);
export { persistor, store };

