import {
  FETCH_PROSPECT_PROFILE_ACTIVITY, FETCH_PROSPECT_PROFILE_ACTIVITY_BY_ID, FETCH_PROSPECT_PROFILE_ACTIVITY_FAILURE, FETCH_PROSPECT_PROFILE_ACTIVITY_SUCCESS,
  RESET_PROSPECT_PROFILE_ACTIVITY, FETCH_NEXT_PROSPECT_PROFILE_ACTIVITY,
} from '../../action/type';
import { constructIndividualProfileActivityNewState, updateActivitiesInRedux } from '../../Utils/reducer.util';

const initialState = {
  isLoading: '',
  LastEvaluatedKey: null,
  entityId: '',
  activities: [],
  error: '',
};

export const prospectProfileActivityReducer = (curState = initialState, action = { payload: '', action: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case RESET_PROSPECT_PROFILE_ACTIVITY:
      newState = { ...initialState };
      break;
    case FETCH_PROSPECT_PROFILE_ACTIVITY:
      newState = {
        ...curState,
        entityId: payload.entityId,
        isLoading: FETCH_PROSPECT_PROFILE_ACTIVITY,
      };
      break;
    case FETCH_PROSPECT_PROFILE_ACTIVITY_SUCCESS: {
      const { entityId } = payload;
      if (entityId === curState.entityId) {
        newState = {
          ...curState,
          LastEvaluatedKey: payload.LastEvaluatedKey,
          isLoading: FETCH_PROSPECT_PROFILE_ACTIVITY_SUCCESS,
          activities: (payload.activities).sort((a, b) => b.createdAt - a.createdAt),
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_PROSPECT_PROFILE_ACTIVITY_FAILURE:
      newState = {
        ...curState,
        error: payload.error,
        isLoading: FETCH_PROSPECT_PROFILE_ACTIVITY_FAILURE,
      };
      break;
    case FETCH_PROSPECT_PROFILE_ACTIVITY_BY_ID: {
      newState = updateActivitiesInRedux({ payload, curState });
      break;
    }
    case FETCH_NEXT_PROSPECT_PROFILE_ACTIVITY: {
      const { entityId } = payload;
      if (entityId === curState.entityId) {
        newState = constructIndividualProfileActivityNewState({ action, curState, loadingState: FETCH_NEXT_PROSPECT_PROFILE_ACTIVITY });
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
