import * as TYPES from '../action/type';

const initialState = {
  items: [],
  isLoading: 'NaN',
  error: '',
  sortingOption: null,
  direction: true,
};
export const workflowsReportInsightReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TYPES.SET_WORKFLOW_REPORT_DATA:
      newState = {
        ...curState,
        isLoading: true,
      };
      break;
    case TYPES.SET_WORKFLOW_REPORT_DATA_SUCCESS:
      newState = {
        ...curState,
        isLoading: false,
        items: action.payload,
      };
      break;
    case TYPES.SET_WORKFLOW_REPORT_DATA_FAILURE:
      newState = {
        ...curState,
        isLoading: false,
        items: [],
        error: action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};

