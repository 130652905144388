import {
  FETCH_WORKFLOW_PROSPECT_FAILURE, FETCH_WORKFLOW_PROSPECT_SUCCESS, FETCH_WORKFLOW_PROSPECT, RESET_WORKFLOW_PROSPECT_ITEMS, SET_WORKFLOW_PROSPECT_PAGE_INDEX, SET_WORKFLOW_PROSPECT_PAGE_SIZE, RELOAD_WORKFLOW_PROSPECT_ITEMS,
} from '../action/WorkflowProspect';
import { isNull, isUndefined } from '../Utils/dataType.util';
import { generateName } from '../Utils/helper.util';

const initialState = {
  workflowId: '',
  isLoading: NaN,
  error: '',
  items: [],
  pageIndex: 0,
  pageSize: 10,
  totalCount: 0,
  requestId: '',
};
export const workflowProspectsListReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_WORKFLOW_PROSPECT:
      newState = {
        ...curState,
        error: null,
        isLoading: true,
        ...action.payload,
      };
      break;
    case FETCH_WORKFLOW_PROSPECT_FAILURE:
      newState = {
        ...curState, isLoading: false, skip: 0, error: action.payload, requestId: '',
      };
      break;
    case FETCH_WORKFLOW_PROSPECT_SUCCESS: {
      const {
        resultArr, totalCount, requestId,
      } = action.payload;
      if (requestId === curState.requestId) {
        resultArr.forEach((d, index) => {
          const item = ({ ...d, isBlacklisted: (isUndefined(d.isBlacklisted) || isNull(d.isBlacklisted)) ? false : d.isBlacklisted, prospectName: generateName(d) });
          resultArr[index] = item;
        });
        newState = {
          ...curState,
          totalCount,
          isLoading: false,
          items: curState.items.concat(resultArr),
        };
      } else {
        newState = curState;
      }

      break;
    }
    case SET_WORKFLOW_PROSPECT_PAGE_SIZE: {
      newState = {
        ...initialState,
        isLoading: curState.isLoading,
        pageSize: action.payload,
      };
      break;
    }
    case SET_WORKFLOW_PROSPECT_PAGE_INDEX: {
      newState = {
        ...curState,
        pageIndex: action.payload,
      };
      break;
    }
    case RESET_WORKFLOW_PROSPECT_ITEMS: {
      newState = { ...initialState };
      break;
    }
    case RELOAD_WORKFLOW_PROSPECT_ITEMS: {
      const { pageSize } = curState;
      newState = { ...initialState, pageIndex: 0, pageSize };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

