import {
  FETCH_TEMPLATE_DETAIL,
  FETCH_TEMPLATE_DETAIL_FAILURE,
  FETCH_TEMPLATE_DETAIL_SUCCESS,
  RESET_TEMPLATE_ITEMS,
  UPDATE_TEMPLATE_DETAIL,
  REMOVE_TEMPLATE,
  SET_CLONE_TEMPLATE,
} from '../../action/Template/index';
import { BULK_UPDATE_TEMPLATE } from '../../action/type';
import { isArray } from '../../Utils/dataType.util';
import { getMatchingKeys } from '../../Utils/V2/creator.util';

const initialState = {
  detailMap: { },
};
const templateDetailsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_TEMPLATE_DETAIL:
      newState = {
        ...curState,
        detailMap: {
          ...curState.detailMap,
          [action.payload.id]: {
            isLoading: true,
            data: {},
            error: '',
          },
        },
      };
      break;
    case FETCH_TEMPLATE_DETAIL_SUCCESS: {
      const [, id] = action.payload._id.split(':');
      if (curState.detailMap[id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              isLoading: false,
              data: action.payload,
              error: '',
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_TEMPLATE_DETAIL_FAILURE: {
      if (curState.detailMap[action.payload.id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              ...curState.detailMap[action.payload.id],
              isLoading: false,
              error: action.payload.error,
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }

      break;
    }
    case SET_CLONE_TEMPLATE: {
      newState = {
        ...curState,
        detailMap: {
          ...curState.detailMap,
          [action.payload.id]: {
            isLoading: false,
            cloned: true,
            data: action.payload,
            error: '',
            lastFetched: Date.now(),
          },
        },
      };
      break;
    }
    case UPDATE_TEMPLATE_DETAIL: {
      const { id, data } = action.payload;
      if (curState.detailMap[id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [id]: {
              ...curState.detailMap[id],
              data: {
                ...curState.detailMap[id].data,
                ...data,
              },
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_TEMPLATE: {
      const { ids, data } = action.payload;
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: ids });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, ...data },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case REMOVE_TEMPLATE: {
      const { detailMap } = curState;
      if (isArray(action.payload)) {
        action.payload.forEach((d) => {
          delete detailMap[d];
        });
      } else {
        delete detailMap[action.payload];
      }
      newState = {
        detailMap: { ...detailMap },
      };
      break;
    }
    case RESET_TEMPLATE_ITEMS: {
      newState = { ...initialState };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

export default templateDetailsReducer;
