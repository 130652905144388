import {
  FETCH_NOTIFICATION, FETCH_NOTIFICATION_SUCCESS, FETCH_NOTIFICATION_FAILURE, RESET_NOTIFICATION, FETCH_NEXT_NOTIFICATION, ADD_NEW_NOTIFICATION, UPDATE_NOTIFICATION, BULK_UPDATE_NOTIFICATION,
} from '../action/type';
import { convertArrayToObject, findAndUpdateInObject, updateItemsWithNewProperties } from '../Utils/reducer.util';

const initialState = {
  items: [],
  isLoading: NaN,
  error: '',
  LastEvaluatedKey: {},
  accountId: '',
};
export const notificationReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case RESET_NOTIFICATION:
      newState = {
        ...curState,
        items: [],
        LastEvaluatedKey: {},
      };
      break;
    case FETCH_NOTIFICATION:
      newState = {
        ...curState,
        error: '',
        accountId: payload.accountId,
        isLoading: FETCH_NOTIFICATION,
      };
      break;
    case FETCH_NOTIFICATION_SUCCESS:
      newState = {
        ...curState,
        error: '',
        isLoading: FETCH_NOTIFICATION_SUCCESS,
        items: payload.items,
        LastEvaluatedKey: payload.LastEvaluatedKey,
      };
      break;
    case FETCH_NOTIFICATION_FAILURE:
      newState = {
        ...curState,
        isLoading: FETCH_NOTIFICATION_FAILURE,
        error: payload,
      };
      break;
    case UPDATE_NOTIFICATION: case ADD_NEW_NOTIFICATION: {
      const copyOfNewItems = convertArrayToObject({ items: [...curState.items] });
      findAndUpdateInObject({ arrObject: copyOfNewItems, item: action.payload, onlyUpdate: type === UPDATE_NOTIFICATION });
      newState = {
        ...curState,
        isLoading: type,
        items: Object.values(copyOfNewItems).sort((a, b) => b.createdAt - a.createdAt),
      };
      break;
    }

    case BULK_UPDATE_NOTIFICATION: {
      const copyOfNewItems = [...curState.items];
      const newItems = updateItemsWithNewProperties({ items: copyOfNewItems, dataMap: action.payload });
      newState = { ...curState };
      if (newItems.isModified) {
        newState.isLoading = type;
        newState.items = newItems.itemsCopy.sort((a, b) => b.createdAt - a.createdAt);
      }
      break;
    }
    case FETCH_NEXT_NOTIFICATION: {
      newState = {
        ...curState,
        error: '',
        isLoading: FETCH_NEXT_NOTIFICATION,
        items: curState.items.concat(payload.items).sort((a, b) => b.createdAt - a.createdAt),
        LastEvaluatedKey: payload.LastEvaluatedKey,
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
