export const OUTBOX_TILE_DATA = 'OUTBOX_TILE_DATA';
export const OUTBOX_TILE_DATA_SUCCESS = 'OUTBOX_TILE_DATA_SUCCESS';
export const OUTBOX_TILE_DATA_FAILURE = 'OUTBOX_TILE_DATA_FAILURE';
export const RESET_OUTBOX_TILE_DATA = 'RESET_OUTBOX_TILE_DATA';

export const FETCH_OUTBOX_V2 = 'FETCH_OUTBOX_V2';
export const FETCH_OUTBOX_SUCCESS_V2 = 'FETCH_OUTBOX_SUCCESS_V2';
export const FETCH_OUTBOX_FAILURE_V2 = 'FETCH_OUTBOX_FAILURE_V2';

export const SET_OUTBOX_PAGE_SIZE = 'SET_OUTBOX_PAGE_SIZE';
export const SET_OUTBOX_PAGE_INDEX = 'SET_OUTBOX_PAGE_INDEX';
export const RESET_OUTBOX_ITEMS = 'RESET_OUTBOX_ITEMS';
export const RELOAD_OUTBOX_ITEMS = 'RELOAD_OUTBOX_ITEMS';

export const OUTBOX_FAILED_COUNT = 'OUTBOX_FAILED_COUNT';
