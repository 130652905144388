export default class History {
  constructor() {
    this.customhistory = null;
  }

  setHistory(history) {
    this.customhistory = history;
  }

  getHistory() {
    return this.customhistory;
  }

  resetHistory() {
    this.customhistory = null;
  }
}

export const CustomHistory = new History();
