export const TASK_TILE_DATA = 'TASK_TILE_DATA';
export const TASK_TILE_DATA_SUCCESS = 'TASK_TILE_DATA_SUCCESS';
export const TASK_TILE_DATA_FAILURE = 'TASK_TILE_DATA_FAILURE';

export const TASK_RANGE_TILE_DATA = 'TASK_RANGE_TILE_DATA';
export const TASK_RANGE_TILE_DATA_SUCCESS = 'TASK_RANGE_TILE_DATA_SUCCESS';
export const TASK_RANGE_TILE_DATA_FAILURE = 'TASK_RANGE_TILE_DATA_FAILURE';

export const RESET_TASK_TILE_DATA = 'RESET_TASK_TILE_DATA';

export const FETCH_TASK_V2 = 'FETCH_TASK_V2';
export const FETCH_TASK_SUCCESS_V2 = 'FETCH_TASK_SUCCESS_V2';
export const FETCH_TASK_FAILURE_V2 = 'FETCH_TASK_FAILURE_V2';

export const SET_TASK_PAGE_SIZE = 'SET_TASK_PAGE_SIZE';
export const SET_TASK_PAGE_INDEX = 'SET_TASK_PAGE_INDEX';
export const RESET_TASK_ITEMS = 'RESET_TASK_ITEMS';
export const RELOAD_TASK_ITEMS = 'RELOAD_TASK_ITEMS';
export const RESET_TASK_DETAIL_DATA = 'RESET_TASK_DETAIL_DATA';

export const UPDATE_TASK_LIST_ON_SINGLE_UPDATE = 'UPDATE_TASK_LIST_ON_SINGLE_UPDATE';

export const FETCH_TASK_VIEW_V2 = 'FETCH_TASK_VIEW_V2';
export const FETCH_TASK_VIEW_SUCCESS_V2 = 'FETCH_TASK_VIEW_SUCCESS_V2';
export const FETCH_TASK_VIEW_FAILURE_V2 = 'FETCH_TASK_VIEW_FAILURE_V2';
export const RESET_TASK_VIEW_ITEMS = 'RESET_TASK_VIEW_ITEMS';
export const SET_ACTIVE_TASK_ID = 'SET_ACTIVE_TASK_ID';

export const FETCH_TASK_DETAIL = 'FETCH_TASK_DETAIL';
export const FETCH_TASK_DETAIL_FAILURE = 'FETCH_TASK_DETAIL_FAILURE';
export const FETCH_TASK_DETAIL_SUCCESS = 'FETCH_TASK_DETAIL_SUCCESS';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const REMOVE_TASK_DETAIL = 'REMOVE_TASK_DETAIL';
export const RESET_TASK_DETAILS = 'RESET_TASK_DETAILS';
export const BULK_UPDATE_TASK_MULTIPLE_FIELD = 'BULK_UPDATE_TASK_MULTIPLE_FIELD';

export const REMOVE_TASK = 'REMOVE_TASK';
export const BULK_UPDATE_TASK = 'BULK_UPDATE_TASK';
