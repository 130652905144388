import React from 'react';
import { MATERIAL_ICON, COLORICONS } from './material.icons';
import './index.scss';
import { voidFunction } from '../../../Utils/helper2.util';

export default function MaterialIcon({
  icon, style = {}, className = '', onClick = voidFunction, id, isDisabledIcon = false, onKeyDown = voidFunction,
  type = 'symbol',
}) {
  return (
    <span
      id={id}
      className={`${type === 'material' ? 'material-icons' : 'material-symbols-outlined'} fontvlg ${isDisabledIcon ? 'noPointerEvents' : 'cursor-pointer'} ${className}`}
      style={{
        textTransform: 'lowercase',
        userSelect: 'none',
        outline: 'none',
        ...style,
        color: isDisabledIcon ? '#c4c4c4' : (style.color || COLORICONS[icon] || COLORICONS.default),
      }}
      onKeyDown={onKeyDown}
      onClick={onClick}
      role="tab"
      tabIndex={0}
    >
      {MATERIAL_ICON[icon] || icon}
    </span>
  );
}
