export const FETCH_TEMPLATE_V2 = 'FETCH_TEMPLATE_V2';
export const FETCH_TEMPLATE_SUCCESS_V2 = 'FETCH_TEMPLATE_SUCCESS_V2';
export const FETCH_TEMPLATE_FAILURE_V2 = 'FETCH_TEMPLATE_FAILURE_V2';

export const SET_TEMPLATE_PAGE_SIZE = 'SET_TEMPLATE_PAGE_SIZE';
export const SET_TEMPLATE_PAGE_INDEX = 'SET_TEMPLATE_PAGE_INDEX';
export const RESET_TEMPLATE_ITEMS = 'RESET_TEMPLATE_ITEMS';
export const RELOAD_TEMPLATE = 'RELOAD_TEMPLATE';

export const FETCH_TEMPLATE_DETAIL = 'FETCH_TEMPLATE_DETAIL';
export const FETCH_TEMPLATE_DETAIL_SUCCESS = 'FETCH_TEMPLATE_DETAIL_SUCCESS';
export const FETCH_TEMPLATE_DETAIL_FAILURE = 'FETCH_TEMPLATE_DETAIL_FAILURE';
export const RESET_TEMPLATE_DETAIL_DATA = 'RESET_TEMPLATE_DETAIL_DATA';
export const UPDATE_TEMPLATE_DETAIL = 'UPDATE_TEMPLATE_DETAIL';
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const SET_CLONE_TEMPLATE = 'SET_CLONE_TEMPLATE';

export const SET_TEMPLATE_SEARCH = 'SET_TEMPLATE_SEARCH';

export const UPDATE_TEMPLATE_LIST_ITEM = 'UPDATE_TEMPLATE_LIST_ITEM';
