/* eslint-disable no-console */
import { IS_NOT_PRODUCTION } from '../constants';

export class Logger {
  static debug(...params) {
    if (IS_NOT_PRODUCTION) console.debug(params);
  }

  static warn(...params) {
    if (IS_NOT_PRODUCTION) console.warn(params);
  }

  static info(...params) {
    if (IS_NOT_PRODUCTION) console.info(params);
  }

  static error(...params) {
    if (IS_NOT_PRODUCTION) console.warn(params);
  }

  static log(...params) {
    if (IS_NOT_PRODUCTION) console.log(params);
  }
}
