import { SET_OFFLINE } from '../action/type';

const initialState = false;

export const offlineReducer = (curState = initialState, action = { type: 0, payload: '' }) => {
  let newState = curState;
  if (action.type === SET_OFFLINE) {
    newState = action.payload;
  }
  return newState;
};
