import { SET_PLAN_DETAILS } from '../action/type';

const initialState = {
  planId: '',
  details: null,
};

export const planDetailReducer = (curState = initialState, action = { type: 0, payload: '' }) => {
  let newState = curState;
  if (action.type === SET_PLAN_DETAILS) {
    newState = action.payload;
  }
  return newState;
};
