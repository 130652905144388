import {
  SOCKET_EVENT,
} from '../action/type';

const initialState = {
  lastAction: '',
  lastMsg: '',
  error: null,
};

export const socketEventReducer = (curState = initialState, action = { type: 0, payload: '' }) => {
  let newState = curState;
  const { type, payload } = action;
  if (type === SOCKET_EVENT) {
    newState = {
      ...curState,
      lastAction: payload.action,
      lastMsg: payload.msg,
      error: payload.error,
    };
  }
  return newState;
};
