import React from 'react';
import ReactTooltip from 'react-tooltip';
import MaterialIcon from '../MaterialIcon';

export default function CautiousIcon({
  dataText, dataId, tooltipProperties = {}, iconStyle = {},
}) {
  return (
    <>
      <ReactTooltip
        id={dataId}
        place="bottom"
        // effect="solid"
        multiline
        {...tooltipProperties}
        html
      />
      <span className="flex align-items-center" data-tip={dataText} data-for={dataId}>
        <MaterialIcon icon="infoIcon" style={iconStyle} className="fontmd" />
      </span>
    </>
  );
}
