import {
  FETCH_OPPORTUNITY_FAILURE_V2,
  FETCH_OPPORTUNITY_SUCCESS_V2,
  FETCH_OPPORTUNITY_V2,
  RELOAD_OPPORTUNITY_ITEMS,
  RESET_OPPORTUNITY_ITEMS,
  SET_OPPORTUNITY_PAGE_INDEX,
  SET_OPPORTUNITY_PAGE_SIZE,
  UPDATE_OPPORTUNITY_LIST_ITEM,
} from '../action/Opportunity';

const initialState = {
  isLoading: NaN,
  error: '',
  items: [],
  pageIndex: 0,
  pageSize: 10,
  totalCount: 0,
  requestId: '',
};
export const opportunityReducerV2 = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_OPPORTUNITY_V2:
      newState = {
        ...curState,
        error: null,
        isLoading: true,
        ...action.payload,
      };
      break;
    case FETCH_OPPORTUNITY_FAILURE_V2:
      newState = {
        ...curState, isLoading: false, skip: 0, error: action.payload, requestId: '',
      };
      break;
    case FETCH_OPPORTUNITY_SUCCESS_V2: {
      const {
        resultArr, totalCount, requestId,
      } = action.payload;
      if (requestId === curState.requestId) {
        newState = {
          ...curState,
          totalCount,
          isLoading: false,
          items: curState.items.concat(resultArr),
        };
      } else {
        newState = curState;
      }

      break;
    }
    case SET_OPPORTUNITY_PAGE_SIZE: {
      newState = {
        ...initialState,
        isLoading: curState.isLoading,
        pageSize: action.payload,
      };
      break;
    }
    case SET_OPPORTUNITY_PAGE_INDEX: {
      newState = {
        ...curState,
        pageIndex: action.payload,
      };
      break;
    }
    case RESET_OPPORTUNITY_ITEMS: {
      newState = { ...initialState };
      break;
    }
    case RELOAD_OPPORTUNITY_ITEMS: {
      const { pageSize } = curState;
      newState = { ...initialState, pageIndex: 0, pageSize };
      break;
    }
    case UPDATE_OPPORTUNITY_LIST_ITEM: {
      const { items } = curState;
      const { _id, data, index: refIndex } = action.payload;
      let isModified = false;
      if (refIndex) {
        if (items[refIndex]?._id === _id) {
          items[refIndex] = { ...items[refIndex], ...data };
          isModified = true;
          break;
        }
      } else {
        for (let index = items.length; index >= 0; index -= 1) {
          if (items[index]?._id === _id) {
            items[index] = { ...items[index], ...data };
            isModified = true;
            break;
          }
        }
      }
      if (isModified) {
        newState = { ...curState, items: [...items] };
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

