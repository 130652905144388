import {
  OUTBOX_TILE_DATA, OUTBOX_TILE_DATA_FAILURE, OUTBOX_TILE_DATA_SUCCESS, RESET_OUTBOX_TILE_DATA,
} from '../action/Outbox';

const initialState = {
  isLoading: NaN,
  data: { sent: 0 },
  error: '',
};
export const outboxTilesReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case OUTBOX_TILE_DATA: {
      newState = {
        ...curState,
        isLoading: true,
      };
      break;
    }
    case OUTBOX_TILE_DATA_SUCCESS: {
      const newDataObj = { ...action.payload.resultArr };
      newState = {
        ...curState,
        data: newDataObj,
        isLoading: false,
      };
      break;
    }
    case OUTBOX_TILE_DATA_FAILURE: {
      newState = {
        ...curState,
        error: action.payload,
        isLoading: false,
      };
      break;
    }
    case RESET_OUTBOX_TILE_DATA: {
      newState = { ...initialState };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

