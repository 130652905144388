export const OPPORTUNITY_TILE_DATA = 'OPPORTUNITY_TILE_DATA';
export const OPPORTUNITY_TILE_DATA_SUCCESS = 'OPPORTUNITY_TILE_DATA_SUCCESS';
export const OPPORTUNITY_TILE_DATA_FAILURE = 'OPPORTUNITY_TILE_DATA_FAILURE';
export const RESET_OPPORTUNITY_TILE_DATA = 'RESET_OPPORTUNITY_TILE_DATA';

export const FETCH_OPPORTUNITY_V2 = 'FETCH_OPPORTUNITY_V2';
export const FETCH_OPPORTUNITY_SUCCESS_V2 = 'FETCH_OPPORTUNITY_SUCCESS_V2';
export const FETCH_OPPORTUNITY_FAILURE_V2 = 'FETCH_OPPORTUNITY_FAILURE_V2';

export const SET_OPPORTUNITY_PAGE_SIZE = 'SET_OPPORTUNITY_PAGE_SIZE';
export const SET_OPPORTUNITY_PAGE_INDEX = 'SET_OPPORTUNITY_PAGE_INDEX';
export const RESET_OPPORTUNITY_ITEMS = 'RESET_OPPORTUNITY_ITEMS';
export const RELOAD_OPPORTUNITY_ITEMS = 'RELOAD_OPPORTUNITY_ITEMS';
export const UPDATE_OPPORTUNITY_LIST_ITEM = 'UPDATE_OPPORTUNITY_LIST_ITEM';

export const FETCH_OPPORTUNITY_DETAIL = 'FETCH_OPPORTUNITY_DETAIL';
export const FETCH_OPPORTUNITY_DETAIL_SUCCESS = 'FETCH_OPPORTUNITY_DETAIL_SUCCESS';
export const FETCH_OPPORTUNITY_DETAIL_FAILURE = 'FETCH_OPPORTUNITY_DETAIL_FAILURE';
export const RESET_OPPORTUNITY_DETAIL_DATA = 'RESET_OPPORTUNITY_DETAIL_DATA';
export const UPDATE_OPPORTUNITY_DETAIL = 'UPDATE_OPPORTUNITY_DETAIL';
