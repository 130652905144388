import * as React from 'react';
import ReactModal from 'react-modal';
import PopupHeader from '../Common/Atoms/PopupHeader';
import './index.scss';

class Modal extends React.PureComponent {
  render() {
    const {
      isOpen, onCancel, className, closeModalOnOverlayClick, closeModalOnEsc,
      extraClass, title, modalId, onConfirm, children, actionDescription, showCloseIcon,
      buttonId,
    } = this.props;
    return (
      <div className="custom-modal-container">
        <ReactModal
          contentLabel="Modal"
          isOpen={isOpen}
          onRequestClose={onCancel}
          shouldCloseOnOverlayClick={closeModalOnOverlayClick}
          shouldCloseOnEsc={closeModalOnEsc}
          ariaHideApp={false}
          className={{
            base: 'custom-modal custom-modal--full-screen',
            afterOpen: 'custom-modal_after-open',
            beforeClose: 'custom-modal_before-close',
          }}
          overlayClassName={{
            base: `custom-modal-overlay full-screen-custom-modal ${className}`,
            afterOpen: 'custom-modal-overlay_after-open',
            beforeClose: 'custom-modal-overlay_before-close',
          }}
          closeTimeoutMS={500}
        >
          <div className={`custom-modal-container ${extraClass}`} id="upscaleReactModalContainer">
            <PopupHeader
              onClick={onCancel}
              buttonId={buttonId}
              title={title}
              actionDescription={actionDescription}
              buttonStyle={{ display: showCloseIcon ? 'auto' : 'none' }}
            />
            <div id={modalId}>
              {React.cloneElement(children, { onConfirm, onCancel })}
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default Modal;
