import { COMMON_SUBSCRIPTION_PLANS } from '../components/SettingBilling/constant';
import { UPSCALE_ADMIN_EMAIL } from '../constants';
import { differenceInDaysForTimestamps } from '../Utils/date.util';

export const isSelfSignedCognitoUserOnFreeTrial = (userAttributes) => userAttributes?.['custom:plan']?.toLowerCase().includes(COMMON_SUBSCRIPTION_PLANS[0].toLowerCase());
export const cognitoUserNeedsToUpgrade = (userAttributes) => (differenceInDaysForTimestamps(Number(userAttributes?.['custom:planExpiry']), Date.now()) + 1) <= 0;

export const removeAllExistingPoups = () => {
  const modalElements = document.getElementsByClassName('ReactModalPortal');
  for (let index = modalElements.length - 1; index >= 0; index -= 1) {
    modalElements[index].parentNode.removeChild(modalElements[index]);
  }
};

export const isSuperAdmin = (email) => email === UPSCALE_ADMIN_EMAIL;
