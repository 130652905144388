import {
  FETCH_OUTBOX_FAILURE_V2, FETCH_OUTBOX_SUCCESS_V2, FETCH_OUTBOX_V2, OUTBOX_TILE_DATA, OUTBOX_TILE_DATA_SUCCESS, SET_OUTBOX_PAGE_INDEX, SET_OUTBOX_PAGE_SIZE,
  RESET_OUTBOX_ITEMS,
  OUTBOX_TILE_DATA_FAILURE,
  RELOAD_OUTBOX_ITEMS,
  RESET_OUTBOX_TILE_DATA,
  OUTBOX_FAILED_COUNT,
} from '.';
import { onError } from '../../libs/errorLib';
import { queryTilesData, queryList, getFailedEmailCount } from '../../service/v2.api';
import { cleanObject } from '../../Utils/helper.util';
import { SET_OUTBOX_SEARCH, SET_OUTBOX_TILE, UPDATE_OUTBOX_DATE_RANGE } from '../type';

export function setOutboxActiveTile(tile) {
  return (dispatch) => {
    dispatch({ type: SET_OUTBOX_TILE, payload: tile });
  };
}

export function setOutboxTileChangeThunk({
  accountId, tile, query,
}) {
  return (dispatch) => {
    const queryCopy = { ...query, conditions: [...query.conditions] };
    dispatch(setOutboxActiveTile(tile));
    dispatch({ type: OUTBOX_TILE_DATA });
    queryTilesData({
      accountId, query: queryCopy, entity: 'email',
    }).then((res) => {
      dispatch({ type: OUTBOX_TILE_DATA_SUCCESS, payload: res });
    }).catch((err) => {
      dispatch({ type: OUTBOX_TILE_DATA_FAILURE, payload: err });
    });
  };
}

export function setOutboxPageIndex(index) {
  return (dispatch) => {
    dispatch({ type: SET_OUTBOX_PAGE_INDEX, payload: index });
  };
}

export function setOutboxPageSize(newSize) {
  return (dispatch) => {
    dispatch({ type: SET_OUTBOX_PAGE_SIZE, payload: newSize });
  };
}

export function setOutboxFiltersChangesThunk({
  accountId, tile, query, search, sort, requestId, skip, limit,
  resetSize = false, resetIndex = false, resetAll = false,
}) {
  return (dispatch) => {
    const queryCopy = { ...query, conditions: [...query.conditions], outboxTile: tile };
    resetAll && dispatch({ type: RESET_OUTBOX_ITEMS });
    resetSize && dispatch(setOutboxPageSize(10));
    resetIndex && dispatch(setOutboxPageIndex(0));
    dispatch({ type: FETCH_OUTBOX_V2, payload: cleanObject({ requestId, items: (resetAll || resetSize || resetIndex) ? [] : null }) });
    queryList({
      accountId, query: queryCopy, search, sort, requestId, skip, limit, entity: 'email',
    }).then((res) => {
      dispatch({ type: FETCH_OUTBOX_SUCCESS_V2, payload: res });
    }).catch((err) => {
      dispatch({ type: FETCH_OUTBOX_FAILURE_V2, payload: err });
    });
  };
}

export function reloadOutbox({ reloadTiles = true }) {
  return (dispatch) => {
    reloadTiles && dispatch({ type: RESET_OUTBOX_TILE_DATA });
    dispatch({ type: RELOAD_OUTBOX_ITEMS });
  };
}

export function setOutboxSearch(search) {
  return (dispatch) => {
    dispatch({ type: SET_OUTBOX_SEARCH, payload: search });
  };
}

export function reloadOutboxTiles() {
  return (dispatch) => dispatch({ type: RESET_OUTBOX_TILE_DATA });
}

export function setOutboxFailCount(accountId) {
  return (dispatch) => {
    getFailedEmailCount(accountId).then((resp) => {
      const { totalCount } = resp;
      dispatch({ type: OUTBOX_FAILED_COUNT, payload: totalCount });
    }).catch((e) => {
      onError(e);
    });
  };
}

export function setOutboxDateRange(dateRange) {
  return (dispatch) => {
    dispatch({ type: UPDATE_OUTBOX_DATE_RANGE, payload: dateRange });
  };
}
