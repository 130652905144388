// import { add, endOfDay } from 'date-fns';
import * as TYPES from '../action/type';
import { INFINITE_DATE } from '../constants/filter.constant';
import { addSingleView, deleteSingleView, updateSingleView } from '../Utils/filterReducer.util';
import { generateDefaultDateRange } from '../Utils/inactiveTasks.util';

const initialState = {
  views: [],
  isLoading: NaN,
  error: '',
  selectedView: { filters: [], operator: 'And' },
  sortingOption: {
    label: 'Due time',
    id: 'executionTime',
  },
  direction: false,
  search: '',
  tile: 'All',
  groupBy: 'Today',
  dateRange: {
    ...generateDefaultDateRange(6),
    endDate: INFINITE_DATE,
    rangeType: 'All',
  },
};
export const taskFilterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TYPES.FETCH_TASK_VIEWS:
      newState = {
        ...curState,
        error: null,
        isLoading: TYPES.FETCH_TASK_VIEWS,
      };
      break;
    case TYPES.FETCH_TASK_VIEWS_SUCCESS:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_TASK_VIEWS_SUCCESS,
        views: action.payload.views,
      };
      break;
    case TYPES.FETCH_TASK_VIEWS_FAILURE:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_TASK_VIEWS_FAILURE,
        error: action.payload,
      };
      break;
    case TYPES.SET_TASK_SORTING_FIELD:
      newState = {
        ...curState,
        isLoading: TYPES.SET_TASK_SORTING_FIELD,
        sortingOption: action.payload.sortingOption,
      };
      break;
    case TYPES.SET_TASK_SORTING_DIRECTION:
      newState = {
        ...curState,
        isLoading: TYPES.SET_TASK_SORTING_DIRECTION,
        direction: action.payload.direction,
      };
      break;
    case TYPES.SET_TASK_SELECTED_VIEW:
      newState = {
        ...curState,
        isLoading: TYPES.SET_TASK_SELECTED_VIEW,
        selectedView: action.payload.selectedView,
      };
      break;
    case TYPES.UPDATE_SINGLE_VIEW_TASK:
      newState = updateSingleView(curState, action);
      break;
    case TYPES.ADD_SINGLE_VIEW_TASK:
      newState = addSingleView(curState, action);
      break;
    case TYPES.DELETE_SINGLE_VIEW_TASK:
      newState = deleteSingleView(curState, action);
      break;
    case TYPES.SET_TASK_SEARCH:
      newState = {
        ...curState,
        search: action.payload,
      };
      break;
    case TYPES.SET_TASK_TILE:
      newState = {
        ...curState,
        tile: action.payload,
      };
      break;
    case TYPES.SET_TASK_GROUP_BY:
      newState = {
        ...curState,
        groupBy: action.payload,
      };
      break;
    case TYPES.UPDATE_TASK_DATE_RANGE: {
      newState = {
        ...curState,
        dateRange: action.payload,
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

