import { cleanObject } from '../Utils/helper.util';
/* https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch */
const BASE_URL = 'https://www.universal-tutorial.com/api';
// TODO: Move this to env
const API_TOKEN = 'RY5VI77DhOv2BPjtjjFzf3zjPcu4StVNRfGDHbwIa5bQFJrlqhEoKzdhNPN7J6XzNNY';
const USER_EMAIL = 'sajan.kashi02@gmail.com';

// Example GET method implementation:
export async function callFetch({
  url = '',
  method = 'GET', data = {}, headers = {},
}) {
  // Default options are marked with *
  const response = await fetch(url, cleanObject({
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: method === 'GET' ? null : JSON.stringify(data), // body data type must match "Content-Type" headers
  }));
  return response.json(); // parses JSON response into native JavaScript objects
}

export const getAuthToken = () => callFetch({
  url: `${BASE_URL}/getaccesstoken`,
  headers: {
    'api-token': API_TOKEN,
    'user-email': USER_EMAIL,
  },
});

export const getCountries = ({ token }) => callFetch({
  url: `${BASE_URL}/countries`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getStates = ({ token, country }) => callFetch({
  url: `${BASE_URL}/states/${country}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getCities = ({ token, state }) => callFetch({
  url: `${BASE_URL}/cities/${state}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
