import {
  endOfDay, startOfDay, startOfWeek,
  startOfMonth, endOfMonth, endOfWeek, format, roundToNearestMinutes, addDays, addMonths, startOfYear, endOfYear, differenceInDays,
} from 'date-fns';
import {
  getTimezoneOffset,
} from 'date-fns-tz';
import { DATE_RANGE_REPORTS_MAP, ONE_DAY_IN_MS } from '../constants';
import { DATE_FILTER_RANGE_MAP } from '../constants/filter.constant';

export const CHART_TIME_UNITS = {
  Daily: { unit: 'day', format: 'MMM dd, yy' },
  Weekly: { unit: 'week', format: 'wo, yy' },
  Monthly: { unit: 'month', format: 'MMM, yy' },
  Quarterly: { unit: 'quarter', format: 'qqqq, yy' },
  Yearly: { unit: 'year', format: 'yyyy' },
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const monthShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

// TODO: Make Date picker good looking or use this across, Remove this.
export const TimeBlocksIntervals = [
  { label: '12:00 AM', value: { hours: 0, minutes: 0 } },
  { label: '12:15 AM', value: { hours: 0, minutes: 15 } },
  { label: '12:30 AM', value: { hours: 0, minutes: 30 } },
  { label: '12:45 AM', value: { hours: 0, minutes: 45 } },
  { label: '01:00 AM', value: { hours: 1, minutes: 0 } },
  { label: '01:15 AM', value: { hours: 1, minutes: 15 } },
  { label: '01:30 AM', value: { hours: 1, minutes: 30 } },
  { label: '01:45 AM', value: { hours: 1, minutes: 45 } },
  { label: '02:00 AM', value: { hours: 2, minutes: 0 } },
  { label: '02:15 AM', value: { hours: 2, minutes: 15 } },
  { label: '02:30 AM', value: { hours: 2, minutes: 30 } },
  { label: '02:45 AM', value: { hours: 2, minutes: 45 } },
  { label: '03:00 AM', value: { hours: 3, minutes: 0 } },
  { label: '03:15 AM', value: { hours: 3, minutes: 15 } },
  { label: '03:30 AM', value: { hours: 3, minutes: 30 } },
  { label: '03:45 AM', value: { hours: 3, minutes: 45 } },
  { label: '04:00 AM', value: { hours: 4, minutes: 0 } },
  { label: '04:15 AM', value: { hours: 4, minutes: 15 } },
  { label: '04:30 AM', value: { hours: 4, minutes: 30 } },
  { label: '04:45 AM', value: { hours: 4, minutes: 45 } },
  { label: '05:00 AM', value: { hours: 5, minutes: 0 } },
  { label: '05:15 AM', value: { hours: 5, minutes: 15 } },
  { label: '05:30 AM', value: { hours: 5, minutes: 30 } },
  { label: '05:45 AM', value: { hours: 5, minutes: 45 } },
  { label: '06:00 AM', value: { hours: 6, minutes: 0 } },
  { label: '06:15 AM', value: { hours: 6, minutes: 15 } },
  { label: '06:30 AM', value: { hours: 6, minutes: 30 } },
  { label: '06:45 AM', value: { hours: 6, minutes: 45 } },
  { label: '07:00 AM', value: { hours: 7, minutes: 0 } },
  { label: '07:15 AM', value: { hours: 7, minutes: 15 } },
  { label: '07:30 AM', value: { hours: 7, minutes: 30 } },
  { label: '07:45 AM', value: { hours: 7, minutes: 45 } },
  { label: '08:00 AM', value: { hours: 8, minutes: 0 } },
  { label: '08:15 AM', value: { hours: 8, minutes: 15 } },
  { label: '08:30 AM', value: { hours: 8, minutes: 30 } },
  { label: '08:45 AM', value: { hours: 8, minutes: 45 } },
  { label: '09:00 AM', value: { hours: 9, minutes: 0 } },
  { label: '09:15 AM', value: { hours: 9, minutes: 15 } },
  { label: '09:30 AM', value: { hours: 9, minutes: 30 } },
  { label: '09:45 AM', value: { hours: 9, minutes: 45 } },
  { label: '10:00 AM', value: { hours: 10, minutes: 0 } },
  { label: '10:15 AM', value: { hours: 10, minutes: 15 } },
  { label: '10:30 AM', value: { hours: 10, minutes: 30 } },
  { label: '10:45 AM', value: { hours: 10, minutes: 45 } },
  { label: '11:00 AM', value: { hours: 11, minutes: 0 } },
  { label: '11:15 AM', value: { hours: 11, minutes: 15 } },
  { label: '11:30 AM', value: { hours: 11, minutes: 30 } },
  { label: '11:45 AM', value: { hours: 11, minutes: 45 } },
  { label: '12:00 PM', value: { hours: 12, minutes: 0 } },
  { label: '12:15 PM', value: { hours: 12, minutes: 15 } },
  { label: '12:30 PM', value: { hours: 12, minutes: 30 } },
  { label: '12:45 PM', value: { hours: 12, minutes: 45 } },
  { label: '01:00 PM', value: { hours: 13, minutes: 0 } },
  { label: '01:15 PM', value: { hours: 13, minutes: 15 } },
  { label: '01:30 PM', value: { hours: 13, minutes: 30 } },
  { label: '01:45 PM', value: { hours: 13, minutes: 45 } },
  { label: '02:00 PM', value: { hours: 14, minutes: 0 } },
  { label: '02:15 PM', value: { hours: 14, minutes: 15 } },
  { label: '02:30 PM', value: { hours: 14, minutes: 30 } },
  { label: '02:45 PM', value: { hours: 14, minutes: 45 } },
  { label: '03:00 PM', value: { hours: 15, minutes: 0 } },
  { label: '03:15 PM', value: { hours: 15, minutes: 15 } },
  { label: '03:30 PM', value: { hours: 15, minutes: 30 } },
  { label: '03:45 PM', value: { hours: 15, minutes: 45 } },
  { label: '04:00 PM', value: { hours: 16, minutes: 0 } },
  { label: '04:15 PM', value: { hours: 16, minutes: 15 } },
  { label: '04:30 PM', value: { hours: 16, minutes: 30 } },
  { label: '04:45 PM', value: { hours: 16, minutes: 45 } },
  { label: '05:00 PM', value: { hours: 17, minutes: 0 } },
  { label: '05:15 PM', value: { hours: 17, minutes: 15 } },
  { label: '05:30 PM', value: { hours: 17, minutes: 30 } },
  { label: '05:45 PM', value: { hours: 17, minutes: 45 } },
  { label: '06:00 PM', value: { hours: 18, minutes: 0 } },
  { label: '06:15 PM', value: { hours: 18, minutes: 15 } },
  { label: '06:30 PM', value: { hours: 18, minutes: 30 } },
  { label: '06:45 PM', value: { hours: 18, minutes: 45 } },
  { label: '07:00 PM', value: { hours: 19, minutes: 0 } },
  { label: '07:15 PM', value: { hours: 19, minutes: 15 } },
  { label: '07:30 PM', value: { hours: 19, minutes: 30 } },
  { label: '07:45 PM', value: { hours: 19, minutes: 45 } },
  { label: '08:00 PM', value: { hours: 20, minutes: 0 } },
  { label: '08:15 PM', value: { hours: 20, minutes: 15 } },
  { label: '08:30 PM', value: { hours: 20, minutes: 30 } },
  { label: '08:45 PM', value: { hours: 20, minutes: 45 } },
  { label: '09:00 PM', value: { hours: 21, minutes: 0 } },
  { label: '09:15 PM', value: { hours: 21, minutes: 15 } },
  { label: '09:30 PM', value: { hours: 21, minutes: 30 } },
  { label: '09:45 PM', value: { hours: 21, minutes: 45 } },
  { label: '10:00 PM', value: { hours: 22, minutes: 0 } },
  { label: '10:15 PM', value: { hours: 22, minutes: 15 } },
  { label: '10:30 PM', value: { hours: 22, minutes: 30 } },
  { label: '10:45 PM', value: { hours: 22, minutes: 45 } },
  { label: '11:00 PM', value: { hours: 23, minutes: 0 } },
  { label: '11:15 PM', value: { hours: 23, minutes: 15 } },
  { label: '11:30 PM', value: { hours: 23, minutes: 30 } },
  { label: '11:45 PM', value: { hours: 23, minutes: 45 } },
];

export const INTERVAL_TYPES_MAP = {
  Everyday: [...days],
  'Mon - Fri': [...days.slice(1, 6)],
  'Sat - Sun': [days[0], days[6]],
};

export const findAndApplyTimeBlocks = (val = {}, list) => {
  const resp = (list || []).find((d) => d.value.hours === val?.hours && d.value.minutes === val?.minutes);
  return resp || null;
};

// export const isDisabledTimeBlockOption = (option, compareObject, compareMinutesEqual = true) => {
//   if (option.hours < compareObject.hours) return true;
//   if (option.hours === compareObject.hours) return compareMinutesEqual ? option.minutes <= compareObject.minutes : option.minutes < compareObject.minutes;
//   return false;
// };

const patterns = {
  '': ({
    dayName, date, monthName, year,
  }) => `${dayName}, ${date} ${monthName} ${year}`,
  ll: ({
    dayName, date, monthName, year,
  }) => `${dayName.substr(0, 3)}, ${date} ${monthName} ${year}`, // wed, 24 January 2017
  mD: ({
    date, monthName, year,
  }) => `${date} ${monthName} ${year}`, // 26 January 2017
  OO: (data) => ({ ...data }), // { dayName, date, monthName, year }
  SF: ({ date, shortMonthName }) => `${date} ${shortMonthName}`, // 26 Jan
};

// TODO: Sajan why do we need this ?
const getFormattedDate = (dateObj = new Date(), pattern = '') => {
  const _dateObj = new Date(dateObj);
  const year = _dateObj.getFullYear(); // 2020
  const date = _dateObj.getDate(); // 20
  const monthName = months[_dateObj.getMonth()]; // May
  const shortMonthName = monthShort[_dateObj.getMonth()];
  const dayName = days[_dateObj.getDay()]; // Wednesday
  return patterns[pattern]({
    dayName, date, monthName, year, shortMonthName,
  });
};

// returns 5/20/2020, 10:52:02 PM
const getLocaleTime = (date = undefined, timeZone = undefined) => {
  const options = timeZone ? { timeZone: `${timeZone}` } : undefined;
  let localDateTime;
  if (date) {
    localDateTime = new Date(date).toLocaleString('en-US', options);
    const timeFormat = localDateTime.slice(-3);
    // To remove the seconds from counter
    localDateTime = localDateTime.slice(0, (localDateTime.length) - 6).concat(timeFormat);
  }
  return localDateTime;
};

// TODO: We need to remove this asap
function getRelativeDifferenceString(previousDateTimeStamp, singleLetter = false) {
  const current = Date.now();

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = Math.abs(current - previousDateTimeStamp);

  let relativeDiff = '';

  if (elapsed < msPerMinute) {
    relativeDiff = `${Math.round(elapsed / 1000)}${singleLetter ? 's' : ` second${Math.round(elapsed / 1000) > 1 ? 's' : ''}`}`;
  } else if (elapsed < msPerHour) {
    relativeDiff = `${Math.round(elapsed / msPerMinute)}${singleLetter ? 'm' : ` minute${Math.round(elapsed / msPerMinute) > 1 ? 's' : ''}`}`;
  } else if (elapsed < msPerDay) {
    relativeDiff = `${Math.round(elapsed / msPerHour)}${singleLetter ? 'h' : ` hour${Math.round(elapsed / msPerHour) > 1 ? 's' : ''}`}`;
  } else if (elapsed < msPerMonth) {
    relativeDiff = `${Math.round(elapsed / msPerDay)}${singleLetter ? 'd' : ` day${Math.round(elapsed / msPerDay) > 1 ? 's' : ''}`}`;
  } else if (elapsed < msPerYear) {
    relativeDiff = `${singleLetter ? '0y ' : ''}${Math.round(elapsed / msPerMonth)}${singleLetter ? 'm' : ` month${Math.round(elapsed / msPerMonth) > 1 ? 's' : ''}`}`;
  } else {
    relativeDiff = `${Math.round(elapsed / msPerYear)}${singleLetter ? 'y' : ` year${Math.round(elapsed / msPerYear) > 1 ? 's' : ''}`}`;
  }
  return relativeDiff;
}

export const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfTomorrow: startOfDay(addDays(new Date(), 1)),
  endOfTomorrow: endOfDay(addDays(new Date(), 1)),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastFourMonths: startOfMonth(addMonths(new Date(), -4)),
  startOfLastThreeMonths: startOfMonth(addMonths(new Date(), -3)),
  startOfLastSixMonths: startOfMonth(addMonths(new Date(), -6)),
  startOfPreviousYear: startOfYear(addMonths(new Date(), -12)),
  endOfPreviousYear: endOfYear(addMonths(new Date(), -12)),
  startOfCurrentYear: startOfYear(new Date()),
  endOfCurrentYear: endOfYear(new Date()),
};

// TODO: Amit to refactor this
function getStartAndEndDateForInsights(option) {
  let startDate = defineds.startOfToday; let endDate = defineds.startOfToday;
  switch (option) {
    case DATE_FILTER_RANGE_MAP.Yesterday: {
      startDate = defineds.startOfYesterday;
      endDate = defineds.endOfYesterday;
      break;
    }
    case DATE_FILTER_RANGE_MAP.Today: {
      startDate = defineds.startOfToday;
      endDate = defineds.endOfToday;
      break;
    }
    case DATE_FILTER_RANGE_MAP['This Week']: {
      startDate = defineds.startOfWeek;
      endDate = defineds.endOfWeek;
      break;
    }
    case DATE_FILTER_RANGE_MAP['The Last Week']: case DATE_RANGE_REPORTS_MAP['Last Week']: {
      startDate = defineds.startOfLastWeek;
      endDate = defineds.endOfLastWeek;
      break;
    }
    case DATE_FILTER_RANGE_MAP['This Month']: {
      startDate = defineds.startOfMonth;
      endDate = defineds.endOfMonth;
      break;
    }
    case DATE_FILTER_RANGE_MAP['The Last Month']: case DATE_RANGE_REPORTS_MAP['Last Month']: {
      startDate = defineds.startOfLastMonth;
      endDate = defineds.endOfLastMonth;
      break;
    }
    case DATE_FILTER_RANGE_MAP['This Year']: {
      startDate = defineds.startOfCurrentYear;
      endDate = defineds.endOfCurrentYear;
      break;
    }
    case DATE_FILTER_RANGE_MAP['The Last Year']: {
      startDate = defineds.startOfPreviousYear;
      endDate = defineds.endOfPreviousYear;
      break;
    }
    case DATE_FILTER_RANGE_MAP['One Month Ago']: {
      startDate = defineds.startOfLastMonth;
      endDate = defineds.endOfLastMonth;
      break;
    }
    case DATE_FILTER_RANGE_MAP['One Week Ago']: {
      startDate = defineds.startOfLastWeek;
      endDate = defineds.endOfLastWeek;
      break;
    }
    case DATE_FILTER_RANGE_MAP['One Year Ago']: {
      startDate = defineds.startOfPreviousYear;
      endDate = defineds.endOfPreviousYear;
      break;
    }
    default:
      break;
  }
  return { startDate: startOfDay(startDate).getTime(), endDate: endOfDay(endDate).getTime() };
}

// TODO: Remove this Anand with: https://stackoverflow.com/questions/62590455/format-time-interval-in-seconds-as-x-hours-y-minutes-z-seconds
function descriptiveMilliseconds(milliseconds) {
  const hrs = Math.floor(milliseconds / 3600).toFixed();
  const mins = Math.floor(milliseconds / 60).toFixed();
  const secs = (milliseconds % 60).toFixed();
  return `${hrs > 0 ? `${hrs} hrs ` : ''}${mins > 0 ? `${mins} mins ` : ''}${secs > 0 || !(hrs > 0 && mins > 0) ? `${secs} secs ` : ''}`;
}

const isThrottleLimitBreached = (count, userThrottleLimit, numberOfUsersToSendEmail, dateOfExecution) => {
  const execDate = format(dateOfExecution, 'LLL d');
  const newCount = count + numberOfUsersToSendEmail;
  return count >= userThrottleLimit ? `You can not send any more emails as daily user email quota of ${userThrottleLimit} will get breached on ${execDate}`
    : newCount > userThrottleLimit ? `Please remove ${newCount - userThrottleLimit} prospect(s) as you are breaching daily user email quota of ${userThrottleLimit} on ${execDate}` : undefined;
};

export const startOfDayWithTimeZone = (date, timeZone) => {
  const parts = Intl.DateTimeFormat('en-US', {
    timeZone,
    hourCycle: 'h23',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).formatToParts(date);
  const hour = Math.floor(parts.find((i) => i.type === 'hour').value);
  const minute = Math.floor(parts.find((i) => i.type === 'minute').value);
  const second = Math.floor(parts.find((i) => i.type === 'second').value);
  return (date - hour * 3600000 - minute * 60000 - second * 1000);
};
// export const endOfDayWithTimeZone = (...args) => startOfDayWithTimeZone(...args) + ONE_DAY_IN_MS;

export const isSameDayWithTimeZone = (timeStampA, timeStampB, timeZone) => {
  const startOfDayTimestamp = startOfDayWithTimeZone(timeStampA, timeZone);
  const endOfDayTimestamp = startOfDayTimestamp + ONE_DAY_IN_MS;
  return timeStampB > startOfDayTimestamp && timeStampB <= endOfDayTimestamp;
};

// TODO: Anand to remove this
const addPrefixZero = (n) => (n < 10 ? `0${n}` : n);

// TODO: Remove this Anand with: https://stackoverflow.com/questions/62590455/format-time-interval-in-seconds-as-x-hours-y-minutes-z-seconds
const convertMilliSecondsToDay = (milliseconds, showSeconds = false) => {
  let hr; let min; let sec;
  sec = Math.floor(milliseconds / 1000) || 0;
  min = Math.floor(sec / 60) || 0;
  sec %= 60;
  hr = Math.floor(min / 60) || 0;
  min %= 60;
  const day = Math.floor(hr / 24) || 0;
  hr %= 24;
  let resp;
  if (day) {
    resp = `${day} day${day > 1 ? 's' : ''} ${addPrefixZero(hr)} hours`;
  } else if (showSeconds && !hr) {
    resp = `${addPrefixZero(min)} min ${addPrefixZero(sec)} secs`;
  } else resp = `${addPrefixZero(hr)} hours ${addPrefixZero(min)}  min`;
  return resp;
};

const getRequiredTimeInGivenTimeZone = (timestamp, srcTimeZone, destTimeZone) => {
  const offsetMs = getTimezoneOffset(destTimeZone);
  const localOffsetMs = getTimezoneOffset(srcTimeZone);
  return timestamp + localOffsetMs - offsetMs;
};

const getClientTimezone = () => Intl.DateTimeFormat().resolvedOptions()?.timeZone;
export const CLIENT_TIMEZONE = (getClientTimezone() === 'Asia/Calcutta' ? 'Asia/Kolkata' : getClientTimezone()) || 'Asia/Kolkata';

export const formatDatePickerInput = (executionTime, workflowTimeZone) => getRequiredTimeInGivenTimeZone(executionTime, workflowTimeZone, CLIENT_TIMEZONE);

export const formatDatePickerOutput = (executionTime, workflowTimeZone) => getRequiredTimeInGivenTimeZone(executionTime, CLIENT_TIMEZONE, workflowTimeZone);
const DATE_FORMAT_REPORTS = 'M/d/yyyy';

const calculateMilliseconds = ({ day, hours, minutes }) => (((minutes || 0) * 60 * 1000) + ((hours || 0) * 3600 * 1000) + ((day || 0) * 1000 * 60 * 60 * 24));

export const getWorkflowStepExecutionTime = ({ days: day, hours, minutes }) => Date.now() + calculateMilliseconds({ day, hours, minutes });

export const roundToNearestQuarter = () => roundToNearestMinutes(Date.now() + 600000, { nearestTo: 15 }).getTime();

/**
  * return array of dates between two dates
  * @param  {} startDate in milliseconds
  * @param  {} endDate in milliseconds
  * @returns []
  */
export const getDates = (startDate, endDate) => {
  const dates = [];
  let currentDate = startDate;
  const addDaysCustomFunction = function (day) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + day);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(format(currentDate, DATE_FORMAT_REPORTS));
    currentDate = addDaysCustomFunction.call(currentDate, 1);
  }
  return dates;
};

export const sortDateArray = (datesArray) => datesArray.sort((a, b) => new Date(Object.keys(a)[0]) - new Date(Object.keys(b)[0]));

export const dateRangeTillToday = getStartAndEndDateForInsights;
export const universalDateFormat = (d) => format(d || Date.now(), 'd LLL yy h:mm a');
export const differenceInDaysForTimestamps = (t1, t2) => differenceInDays(t1, t2);

export {
  getFormattedDate, getLocaleTime,
  getRelativeDifferenceString, getStartAndEndDateForInsights,
  descriptiveMilliseconds, monthShort, isThrottleLimitBreached,
  convertMilliSecondsToDay, DATE_FORMAT_REPORTS,
};
