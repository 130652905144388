import { updateReduxStore } from '../Utils/creator.util';
import * as CONSTANTS from './type';

function toggleComponentChange(payload) {
  return updateReduxStore({ type: CONSTANTS.TOGGLE_COMPONENT_CHANGE, payload });
}

function setToggleComponentChangeThunk(payload) {
  return (dispatch) => {
    dispatch(toggleComponentChange(payload));
  };
}

export { setToggleComponentChangeThunk };
