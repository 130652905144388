import { EXEMPTED_STATUS_CODES } from '../constants';
import { onError } from './errorLib';

export const responseCallback = (resp) => {
  if (resp && !EXEMPTED_STATUS_CODES.includes(resp.statusCode)) {
    if (resp?.body) onError(new Error('RESPONSE BROKE:'));
  }
  return resp;
};

export const parseResponse = (resp) => {
  if (resp && !EXEMPTED_STATUS_CODES.includes(resp.statusCode)) {
    throw new Error(resp.body);
  } else if (!resp) {
    throw new Error('Response not found');
  }
  const parsedData = JSON.parse(resp.body);
  if (parsedData?.error || parsedData?.data?.error || ([400, 404].includes(parsedData?.data?.statusCode))) {
    throw new Error(parsedData?.error || parsedData?.data?.error || parsedData?.data?.message || 'Something went wrong, if the same error persist please report at contact@upscale.ai');
  }
  return parsedData?.data;
};
