const FEATURE_CHECKLIST = [
  'Multiple Integrations', 'Unlimited Workflows', 'Dialer & Call Recording',
  'Download Reports', '24x7 Support',
  'Integrations Unavailable',
];

export const COMMON_SUBSCRIPTION_PLANS = ['21DaysFreeTrial', 'basic', 'essential']; // TODO: update this whenever we create new plan or price unit item

export const isLTDPlan = (pId) => (pId.includes('external-RocketHub') || pId.includes('upscale_tier'));

export const PLANS = [
  {
    id: COMMON_SUBSCRIPTION_PLANS[0],
    h1: '14 Days Free Trial',
    desc: 'For a sneak-peek into an all-inclusive sales engagement platform',
    price: {
      Annual: 'Free',
      Monthly: 'Free',
    },
    priceUnit: '',
    subPrice: '',
    checklist: [
      [1, FEATURE_CHECKLIST[0]],
      [1, FEATURE_CHECKLIST[1]],
      [1, FEATURE_CHECKLIST[2]],
      [1, FEATURE_CHECKLIST[3]],
      [1, FEATURE_CHECKLIST[4]],
    ],
  },
  {
    id: COMMON_SUBSCRIPTION_PLANS[1],
    h1: 'Basic',
    desc: 'For smaller teams getting started with a sales engagement platform',
    price: {
      Annual: '50',
      Monthly: '75',
    },
    priceUnit: '$',
    subPrice: '/ user',
    checklist: [
      [1, FEATURE_CHECKLIST[1]],
      [1, 'Dialer (Prepaid)'],
      [1, FEATURE_CHECKLIST[3]],
      [1, FEATURE_CHECKLIST[4]],
      [0, FEATURE_CHECKLIST[5]],
    ],
  },
  {
    id: COMMON_SUBSCRIPTION_PLANS[2],
    h1: 'Essential',
    desc: 'For early-stage companies to increase sales productivity & revenue',
    price: {
      Annual: '75',
      Monthly: '99',
    },
    priceUnit: '$',
    subPrice: '/ user',
    isStarred: true,
    checklist: [
      [1, FEATURE_CHECKLIST[0]],
      [1, FEATURE_CHECKLIST[1]],
      [1, FEATURE_CHECKLIST[2]],
      [1, FEATURE_CHECKLIST[3]],
      [1, FEATURE_CHECKLIST[4]],
    ],
  },
  {
    id: '3',
    h1: 'Custom Plan',
    desc: 'For fast-growing companies looking out for a fully customized sales engagement platform ',
    subPrice: '',
    buttonDetail: 'Have custom requirements?',
    checklist: [
    ],
    cta: 'Talk to Us',
  },
];
