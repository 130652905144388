import * as TYPES from '../../action/type';
import { SET_TEMPLATE_SEARCH } from '../../action/Template';
import { addSingleView, deleteSingleView, updateSingleView } from '../../Utils/filterReducer.util';

const initialState = {
  views: [],
  isLoading: NaN,
  error: '',
  search: '',
  selectedView: { filters: [], operator: 'And' },
  sortingOption: { id: 'createdAt' },
  direction: false,
};
const templateFilterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TYPES.FETCH_TEMPLATE_VIEWS:
      newState = {
        ...curState,
        error: null,
        isLoading: TYPES.FETCH_TEMPLATE_VIEWS,
      };
      break;
    case TYPES.FETCH_TEMPLATE_VIEWS_SUCCESS:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_TEMPLATE_VIEWS_SUCCESS,
        views: action.payload.views,
      };
      break;
    case TYPES.FETCH_TEMPLATE_VIEWS_FAILURE:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_TEMPLATE_VIEWS_FAILURE,
        error: action.payload,
      };
      break;
    case TYPES.SET_TEMPLATE_SORTING_FIELD:
      newState = {
        ...curState,
        isLoading: TYPES.SET_TEMPLATE_SORTING_FIELD,
        sortingOption: action.payload.sortingOption,
      };
      break;
    case TYPES.SET_TEMPLATE_SORTING_DIRECTION:
      newState = {
        ...curState,
        isLoading: TYPES.SET_TEMPLATE_SORTING_DIRECTION,
        direction: action.payload.direction,
      };
      break;
    case TYPES.SET_TEMPLATE_SELECTED_VIEW:
      newState = {
        ...curState,
        isLoading: TYPES.SET_TEMPLATE_SELECTED_VIEW,
        selectedView: action.payload.selectedView,
      };
      break;
    case TYPES.UPDATE_SINGLE_VIEW_TEMPLATE:
      newState = updateSingleView(curState, action);
      break;
    case TYPES.ADD_SINGLE_VIEW_TEMPLATE:
      newState = addSingleView(curState, action);
      break;
    case TYPES.DELETE_SINGLE_VIEW_TEMPLATE:
      newState = deleteSingleView(curState, action);
      break;
    case SET_TEMPLATE_SEARCH:
      newState = {
        ...curState,
        search: action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};

export default templateFilterReducer;
