import {
  FETCH_PROSPECT_DETAIL,
  FETCH_PROSPECT_DETAIL_FAILURE,
  FETCH_PROSPECT_DETAIL_SUCCESS,
  RESET_PROSPECT_ITEMS,
  UPDATE_PROSPECT_DETAIL,
} from '../action/Prospect';
import {
  ADD_PROSPECT_NOTE, BULK_UPDATE_PROSPECT, BULK_UPDATE_PROSPECT_CUSTOM_FIELD, BULK_UPDATE_PROSPECT_MULTIPLE_FIELD, BULK_UPDATE_PROSPECT_TAGS, REMOVE_PROSPECT,
} from '../action/type';
import { combineAndGenerateValidTags } from '../Utils/crossMapper.util';
import { getMatchingKeys } from '../Utils/V2/creator.util';

const initialState = {
  detailMap: { },
};
export const prospectDetails = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_PROSPECT_DETAIL:
      newState = {
        ...curState,
        detailMap: {
          ...curState.detailMap,
          [action.payload.id]: {
            isLoading: true,
            data: {},
            error: '',
          },
        },
      };
      break;
    case FETCH_PROSPECT_DETAIL_SUCCESS: {
      if (curState.detailMap[action.payload.prospectEmail]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.prospectEmail]: {
              isLoading: false,
              data: action.payload,
              error: '',
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_PROSPECT_DETAIL_FAILURE: {
      if (curState.detailMap[action.payload.id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              ...curState.detailMap[action.payload.id],
              isLoading: false,
              error: action.payload.error,
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }

      break;
    }
    case RESET_PROSPECT_ITEMS: {
      newState = { ...initialState };
      break;
    }
    case UPDATE_PROSPECT_DETAIL: {
      const { prospectEmail: id, data } = action.payload;
      if (curState.detailMap[id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [id]: {
              ...curState.detailMap[id],
              data: {
                ...curState.detailMap[id].data,
                ...data,
              },
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    //
    case BULK_UPDATE_PROSPECT: {
      const { prospectEmails, data } = action.payload;
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: prospectEmails });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, ...data },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_PROSPECT_CUSTOM_FIELD: {
      const { prospectEmails, data } = action.payload;
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: prospectEmails });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, customFields: { ...(detailMap[key].data?.customFields || {}), ...data } },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_PROSPECT_TAGS: {
      const { prospectEmails, data } = action.payload;
      const { newTags } = data;
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: prospectEmails });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, tags: combineAndGenerateValidTags(detailMap[key].data?.tags || [], newTags) },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_PROSPECT_MULTIPLE_FIELD: {
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: Object.keys(action.payload) });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, ...action.payload[key] },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case ADD_PROSPECT_NOTE: {
      const { prospectEmail, data } = action.payload;
      const { detailMap } = curState;
      if (detailMap[prospectEmail]?.data?._id && data.note) {
        const notes = detailMap[prospectEmail].data?.notes || [];
        if (!notes.find((d) => d.id === data.note.id)) {
          notes.unshift(data.note);
          detailMap[prospectEmail] = {
            ...detailMap[prospectEmail],
            data: { ...detailMap[prospectEmail].data, notes: notes || [] },
          };
          newState = {
            detailMap: { ...detailMap },
          };
        }
        newState = curState;
      } else {
        newState = curState;
      }
      break;
    }
    case REMOVE_PROSPECT: {
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: action.payload.ids });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          delete detailMap[key];
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
