export const CALL_TILE_DATA = 'CALL_TILE_DATA';
export const CALL_TILE_DATA_SUCCESS = 'CALL_TILE_DATA_SUCCESS';
export const CALL_TILE_DATA_FAILURE = 'CALL_TILE_DATA_FAILURE';
export const RESET_CALL_TILE_DATA = 'RESET_CALL_TILE_DATA';

export const FETCH_CALL_V2 = 'FETCH_CALL_V2';
export const FETCH_CALL_SUCCESS_V2 = 'FETCH_CALL_SUCCESS_V2';
export const FETCH_CALL_FAILURE_V2 = 'FETCH_CALL_FAILURE_V2';

export const SET_CALL_PAGE_SIZE = 'SET_CALL_PAGE_SIZE';
export const SET_CALL_PAGE_INDEX = 'SET_CALL_PAGE_INDEX';
export const RESET_CALL_ITEMS = 'RESET_CALL_ITEMS';
export const RELOAD_CALL_ITEMS = 'RELOAD_CALL_ITEMS';
export const RESET_CALL_DETAIL_DATA = 'RESET_CALL_DETAIL_DATA';

export const SET_ACTIVE_CALL_ID = 'SET_ACTIVE_CALL_ID';

export const FETCH_CALL_DETAIL = 'FETCH_CALL_DETAIL';
export const FETCH_CALL_DETAIL_FAILURE = 'FETCH_CALL_DETAIL_FAILURE';
export const FETCH_CALL_DETAIL_SUCCESS = 'FETCH_CALL_DETAIL_SUCCESS';
export const UPDATE_CALL_DETAIL = 'UPDATE_CALL_DETAIL';
export const REMOVE_CALL_DETAIL = 'REMOVE_CALL_DETAIL';
export const RESET_CALL_DETAILS = 'RESET_CALL_DETAILS';
