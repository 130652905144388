import {
  FETCH_WORKFLOW_DETAIL,
  FETCH_WORKFLOW_DETAIL_FAILURE,
  FETCH_WORKFLOW_DETAIL_SUCCESS,
  RESET_WORKFLOW_ITEMS,
  UPDATE_WORKFLOW_DETAIL,
  REMOVE_WORKFLOW,
  SET_PROSPECT_SEQUENCE_DATA,
  SET_PROSPECT_SEQUENCE_DATA_SUCCESS,
  SET_PROSPECT_SEQUENCE_DATA_FAILURE,
} from '../../action/Workflow/index';

const initialState = {
  detailMap: { },
  prospectSequenceData: {
    requestId: '',
    data: {},
    isLoading: false,
    error: '',
  },
};
const workflowDetailsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_WORKFLOW_DETAIL:
      newState = {
        ...curState,
        detailMap: {
          ...curState.detailMap,
          [action.payload.id]: {
            isLoading: true,
            data: {},
            error: '',
          },
        },
      };
      break;
    case FETCH_WORKFLOW_DETAIL_SUCCESS: {
      const [, id] = action.payload._id.split(':');
      if (curState.detailMap[id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              isLoading: false,
              data: action.payload,
              error: '',
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_WORKFLOW_DETAIL_FAILURE: {
      if (curState.detailMap[action.payload.id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              ...curState.detailMap[action.payload.id],
              isLoading: false,
              error: action.payload.error,
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }

      break;
    }
    case UPDATE_WORKFLOW_DETAIL: {
      const { id, data } = action.payload;
      if (curState.detailMap[id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [id]: {
              ...curState.detailMap[id],
              data: {
                ...curState.detailMap[id].data,
                ...data,
              },
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case REMOVE_WORKFLOW: {
      const { detailMap } = curState;
      delete detailMap[action.payload];
      newState = {
        ...curState,
        detailMap: { ...detailMap },
      };
      break;
    }
    case RESET_WORKFLOW_ITEMS: {
      newState = { ...initialState };
      break;
    }
    case SET_PROSPECT_SEQUENCE_DATA: {
      newState = {
        ...curState,
        prospectSequenceData: {
          isLoading: true,
          requestId: action.payload.requestId,
          data: {},
          error: '',
        },
      };
      break;
    }
    case SET_PROSPECT_SEQUENCE_DATA_SUCCESS: {
      if (action.payload.requestId === curState.prospectSequenceData.requestId) {
        newState = {
          ...curState,
          prospectSequenceData: {
            isLoading: false,
            requestId: action.payload.requestId,
            data: action.payload.data,
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case SET_PROSPECT_SEQUENCE_DATA_FAILURE: {
      if (action.payload.requestId === curState.prospectSequenceData.requestId) {
        newState = {
          ...curState,
          prospectSequenceData: {
            isLoading: false,
            requestId: action.payload.requestId,
            data: {},
            error: action.payload.error,
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

export default workflowDetailsReducer;
