import {
  FETCH_CALL_FAILURE_V2, FETCH_CALL_SUCCESS_V2, FETCH_CALL_V2, RELOAD_CALL_ITEMS, RESET_CALL_ITEMS, SET_CALL_PAGE_INDEX, SET_CALL_PAGE_SIZE,
} from '../action/Call';

const initialState = {
  isLoading: NaN,
  error: '',
  items: [],
  pageIndex: 0,
  pageSize: 10,
  totalCount: 0,
  requestId: '',
};
export const callReducerV2 = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_CALL_V2:
      newState = {
        ...curState,
        error: null,
        isLoading: true,
        ...action.payload,
      };
      break;
    case FETCH_CALL_FAILURE_V2:
      newState = {
        ...curState, isLoading: false, skip: 0, error: action.payload, requestId: '',
      };
      break;
    case FETCH_CALL_SUCCESS_V2: {
      const {
        resultArr, totalCount, requestId,
      } = action.payload;
      if (requestId === curState.requestId) {
        newState = {
          ...curState,
          totalCount,
          isLoading: false,
          items: curState.items.concat(resultArr),
        };
      } else {
        newState = curState;
      }

      break;
    }
    case SET_CALL_PAGE_SIZE: {
      newState = {
        ...initialState,
        isLoading: curState.isLoading,
        pageSize: action.payload,
      };
      break;
    }
    case SET_CALL_PAGE_INDEX: {
      newState = {
        ...curState,
        pageIndex: action.payload,
      };
      break;
    }
    case RESET_CALL_ITEMS: {
      newState = { ...initialState };
      break;
    }
    case RELOAD_CALL_ITEMS: {
      const { pageSize } = curState;
      newState = { ...initialState, pageIndex: 0, pageSize };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

