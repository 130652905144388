import { add, endOfDay } from 'date-fns';
import * as TYPES from '../action/type';
import { addSingleView, deleteSingleView } from '../Utils/filterReducer.util';
import { generateDefaultDateRange } from '../Utils/inactiveTasks.util';

const initialState = {
  views: [],
  isLoading: NaN,
  error: '',
  selectedView: { filters: [], operator: 'And' },
  sortingOption: {
    label: 'Time',
    id: 'completionTime',
  },
  direction: false,
  tile: 'sent',
  search: '',
  dateRange: {
    ...generateDefaultDateRange(179), // Dev note: Default to last 179 days
    endDate: endOfDay(add(Date.now(), { days: 1 })).getTime(),
  },
};
export const outboxFilterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TYPES.FETCH_OUTBOX_VIEWS: case TYPES.FETCH_OUTBOX_VIEWS_SUCCESS: case TYPES.FETCH_OUTBOX_VIEWS_FAILURE:
    case TYPES.SET_OUTBOX_SELECTED_VIEW: case TYPES.SET_OUTBOX_SORTING_FIELD: case TYPES.SET_OUTBOX_SORTING_DIRECTION:
      newState = {
        ...curState,
        isLoading: action.type,
        ...action.payload,
      };
      break;
    case TYPES.UPDATE_SINGLE_VIEW_OUTBOX:
      newState = addSingleView(curState, action);
      break;
    case TYPES.ADD_SINGLE_VIEW_OUTBOX:
      newState = addSingleView(curState, action);
      break;
    case TYPES.DELETE_SINGLE_VIEW_OUTBOX:
      newState = deleteSingleView(curState, action);
      break;
    case TYPES.SET_OUTBOX_SEARCH:
      newState = {
        ...curState,
        search: action.payload,
      };
      break;
    case TYPES.SET_OUTBOX_TILE:
      newState = {
        ...curState,
        tile: action.payload,
      };
      break;
    case TYPES.UPDATE_OUTBOX_DATE_RANGE:
      newState = {
        ...curState,
        dateRange: action.payload,
      };
      break;
    case TYPES.UPDATE_OUTBOX_ON_WORKFLOW_REPORT_NAV:
      newState = {
        ...curState,
        ...action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};

