import React, { memo } from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import './index.scss';
import { triggerCloseModal } from '../../Utils/helper.util';

function Alert({
  type, primaryText, secondaryText, onNo, onYes, secondaryContext, primaryContext, isRequiredToCallTriggerCloseModal = true,
}) {
  const getAlertButtons = () => {
    let el = null;
    if (type === 'confirmation') {
      el = (
        <>
          <button
            type="button"
            onClick={() => {
              isRequiredToCallTriggerCloseModal && triggerCloseModal();
              onNo && onNo();
            }}
            className="ctaOpt smallFont"
            style={{
              marginRight: '10px',
            }}
          >
            {secondaryText}
          </button>
          <button
            type="button"
            onClick={() => {
              isRequiredToCallTriggerCloseModal && triggerCloseModal();
              onYes && onYes();
            }}
            className="cta smallFont"
          >
            {parser(primaryText)}
          </button>
        </>
      );
    } else {
      el = (
        <button
          type="button"
          onClick={() => {
            isRequiredToCallTriggerCloseModal && triggerCloseModal();
            onYes && onYes();
          }}
          className="cta"
          style={{ display: primaryText ? 'auto' : 'none' }}
        >
          {primaryText}
        </button>
      );
    }
    return (
      el
    );
  };

  return (
    <div className="alert-container">
      <div className="alert-body">
        <p className="alert-body__p">{ typeof primaryContext === 'object' ? primaryContext : parser(primaryContext)}</p>
        {secondaryContext && (
        <div style={{
          maxHeight: '180px',
          overflowY: 'scroll',
        }}
        >
          {
          typeof secondaryContext === 'object' ? secondaryContext
            : <span className="lightFont">{parser(secondaryContext)}</span>
        }
        </div>
        )}
      </div>
      <div className="alert-footer action-btn">
        {getAlertButtons()}
      </div>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.string,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  secondaryContext: PropTypes.any,
  primaryContext: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  isRequiredToCallTriggerCloseModal: PropTypes.bool,
};

Alert.defaultProps = {
  type: 'alert',
  secondaryText: 'Cancel',
  primaryText: 'Yes, Delete',
  secondaryContext: '',
  primaryContext: '',
  onYes: null,
  onNo: null,
  isRequiredToCallTriggerCloseModal: true,
};

export default memo(Alert);
