import {
  FETCH_OPPORTUNITY_PROFILE_ACTIVITY, FETCH_OPPORTUNITY_PROFILE_ACTIVITY_BY_ID, FETCH_OPPORTUNITY_PROFILE_ACTIVITY_FAILURE, FETCH_OPPORTUNITY_PROFILE_ACTIVITY_SUCCESS,
  RESET_OPPORTUNITY_PROFILE_ACTIVITY,
} from '../../action/type';
import { constructIndividualProfileActivityNewState, updateActivitiesInRedux } from '../../Utils/reducer.util';

const initialState = {
  isLoading: '',
  LastEvaluatedKey: null,
  entityId: '',
  activities: [],
};

export const opportunityProfileActivityReducer = (curState = initialState, action = { payload: '', action: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case RESET_OPPORTUNITY_PROFILE_ACTIVITY:
      newState = { ...initialState };
      break;
    case FETCH_OPPORTUNITY_PROFILE_ACTIVITY:
      newState = {
        ...curState,
        isLoading: FETCH_OPPORTUNITY_PROFILE_ACTIVITY,
      };
      break;
    case FETCH_OPPORTUNITY_PROFILE_ACTIVITY_SUCCESS:
      newState = constructIndividualProfileActivityNewState({ action, curState, loadingState: FETCH_OPPORTUNITY_PROFILE_ACTIVITY_SUCCESS });
      break;
    case FETCH_OPPORTUNITY_PROFILE_ACTIVITY_FAILURE:
      newState = {
        ...curState,
        isLoading: FETCH_OPPORTUNITY_PROFILE_ACTIVITY_FAILURE,
      };
      break;
    case FETCH_OPPORTUNITY_PROFILE_ACTIVITY_BY_ID: {
      newState = updateActivitiesInRedux({ payload, curState });
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
