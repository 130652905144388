import { useCallback, useEffect } from 'react';
import { closeDropdownListener } from '../Utils/helper.util';
import { useIsMountedState } from './useIsMounted.hook';

export function useHighlightWithCssEffectHook({ parentContainerId = 'upscaleReactModalContainer', parentContainerClassName = 'custom-modal-container', highlightClassName = 'shakeImage' }) {
  const [closeIconEffectCss, setCloseIconEffectsCss] = useIsMountedState('');
  const [clickCount, setClickCount] = useIsMountedState(0);

  const updateOnClick = useCallback(() => {
    if (clickCount + 1 > 1) {
      setCloseIconEffectsCss(highlightClassName);
      setTimeout(() => {
        setCloseIconEffectsCss({ closeIconEffectCss: '' });
      }, 500);
    }
    setClickCount(clickCount + 1);
  }, [clickCount, highlightClassName, setClickCount, setCloseIconEffectsCss]);

  const flyOutsideHandler = useCallback((evt) => {
    closeDropdownListener(evt, [parentContainerId], updateOnClick, [parentContainerClassName]);
  }, [parentContainerClassName, parentContainerId, updateOnClick]);

  useEffect(() => {
    document.addEventListener('click', flyOutsideHandler);
    return () => {
      document.removeEventListener('click', flyOutsideHandler);
    };
  }, [flyOutsideHandler]);

  return [closeIconEffectCss];
}
