import {
  ALL_USERS, ALL_USERS_SUCCESS, ALL_USERS_FAILURE, REMOVE_USER, FETCH_BULK_USER, BULK_UPDATE_USER_EMAIL_THROTTLE_METAINFO, UPDATE_USER, BULK_UPDATE_USER, CREATE_USER,
} from '../action/type';
import { generateName } from '../Utils/helper.util';

const initialState = {
  accountId: '', allUsers: [], isLoading: NaN, error: '',
};
export const allUsersReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case ALL_USERS:
      newState = {
        ...curState, error: null, isLoading: ALL_USERS, accountId: action.payload,
      };
      break;
    case ALL_USERS_SUCCESS:
      newState = { ...curState, isLoading: ALL_USERS_SUCCESS, ...action.payload };
      break;
    case ALL_USERS_FAILURE:
      newState = {
        ...curState, isLoading: ALL_USERS_FAILURE, accountId: '', error: action.payload,
      };
      break;
    case FETCH_BULK_USER: {
      const { allUsers } = curState;
      const allUsersCopy = [...allUsers];
      if (allUsersCopy) {
        const tIdMap = allUsersCopy.map((d) => d.id);
        action.payload.forEach((item) => {
          const oldIndex = tIdMap.indexOf(item.id);
          const newItem = { ...item, ownerName: generateName(item.basicInfo) };
          if (oldIndex > -1) {
            allUsersCopy[oldIndex] = newItem;
          } else {
            allUsersCopy.push(newItem);
          }
        });
      }
      newState = {
        ...curState,
        allUsers: allUsersCopy,
      };
      break;
    }
    case BULK_UPDATE_USER: {
      const { allUsers } = curState;
      const allUsersCopy = [...allUsers];
      if (allUsersCopy) {
        const tIdMap = allUsersCopy.map((d) => d.id);
        const { userIds, data } = action.payload;
        userIds.forEach((id) => {
          const oldIndex = tIdMap.indexOf(id);
          if (oldIndex > -1) {
            allUsersCopy[oldIndex] = {
              ...allUsersCopy[oldIndex],
              ...data,
            };
          }
        });
      }
      newState = {
        ...curState,
        allUsers: allUsersCopy,
      };
      break;
    }
    case BULK_UPDATE_USER_EMAIL_THROTTLE_METAINFO: {
      const { allUsers } = curState;
      const allUsersCopy = [...allUsers];
      if (allUsersCopy) {
        const tIdMap = allUsersCopy.map((d) => d.id);
        const userIds = Object.keys(action.payload);
        userIds.forEach((id) => {
          const oldIndex = tIdMap.indexOf(id);
          if (oldIndex > -1) {
            allUsersCopy[oldIndex] = {
              ...allUsersCopy[oldIndex],
              ...action.payload[id],
            };
          }
        });
      }
      newState = {
        ...curState,
        allUsers: allUsersCopy,
      };
      break;
    }
    case UPDATE_USER: {
      const { allUsers } = curState;
      const allUsersCopy = [...allUsers];
      if (allUsersCopy) {
        const tIdMap = allUsersCopy.map((d) => d.id);
        const { id, data } = action.payload;
        const oldIndex = tIdMap.indexOf(id);
        if (oldIndex > -1) {
          const newData = { ...allUsersCopy[oldIndex], ...data };
          allUsersCopy[oldIndex] = {
            ...newData,
            ownerName: generateName(newData.basicInfo),
          };
        }
      }
      newState = {
        ...curState,
        allUsers: allUsersCopy,
      };
      break;
    }
    case CREATE_USER: {
      const { allUsers = [] } = curState;
      const allUsersCopy = [...allUsers];
      const { id } = action.payload;
      const foundIndex = allUsersCopy.findIndex((d) => d.id === id);
      if (foundIndex > -1) {
        allUsersCopy[foundIndex] = {
          ...allUsersCopy[foundIndex],
          ...action.payload,
        };
      } else {
        allUsersCopy.push(action.payload);
      }
      newState = {
        ...curState,
        allUsers: allUsersCopy,
      };
      break;
    }
    case REMOVE_USER: {
      const { allUsers } = curState;
      const allUsersCopy = [...allUsers];
      if (allUsersCopy.length) {
        const userIndex = allUsersCopy.findIndex((d) => d.id === action.payload);
        if (userIndex > -1) {
          allUsersCopy.splice(userIndex, 1);
        }
      }
      newState = {
        ...curState, allUsers: allUsersCopy,
      };
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
