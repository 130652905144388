import { updateReduxStore } from '../Utils/creator.util';
import {
  USER_AUTHENTICATED, USER_ACCESS_DETAILS, RESET_IMPORT_PROSPECT_HISTORY, FETCH_IMPORT_PROSPECT_HISTORY, FETCH_IMPORT_PROSPECT_HISTORY_SUCCESS, FETCH_IMPORT_PROSPECT_HISTORY_FAILURE, SET_WORKFLOW_REPORT_DATE_RANGE, UPDATE_OUTBOX_ON_WORKFLOW_REPORT_NAV, SET_WORKFLOW_REPORT_DATA_SUCCESS, SET_WORKFLOW_REPORT_DATA, SET_WORKFLOW_REPORT_DATA_FAILURE,
} from './type';
import { batchGetImportProspectHistory } from '../service/reports';
import { isObject } from '../Utils/dataType.util';
import { removeClientAccount, removeOpportunity, removeProspects } from './creator';
import { reloadOutbox } from './Outbox/creator';
import { getWorkflowStatisticV2 } from '../service/workflow';

export const setUserAuthenticated = () => updateReduxStore({ type: USER_AUTHENTICATED });
export const setUserAccessDetails = (user) => updateReduxStore({ type: USER_ACCESS_DETAILS, payload: user });

function setResetImportHistory() {
  return {
    type: RESET_IMPORT_PROSPECT_HISTORY,
  };
}

function setImportHistory(payload) {
  return updateReduxStore({ type: FETCH_IMPORT_PROSPECT_HISTORY, payload });
}

function setImportHistorySuccess(payload) {
  return updateReduxStore({ type: FETCH_IMPORT_PROSPECT_HISTORY_SUCCESS, payload });
}

function setImportHistoryFailure(error) {
  return updateReduxStore({ type: FETCH_IMPORT_PROSPECT_HISTORY_FAILURE, payload: error });
}

function setRecursiveImportHistoryThunk({
  accountId, LastEvaluatedKey, totalItems = [],
}) {
  return (dispatch) => {
    dispatch(setImportHistory());
    batchGetImportProspectHistory({
      accountId, LastEvaluatedKey,
    })
      .then((resp) => {
        const { Items, LastEvaluatedKey: newLastEvaluatedKey } = resp;
        // eslint-disable-next-line no-param-reassign
        totalItems = totalItems.concat(Items);
        if (isObject(newLastEvaluatedKey) && Object.keys(newLastEvaluatedKey).length) {
          dispatch(setRecursiveImportHistoryThunk({
            accountId, LastEvaluatedKey: newLastEvaluatedKey, totalItems,
          }));
        } else {
          dispatch(setImportHistorySuccess({
            items: totalItems,
            LastEvaluatedKey: newLastEvaluatedKey,
          }));
        }
      }).catch((error) => {
        dispatch(setImportHistoryFailure(error));
      });
  };
}

export function setImportHistoryThunk({ accountId }) {
  return (dispatch) => {
    dispatch(setResetImportHistory());
    dispatch(setRecursiveImportHistoryThunk({ accountId }));
  };
}

export function setRemoveSelectedIndividualView(viewId) {
  return (dispatch) => {
    const [category, uId] = viewId.split(':');
    (category === 'prospect') && dispatch(removeProspects({ ids: [uId] }));
    (category === 'account') && dispatch(removeClientAccount({ ids: [uId] }));
    (category === 'opportunity') && dispatch(removeOpportunity({ ids: [uId] }));
  };
}

export function setWorkflowReportDaterange(dateRange) {
  return (dispatch) => {
    dispatch({ type: SET_WORKFLOW_REPORT_DATE_RANGE, payload: dateRange });
  };
}

export const setOutboxViewOnWorkflowReportClick = ({ tile, selectedView, dateRange }) => (dispatch) => {
  dispatch(reloadOutbox({ reloadTiles: true }));
  dispatch({ type: UPDATE_OUTBOX_ON_WORKFLOW_REPORT_NAV, payload: { tile, selectedView, dateRange } });
};

export const setWorkflowsReportFetchThunk = (dateRange) => (dispatch, getSate) => {
  const { UserSettings: { user } } = getSate();
  dispatch({ type: SET_WORKFLOW_REPORT_DATA });
  getWorkflowStatisticV2({ accountId: user?.accountId, startTime: dateRange.startDate, endTime: dateRange.endDate })
    .then((res) => {
      dispatch({ type: SET_WORKFLOW_REPORT_DATA_SUCCESS, payload: res });
    })
    .catch((err) => {
      dispatch({ type: SET_WORKFLOW_REPORT_DATA_FAILURE, payload: err.message });
    });
};
