import {
  useEffect, useRef, useState,
} from 'react';

export const useIsMounted = (defaultState = false) => {
  const isMounted = useRef(defaultState);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);
  return isMounted.current;
};

export const useIsMountedState = (defaultState) => {
  const [state, setState] = useState(defaultState);
  const isMounted = useIsMounted(true);

  const setStateCallback = (newState) => {
    isMounted && setState(newState);
  };

  return [state, setStateCallback];
};
