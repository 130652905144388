import {
  FETCH_TASK_DETAIL,
  FETCH_TASK_DETAIL_FAILURE,
  FETCH_TASK_DETAIL_SUCCESS,
  UPDATE_TASK_DETAIL,
  REMOVE_TASK_DETAIL,
  RESET_TASK_DETAILS,
  BULK_UPDATE_TASK_MULTIPLE_FIELD,
} from '../action/Task';
import { getMatchingKeys } from '../Utils/V2/creator.util';

const initialState = {
  detailMap: { },
};
export const taskDetails = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case FETCH_TASK_DETAIL:
      newState = {
        ...curState,
        detailMap: {
          ...curState.detailMap,
          [action.payload.id]: {
            isLoading: true,
            error: '',
          },
        },
      };
      break;
    case FETCH_TASK_DETAIL_SUCCESS: {
      if (curState.detailMap[action.payload.id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              isLoading: false,
              data: action.payload,
              error: '',
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_TASK_DETAIL_FAILURE: {
      if (curState.detailMap[action.payload.id]) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [action.payload.id]: {
              ...curState.detailMap[action.payload.id],
              isLoading: false,
              error: action.payload.error,
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }

      break;
    }
    case RESET_TASK_DETAILS: {
      newState = { ...initialState };
      break;
    }
    case UPDATE_TASK_DETAIL: {
      const { id, data } = action.payload;
      if (curState.detailMap[id] && curState.detailMap[id].data) {
        newState = {
          ...curState,
          detailMap: {
            ...curState.detailMap,
            [id]: {
              ...curState.detailMap[id],
              data: {
                ...curState.detailMap[id].data,
                ...data,
              },
              lastFetched: Date.now(),
            },
          },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case BULK_UPDATE_TASK_MULTIPLE_FIELD: {
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: Object.keys(action.payload) });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          detailMap[key] = {
            ...detailMap[key],
            data: { ...detailMap[key].data, ...action.payload[key] },
          };
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    case REMOVE_TASK_DETAIL: {
      const { detailMap } = curState;
      const keysToIterate = getMatchingKeys({ keys: Object.keys(detailMap), modifiedKeys: action.payload.ids });
      if (keysToIterate.length) {
        keysToIterate.forEach((key) => {
          delete detailMap[key];
        });
        newState = {
          detailMap: { ...detailMap },
        };
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};

