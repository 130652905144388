import API from '../libs/amplify.lib';

import { API_NAME } from '../constants';
import { generateQueryStringFromObject } from '../Utils/query.v2.util';
import { parseResponse } from '../libs/response.lib';

const getIntegrationSettings = async ({ accountId }) => API
  .get(API_NAME, `/account?${generateQueryStringFromObject({ accountId, operation: 'getIntegrations' })}`)
  .then(parseResponse);

export { getIntegrationSettings };
