import * as TYPES from '../../action/type';
import { SET_WORKFLOW_SEARCH } from '../../action/Workflow';
import { addSingleView, deleteSingleView, updateSingleView } from '../../Utils/filterReducer.util';
import { generateDefaultDateRange } from '../../Utils/inactiveTasks.util';

const initialState = {
  views: [],
  isLoading: NaN,
  error: '',
  search: '',
  selectedView: { filters: [], operator: 'And' },
  sortingOption: { id: 'createdAt' },
  direction: false,
  workflowReportFilter: { filters: [], operator: 'And' },
  workflowReportFilterDateRange: {
    ...generateDefaultDateRange(180),
  },
};
const workflowFilterReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case TYPES.FETCH_WORKFLOW_VIEWS:
      newState = {
        ...curState,
        error: null,
        isLoading: TYPES.FETCH_WORKFLOW_VIEWS,
      };
      break;
    case TYPES.FETCH_WORKFLOW_VIEWS_SUCCESS:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_WORKFLOW_VIEWS_SUCCESS,
        views: action.payload.views,
      };
      break;
    case TYPES.FETCH_WORKFLOW_VIEWS_FAILURE:
      newState = {
        ...curState,
        isLoading: TYPES.FETCH_WORKFLOW_VIEWS_FAILURE,
        error: action.payload,
      };
      break;
    case TYPES.SET_WORKFLOW_SORTING_FIELD:
      newState = {
        ...curState,
        isLoading: TYPES.SET_WORKFLOW_SORTING_FIELD,
        sortingOption: action.payload.sortingOption,
      };
      break;
    case TYPES.SET_WORKFLOW_SORTING_DIRECTION:
      newState = {
        ...curState,
        isLoading: TYPES.SET_WORKFLOW_SORTING_DIRECTION,
        direction: action.payload.direction,
      };
      break;
    case TYPES.SET_WORKFLOW_SELECTED_VIEW:
      newState = {
        ...curState,
        isLoading: TYPES.SET_WORKFLOW_SELECTED_VIEW,
        selectedView: action.payload.selectedView,
      };
      break;
    case TYPES.UPDATE_SINGLE_VIEW_WORKFLOW:
      newState = updateSingleView(curState, action);
      break;
    case TYPES.ADD_SINGLE_VIEW_WORKFLOW:
      newState = addSingleView(curState, action);
      break;
    case TYPES.DELETE_SINGLE_VIEW_WORKFLOW:
      newState = deleteSingleView(curState, action);
      break;
    case TYPES.SET_WORKFLOW_REPORT_SELECTED_VIEW:
      newState = {
        ...curState,
        isLoading: TYPES.SET_WORKFLOW_REPORT_SELECTED_VIEW,
        workflowReportFilter: action.payload.selectedView,
      };
      break;
    case TYPES.SET_WORKFLOW_REPORT_DATE_RANGE:
      newState = {
        ...curState,
        workflowReportFilterDateRange: action.payload,
      };
      break;
    case SET_WORKFLOW_SEARCH:
      newState = {
        ...curState,
        search: action.payload,
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};

export default workflowFilterReducer;
